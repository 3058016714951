import "./RecommendedSong.scss";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Input,
    Container,
    Card,
    Table,
    CardFooter,
    Row,
    Media
} from 'reactstrap';
import { useEffect, useState } from "react";
import axios from '../../shared/hooks/use-customAxios';
import Pagination from 'react-responsive-pagination';
import { useDispatch } from "react-redux";
import { uiActions } from "../../shared/store/ui-Slice";
import { useSelector } from "react-redux";
import Moment from 'moment';
import FilterSong from "./FilterSong";
import usePageTitle from '../../shared/hooks/use-pageTitle';

const AddSongList: React.FC<any> = ({ songModal, setSongModal }) => {
    usePageTitle("Recommended For Song");
    const header = ["Sr No.", "Name", "Category", "Language", "Created At"];
    const options = ["5", "10", "15", "20", "25"];
    const [total, setTotal] = useState<number>(0);
    const [record, setRecord] = useState<string>(options[0]);
    const [page, setPage] = useState<number>(1);
    const [filterName, setFilterName] = useState<string>('');
    const [filterSearchKeywords, setFilterSearchKeywords] = useState<any>('');
    const [filterCategory, setFilterCategory] = useState<any>('');
    const [filterLanguage, setFilterLanguage] = useState<any>('');
    const [filterTag, setFilterTag] = useState<any>('');
    const [filterIsFree, setFilterIsFree] = useState<any>('');
    const [filterPage, setFilterPage] = useState<boolean>(false);
    const [songData, setSongData] = useState<any>([]);
    let totalPages: number = Math.ceil(total / +record);
    let start: number = (page - 1) * +record;
    let srIndex: number = start;
    const dispatch = useDispatch();
    const songsItem: any = useSelector((state: any) => state.ui.songsItem);

    const handleChange = (newPage: number): void => {
        setPage(newPage);
    }

    const featchingData = async (): Promise<void> => {
        try {
            let obj: any = {
                pageNo: page,
                recordPerPage: record
            };

            if ((filterName && filterName !== '') ||
                (filterCategory && filterCategory !== '') || (filterLanguage && filterLanguage !== '') ||
                (filterTag && filterTag !== '') || (filterTag && filterTag !== '') || (filterIsFree && filterIsFree !== '') ||
                (filterSearchKeywords && filterSearchKeywords !== '')) {
                let categories: any = [];
                if (!!filterCategory) {
                    categories = Array.isArray(filterCategory) && filterCategory.map((i: any) => i.value);
                }
                obj.name = filterName;
                obj.category = categories.length === 0 ? '' : categories;
                obj.language = filterLanguage;
                obj.tag = filterTag;
                obj.forGuestUser = filterIsFree;
                obj.searchKeywords = filterSearchKeywords;
            }

            const res: any = await axios.post('song/list', obj);
            if (res && res.status === 200) {
                setSongData(res.data.data.song);
                if (res.data.data.totalRecords || res.data.data.totalRecords === 0) {
                    setTotal(res.data.data.totalRecords);
                }
                if (res.data.data.song.length === 0) {
                    setPage(1);
                }
            }

        } catch (error: any) { }
    };

    useEffect(() => {
        featchingData();
    }, [page, record]);

    return (
        <div>
            {filterPage && <FilterSong
                filterPage={filterPage}
                setFilterPage={setFilterPage}
                filterName={filterName}
                setFilterName={setFilterName}
                filterCategory={filterCategory}
                setFilterCategory={setFilterCategory}
                filterLanguage={filterLanguage}
                setFilterLanguage={setFilterLanguage}
                filterTag={filterTag}
                setFilterTag={setFilterTag}
                filterIsFree={filterIsFree}
                setFilterIsFree={setFilterIsFree}
                filterSearchKeywords={filterSearchKeywords}
                setFilterSearchKeywords={setFilterSearchKeywords}
                featchingData={featchingData}
            />}
            <Modal isOpen={songModal} centered={true} size="xl">
                <ModalHeader>
                    <div className="d-flex align-items-center justify-content-between">
                        <div>Song [{total}]</div>
                        <Button className='filter' onClick={() => setFilterPage(true)}>Filter</Button>
                    </div>
                </ModalHeader>
                <ModalBody className="p-0 pt-3">
                    <Container className="mt--7" fluid>
                        <Row>
                            <div className="col">
                                <Card className="border-0">
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                {Array.isArray(header) && header.map((item: string, index: number) => (
                                                    <th className="border-top-0" scope="col" key={index}>{item}</th>
                                                ))}
                                                <th scope="col" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(songData) && songData.map((item: any, index: number) => (
                                                <tr key={index}>
                                                    <td>{++srIndex}</td>
                                                    <td>
                                                        <Media className="align-items-center d-flex gap-3">
                                                            <div className="avatar rounded-circle mr-3">
                                                                <img alt="..." src={item?.img} />
                                                            </div>
                                                            <Media>
                                                                <span className="mb-0 text-sm">{item?.name}</span>
                                                            </Media>
                                                        </Media>
                                                    </td>
                                                    <td>{(Array.isArray(item?.category) && item?.category.map((i: any, index: number) => (i?.name) + `${item?.category.length !== index + 1 ? ', ' : ' '}`))}</td>
                                                    <td>{item?.language?.name}</td>
                                                    <td>{Moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                    <td className="text-right">
                                                        {Array.isArray(songsItem) && songsItem.filter((song: any) => song._id === item._id).length === 0 && <i className="bi bi-plus-circle-fill text-success" onClick={() => dispatch(uiActions.addSongHandler(item))}></i>}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <CardFooter className="p-2 border-0">
                                        <Input type="select" value={record} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRecord(e.target.value)}>
                                            {Array.isArray(options) && options.map((item: string, index: number) => (
                                                <option key={index} value={item}>{item}</option>

                                            ))}
                                        </Input>
                                        <Pagination
                                            current={page}
                                            total={totalPages}
                                            onPageChange={handleChange}
                                            maxWidth={5}
                                        />
                                    </CardFooter>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button className="primary-btn" size="sm" onClick={() => setSongModal(false)}>
                        Submit
                    </Button>
                    <Button className="cancel-btn" size="sm" onClick={() => setSongModal(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div >
    );
};

export default AddSongList;