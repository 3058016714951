import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { uiActions } from '../store/ui-Slice';

const usePageTitle = (title: string): void => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(uiActions.pageHandler(title));
    }, []);
};

export default usePageTitle;