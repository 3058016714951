import "./Sponsor.scss";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Container,
    Row,
    Col,
    CardFooter,
} from "reactstrap";
import { useEffect, useState } from 'react';
import { Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import axios from '../../shared/hooks/use-customAxios';
import { success } from '../../shared/hooks/use-toastify';
import useSelect from '../../shared/hooks/use-select';
import CreatableSelect from 'react-select/creatable';
import { config } from '../../config/config';
import usePageTitle from '../../shared/hooks/use-pageTitle';
import { useDispatch } from "react-redux";
import { uiActions } from "../../shared/store/ui-Slice";

const AddSponsor: React.FC<any> = ({featchingData}) => {
    usePageTitle("Sponsor");
    const [error, setError] = useState<any>();
    const [sponsorList, setSponsorList] = useState<any[]>([]);
    let styles: any = useSelect();
    const dispatch = useDispatch();

    const sponsorSchema = yup.object().shape({
        name: yup.object().required('Name is required')
    });

    useEffect(() => {
        const list = async () => {
            try {
                const res: any = await axios.post(`master/list`);
                if (res && res.status === 200) {
                    let sponsors: any[] = res?.data?.data?.sponsors;
                    if (Array.isArray(sponsors) && sponsors.length > 0) sponsors = sponsors.map((item: any) => ({ label: item?.name, value: item?.name }));
                    setSponsorList(sponsors);
                }

            } catch (error: any) { }
        };

        list();
    }, []);

    return (
        <div className="form-sponsor">
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="8">
                        <Card>
                            <Formik
                                initialValues={{ name: '' }}
                                validationSchema={sponsorSchema}
                                onSubmit={async (values: any, { resetForm }): Promise<void> => {
                                    try {
                                        let payload: any = {
                                            name: values.name.value,
                                        };
                                        let res: any = await axios.post('sponsor/add', payload);
                                        if (res && res.status === 200) {
                                            let payload: any = {
                                                moduleName: "sponsor",
                                                moduleLink: `${config.front_url}sponsor/view/${res?.data?.data?.sponsor}`,
                                                activity: "insert",
                                                message: `The ${values.name.value} sponsor record has been inserted.`
                                            }
                                            await axios.post("history/add", payload);
                                            success('Sponsor Created Successfully.');
                                            resetForm({ values: '' });
                                            featchingData();
                                            dispatch(uiActions.pageType(''));
                                        }
                                    } catch (error: any) {
                                        if (error.response) {
                                            setError(error.response.data.error);
                                        }
                                    }
                                }} >
                                {({ values, setFieldValue, setFieldTouched }) => (
                                    <Form>
                                        <CardBody className="pb-2">
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-reader">Name <span className="text-danger">*</span></label>
                                                            <CreatableSelect
                                                                name="name"
                                                                onChange={(e) => { setFieldValue("name", e) }}
                                                                onBlur={(e: any) => { setFieldTouched("name", e) }}
                                                                value={values.name}
                                                                styles={styles}
                                                                placeholder="Name"
                                                                options={sponsorList}
                                                            />
                                                            {error ? <div className="error-message">{error?.name ? error?.name : ''}</div> : <ErrorMessage component="div" className="error-message" name="name" />}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardBody>
                                        <CardFooter className="bg-white border-0 pl-lg-4 ">
                                            <div className="form-button">
                                                <Button className="primary-btn" size="sm" type="submit">Submit</Button>
                                                <Button className="cancel-btn" size="sm" onClick={() => dispatch(uiActions.pageType(''))}>Cancel</Button>
                                            </div>
                                        </CardFooter>
                                    </Form>
                                )}
                            </Formik>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default AddSponsor;