import "./Audiobook.scss";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormGroup,
    Input,
    Row,
    Col
} from 'reactstrap';
import { useState, useEffect } from "react";
import Select from 'react-select';
import axios from '../../shared/hooks/use-customAxios';
import useSelect from '../../shared/hooks/use-select';
import usePageTitle from '../../shared/hooks/use-pageTitle';

const FilterAudioBook: React.FC<any> = ({
    setFilterPage,
    filterPage,
    setFilterName,
    filterName,
    filterPublisher,
    setFilterPublisher,
    filterAuthor,
    setFilterAuthor,
    filterCategory,
    setFilterCategory,
    filterLanguage,
    setFilterLanguage,
    filterTag,
    setFilterTag,
    filterSearchKeywords,
    setFilterSearchKeywords,
    filterIsFree,
    setFilterIsFree,
    featchingData,
    args
}) => {
    usePageTitle("Audiobook");
    const [publisherList, setPublisherList] = useState<any[]>([]);
    const [authorList, setAuthorList] = useState<any[]>([]);
    const [categoryList, setCategoryList] = useState<any[]>([]);
    const [languageList, setLanguageList] = useState<any[]>([]);
    const [tagList, setTagList] = useState<any[]>([]);
    let styles: any = useSelect();

    useEffect(() => {
        const selectData = async (): Promise<void> => {
            try {
                let res: any = await axios.post(`master/list`, { useFor: "audiobook" });
                if (res && res.status === 200) {
                    let publishers: any[] = res?.data?.data?.publishers;
                    if (Array.isArray(publishers) && publishers.length > 0) publishers = publishers.map((item: any) => ({ label: item.name, value: item._id }));
                    setPublisherList(publishers);

                    let authors: any[] = res?.data?.data?.authors;
                    if (Array.isArray(authors) && authors.length > 0) authors = authors.map((item: any) => ({ value: item._id, label: item.name }));
                    setAuthorList(authors);

                    let categories: any[] = res?.data?.data?.categories;
                    if (Array.isArray(categories) && categories.length > 0) categories = categories.map((item: any) => ({ label: item.name, value: item._id }));
                    setCategoryList(categories);
                    setLanguageList(res.data.data.languages);
                    setTagList(res.data.data.tags);
                }
            } catch (error: any) { }
        };

        selectData();
    }, []);

    const clearHandler = (): void => {
        setFilterName("");
        setFilterPublisher("");
        setFilterAuthor("");
        setFilterCategory("");
        setFilterLanguage("");
        setFilterIsFree("");
        setFilterSearchKeywords("");
    };

    const toggle = () => { setFilterPage(!filterPage); featchingData(); };

    return (
        <Modal className="file-form" isOpen={filterPage} toggle={toggle} {...args} centered={true}>
            <ModalHeader toggle={toggle}>Filter</ModalHeader>
            <ModalBody>
                <div className="pl-lg-4">
                    <Row>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-name">Name</label>
                                <Input className="form-control-alternative" id="input-name" placeholder="Name" type="text" value={filterName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterName(e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-reader">Publisher</label>
                                <Select
                                    options={publisherList}
                                    value={filterPublisher}
                                    onChange={(val: any) => setFilterPublisher(val)}
                                    styles={styles}
                                    placeholder="Select"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-reader">Author</label>
                                <Select
                                    options={authorList}
                                    value={filterAuthor}
                                    onChange={(val: any) => setFilterAuthor(val)}
                                    styles={styles}
                                    placeholder="Select"
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-category">Category</label>
                                <Select
                                    options={categoryList}
                                    value={filterCategory}
                                    isMulti
                                    onChange={(val: any) => setFilterCategory(val)}
                                    styles={styles}
                                    placeholder="Select"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-language">Language</label>
                                <Input
                                    id="input-language"
                                    name="language"
                                    type="select"
                                    placeholder="Select"
                                    className="form-control-alternative"
                                    value={filterLanguage} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterLanguage(e.target.value)}
                                >
                                    <option value="">Select</option>
                                    {Array.isArray(languageList) && languageList.map((item: any, index: number) => (
                                        <option value={item._id} key={index}>{item.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-tag">Tag</label>
                                <Input
                                    id="input-tag"
                                    name="tag"
                                    type="select"
                                    placeholder="Select"
                                    className="form-control-alternative"
                                    value={filterTag} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterTag(e.target.value)}
                                >
                                    <option value="">Select</option>
                                    {Array.isArray(tagList) && tagList.map((item: any, index: number) => (
                                        <option value={item._id} key={index}>{item.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-searchKeywords">Search Keywords</label>
                                <Input className="form-control-alternative" id="input-searchKeywords" placeholder="Search Keywords" type="text" value={filterSearchKeywords} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterSearchKeywords(e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-forGuestUser">For Guest User</label>
                                <Input
                                    id="input-forGuestUser"
                                    name="forGuestUser"
                                    type="select"
                                    placeholder="Select"
                                    className="form-control-alternative"
                                    value={filterIsFree} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterIsFree(e.target.value)}
                                >
                                    <option value="">Select</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button className="cancel-btn" size="sm" onClick={clearHandler}>Clear</Button>
                <Button className="primary-btn" size="sm" onClick={() => { setFilterPage(false); featchingData(); }}>
                    Submit
                </Button>
            </ModalFooter>
        </Modal >
    );
};

export default FilterAudioBook;