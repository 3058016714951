import { useState, useEffect } from 'react';
import './NoSearchFound.scss';
import Pagination from 'react-responsive-pagination';
import {
    Card,
    CardHeader,
    CardFooter,
    Table,
    Container,
    Row,
    Button,
    Input
} from "reactstrap";
import usePageTitle from '../../shared/hooks/use-pageTitle';
import axios from '../../shared/hooks/use-customAxios';
import Skeleton from '../../shared/components/Skeleton/Skeleton';
import Moment from 'moment';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useSelector } from 'react-redux';

const NoSearchFound = () => {
    usePageTitle("No Search Found");
    const pageType: string = useSelector((state: any) => state.ui.pageType);
    const header = ["Sr No.", "Type", "Search", "Created At"];
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [noSearchFoundData, setNoSearchFoundData] = useState<any>([]);
    const [noSearchFoundDataList, setNoSearchFoundDataList] = useState<any>([]);
    const options = ["5", "10", "15", "20", "25"];
    const [total, setTotal] = useState<number>(0);
    const [record, setRecord] = useState<string>(options[0]);
    const [page, setPage] = useState<number>(1);
    let totalPages: number = Math.ceil(total / +record);
    let start: number = (page - 1) * +record;
    let srIndex: number = start;
    const fileType: string = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension: string = ".xlsx";
    const handleChange = (newPage: number): void => {
        setPage(newPage);
    }

    const featchingData = async (): Promise<void> => {
        try {
            let obj: any = {
                pageNo: page,
                recordPerPage: record,
            };

            const res: any = await axios.post('noSearchFound/list', obj);
            if (res && res.status === 200) {
                setIsLoading(false);
                setNoSearchFoundData(res.data.data.noSearchFound);
                if (res.data.data.totalRecords || res.data.data.totalRecords === 0) {
                    setTotal(res.data.data.totalRecords);
                }
                if (res.data.data.noSearchFound.length === 0) {
                    setPage(1);
                }
            }

        } catch (error: any) {
            setIsLoading(true);
        }
    };

    useEffect(() => {
        featchingData();
    }, [page, record]);

    useEffect(() => {
        const list = async () => {
            try {
                const res: any = await axios.post(`master/list`);
                if (res && res.status === 200) {
                    let data: any = Array.isArray(res.data.data.noSearchFound) && res.data.data.noSearchFound.map((item: any) => ({ ...item, createdAt: Moment(item?.createdAt).format('DD-MM-YYYY') }));
                    setNoSearchFoundDataList(data);
                }
    
            } catch (error: any) {}
        };

        list();
    }, []);

    const exportData = async (): Promise<void> => {
        const ws: any = XLSX.utils.json_to_sheet(noSearchFoundDataList);
        const wb: any = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer: any = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data: any = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "NoSearchFoundData" + fileExtension);
        let payload: any = {
            moduleName: "no search found",
            activity: "export data",
            message: `The no search found data export.`
        }
        await axios.post("history/add", payload);
    };

    return (
        <>
            {pageType === '' && (isLoading ? <Skeleton col={4} title="No Search Found" /> :
                <div className='no-search-found'>
                    <Container className="mt--7" fluid>
                        <Row>
                            <div className="col">
                                <Card>
                                    <CardHeader className="border-0 d-flex justify-content-between">
                                        <h3 className='mb-0'>No Search Found [{total}]</h3>
                                        <div className="header-btn">
                                            <Button className='btn' onClick={exportData}>Export</Button>
                                        </div>
                                    </CardHeader>
                                    <Table className="align-items-center table-flush mb-0" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                {Array.isArray(header) && header.map((item: string, index: number) => (
                                                    <th scope="col" key={index}>{item}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(noSearchFoundData) && noSearchFoundData.map((item: any, index: number) => (
                                                <tr key={index}>
                                                    <td>{++srIndex}</td>
                                                    <td>{item?.type === "podcast" ? "bauddhik" : item?.type}</td>
                                                    <td>{item?.search}</td>
                                                    <td>{Moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <CardFooter className="p-2">
                                        <Input type="select" value={record} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRecord(e.target.value)}>
                                            {Array.isArray(options) && options.map((item: string, index: number) => (
                                                <option key={index} value={item}>{item}</option>

                                            ))};
                                        </Input>
                                        <Pagination
                                            current={page}
                                            total={totalPages}
                                            onPageChange={handleChange}
                                            maxWidth={5}
                                        />
                                    </CardFooter>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                </div>)}
        </>
    );
};

export default NoSearchFound;
