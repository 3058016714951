import "./Podcast.scss";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormGroup,
    Input,
    Row,
    Col
} from 'reactstrap';
import { useState, useEffect } from "react";
import Select from 'react-select';
import axios from '../../shared/hooks/use-customAxios';
import useSelect from '../../shared/hooks/use-select';
import usePageTitle from '../../shared/hooks/use-pageTitle';

const FilterPodcast: React.FC<any> = ({
    setFilterPage,
    filterPage,
    setFilterName,
    filterName,
    filterSpeaker,
    setFilterSpeaker,
    filterCategory,
    setFilterCategory,
    filterLanguage,
    setFilterLanguage,
    filterTag,
    setFilterTag,
    filterSearchKeywords,
    setFilterSearchKeywords,
    filterIsFree,
    setFilterIsFree,
    featchingData,
    args
}) => {
    usePageTitle("Bauddhik");
    const [speakerList, setSpeakerList] = useState<any[]>([]);
    const [categoryList, setCategoryList] = useState<any[]>([]);
    const [languageList, setLanguageList] = useState<any[]>([]);
    const [tagList, setTagList] = useState<any[]>([]);
    let styles: any = useSelect();

    useEffect(() => {
        const selectData = async (): Promise<void> => {
            try {
                let res: any = await axios.post(`master/list`, { useFor: "podcast" });
                if (res && res.status === 200) {
                    let speakers: any[] = res?.data?.data?.speakers;
                    if (Array.isArray(speakers) && speakers.length > 0) speakers = speakers.map((item: any) => ({ value: item._id, label: item.name }));
                    setSpeakerList(speakers);

                    let categories: any[] = res?.data?.data?.categories;
                    if (Array.isArray(categories) && categories.length > 0) categories = categories.map((item: any) => ({ label: item.name, value: item._id }));
                    setCategoryList(categories);
                    setLanguageList(res.data.data.languages);
                    setTagList(res.data.data.tags);
                }
            } catch (error: any) { }
        };

        selectData();
    }, []);

    const clearHandler = (): void => {
        setFilterName("");
        setFilterSpeaker("");
        setFilterCategory("");
        setFilterLanguage("");
        setFilterIsFree("");
        setFilterSearchKeywords("");
    };

    const toggle = () => { setFilterPage(!filterPage); featchingData(); };

    return (
        <Modal className="file-form" isOpen={filterPage} toggle={toggle} {...args} centered={true}>
            <ModalHeader toggle={toggle}>Filter</ModalHeader>
            <ModalBody>
                <div className="pl-lg-4">
                    <Row>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-name">Name</label>
                                <Input className="form-control-alternative" id="input-name" placeholder="Name" type="text" value={filterName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterName(e.target.value)} />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-reader">Speaker</label>
                                <Select
                                    options={speakerList}
                                    value={filterSpeaker}
                                    onChange={(val: any) => setFilterSpeaker(val)}
                                    styles={styles}
                                    placeholder="Select"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-category">Category</label>
                                <Select
                                    options={categoryList}
                                    value={filterCategory}
                                    isMulti
                                    onChange={(val: any) => setFilterCategory(val)}
                                    styles={styles}
                                    placeholder="Select"
                                />
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-language">Language</label>
                                <Input
                                    id="input-language"
                                    name="language"
                                    type="select"
                                    placeholder="Select"
                                    className="form-control-alternative"
                                    value={filterLanguage} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterLanguage(e.target.value)}
                                >
                                    <option value="">Select</option>
                                    {Array.isArray(languageList) && languageList.map((item: any, index: number) => (
                                        <option value={item._id} key={index}>{item.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-tag">Tag</label>
                                <Input
                                    id="input-tag"
                                    name="tag"
                                    type="select"
                                    placeholder="Select"
                                    className="form-control-alternative"
                                    value={filterTag} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterTag(e.target.value)}
                                >
                                    <option value="">Select</option>
                                    {Array.isArray(tagList) && tagList.map((item: any, index: number) => (
                                        <option value={item._id} key={index}>{item.name}</option>
                                    ))}
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-searchKeywords">Search Keywords</label>
                                <Input className="form-control-alternative" id="input-searchKeywords" placeholder="Search Keywords" type="text" value={filterSearchKeywords} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterSearchKeywords(e.target.value)} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6">
                            <FormGroup>
                                <label className="form-control-label" htmlFor="input-forGuestUser">For Guest User</label>
                                <Input
                                    id="input-forGuestUser"
                                    name="forGuestUser"
                                    type="select"
                                    placeholder="Select"
                                    className="form-control-alternative"
                                    value={filterIsFree} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterIsFree(e.target.value)}
                                >
                                    <option value="">Select</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button className="cancel-btn" size="sm" onClick={clearHandler}>Clear</Button>
                <Button className="primary-btn" size="sm" onClick={() => { setFilterPage(false); featchingData(); }}>
                    Submit
                </Button>
            </ModalFooter>
        </Modal >
    );
};

export default FilterPodcast;