import './AppUser.scss';
import { useState, useEffect } from "react";
import {
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col,
    CardFooter,
} from "reactstrap";
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from '../../shared/hooks/use-customAxios';
import Moment from 'moment';
import usePageTitle from '../../shared/hooks/use-pageTitle';
import { useDispatch } from 'react-redux';
import { uiActions } from '../../shared/store/ui-Slice';

const ViewAppUser: React.FC<any> = ({ id }) => {
    usePageTitle("App User");
    const [editData, setEditData] = useState<any>(null);
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const view = async (): Promise<void> => {
            try {
                
                let res: any = await axios.post(`user/view`, { id: !!id ? id : params?.id });
                if (res && res.status === 200) {
                    setEditData(res?.data?.data?.user);
                }
            } catch (error: any) {
                if (error && error.response && error.response.status === 400) {
                    if (error.response.data.message) {
                        navigate('/user');
                    }
                }
            }
        };

        view();
    }, []);

    const cancelHandler = () => {
        if (!!id) {
            dispatch(uiActions.pageType(''))
        } else {
            dispatch(uiActions.pageType(''));
            navigate("/history");
        }
    };

    return (
        <div className="form-user">
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="7">
                        <Card>
                            <CardBody>
                                <Row className='view-user'>
                                    <Col lg="6" xs="6">
                                        <p>Name : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.name}</p>
                                    </Col>
                                </Row>
                                <Row className='view-user'>
                                    <Col lg="6" xs="6">
                                        <p>Image : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        {!!editData?.photo ? <img className="file-img" src={editData?.photo} alt="img" /> :
                                            <p style={{ background: "#ebebeb", width: "5rem", height: "6rem" }}></p>
                                        }
                                    </Col>
                                </Row>
                                <Row className='view-user'>
                                    <Col lg="6" xs="6">
                                        <p>Country Code : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.countryCode === 0 ? '' : editData?.countryCode}</p>
                                    </Col>
                                </Row>
                                <Row className='view-user'>
                                    <Col lg="6" xs="6">
                                        <p>Email : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.email}</p>
                                    </Col>
                                </Row>
                                <Row className='view-user'>
                                    <Col lg="6" xs="6">
                                        <p>Mobile No. : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.mobileNo}</p>
                                    </Col>
                                </Row>
                                <Row className='view-user'>
                                    <Col lg="6" xs="6">
                                        <p>Gender : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.gender}</p>
                                    </Col>
                                </Row>
                                <Row className='view-user'>
                                    <Col lg="6" xs="6">
                                        <p>Country : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.country?.name}</p>
                                    </Col>
                                </Row>
                                <Row className='view-user'>
                                    <Col lg="6" xs="6">
                                        <p>State : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.state?.stateName}</p>
                                    </Col>
                                </Row>
                                <Row className='view-user'>
                                    <Col lg="6" xs="6">
                                        <p>City : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.city}</p>
                                    </Col>
                                </Row>
                                <Row className='view-user'>
                                    <Col lg="6" xs="6">
                                        <p>Preferred Languages  : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{(Array.isArray(editData?.preferredLanguages) && editData?.preferredLanguages.map((i: any, index: number) => (i?.name) + `${editData?.preferredLanguages.length !== index + 1 ? ', ' : ' '}`))
                                        }</p>
                                    </Col>
                                </Row>
                                <Row className='view-user'>
                                    <Col lg="6" xs="6">
                                        <p>Is Part Of Rss : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.isPartOfRss === true ? 'Yes' : 'No'}</p>
                                    </Col>
                                </Row>
                                <Row className='view-user'>
                                    <Col lg="6" xs="6">
                                        <p>No Of Years With Rss  : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.noOfYearsWithRss === 0 ? 'No' : editData?.noOfYearsWithRss === 1 ? 'Less than a Year' : editData?.noOfYearsWithRss === 2 ? '1 to 5 Years' : 'More than 5 Years'}</p>
                                    </Col>
                                </Row>
                                <Row className='view-user'>
                                    <Col lg="6" xs="6">
                                        <p>Updates On WhatsApp : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.updatesOnWhatsApp === true ? 'Yes' : 'No'}</p>
                                    </Col>
                                </Row>
                                <Row className='view-user'>
                                    <Col lg="6" xs="6">
                                        <p>Auth Service  : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.authService}</p>
                                    </Col>
                                </Row>
                                <Row className='view-user'>
                                    <Col lg="6" xs="6">
                                        <p>Preferred Listen Time   : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{(!!editData?.preferredListenTime?.hours ? (editData?.preferredListenTime?.hours.toString().length === 1 ? '0' + editData?.preferredListenTime?.hours : editData?.preferredListenTime?.hours) : '00') + ':' +
                                            (!!editData?.preferredListenTime?.minutes ? (editData?.preferredListenTime?.minutes.toString().length === 1 ? '0' + editData?.preferredListenTime?.minutes : editData?.preferredListenTime?.minutes) : '00')}</p>
                                    </Col>
                                </Row>
                                <Row className='view-user'>
                                    <Col lg="6" xs="6">
                                        <p>Status  : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.Status === 0 ? 'Not Verified' : 'Verified'}</p>
                                    </Col>
                                </Row>
                                <Row className='view-user'>
                                    <Col lg="6" xs="6">
                                        <p>Created At : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{Moment(editData?.createdAt).format('DD-MM-YYYY')}</p>
                                    </Col>
                                </Row>
                                <Row className='view-user'>
                                    <Col lg="6" xs="6">
                                        <p>Updated At : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{Moment(editData?.updatedAt).format('DD-MM-YYYY')}</p>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className="bg-white border-0 pl-lg-4 ">
                                <div className="form-button">
                                   <Button className="cancel-btn" size="sm" onClick={cancelHandler}>Cancel</Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default ViewAppUser;