import "./Song.scss";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import axios from '../../shared/hooks/use-customAxios';
import { success } from '../../shared/hooks/use-toastify';
import { useState } from "react";
import usePageTitle from '../../shared/hooks/use-pageTitle';

const DeleteSong: React.FC<any> = ({ setDeletePage, deletePage, editData, featchingData }) => {
    usePageTitle("Song");
    const [img, setImg] = useState<any>(editData?.img);
    const [file, setFile] = useState<any>(editData?.file);

    const deleteHandler = async (): Promise<void> => {
        try {
            let res: any = await axios.post('song/delete', { id: editData?._id });
            if (res && res.status === 200) {
                if (!!img && !!file) {
                    axios.post(`song/removePhoto`, { filename: img }).then((result) => { }).catch((error: any) => { });
                    axios.post(`song/removeAudio`, { filename: file }).then((result) => { }).catch((error: any) => { });
                }
                let payload: any = {
                    moduleName: "song",
                    activity: "delete",
                    message: `The ${editData?.name} song record has been deleted.`
                }
                await axios.post("history/add", payload);
                success('Song Deleted Successfully.');
                setDeletePage(false);
                featchingData();
            }
        } catch (error: any) { }
    };

    return (
        <div>
            <Modal isOpen={deletePage} centered={true}>
                <ModalBody>
                    Are you sure to delete the Song?
                </ModalBody>
                <ModalFooter>
                    <Button className='primary-btn' size='sm' onClick={deleteHandler}>
                        Delete
                    </Button>
                    <Button className='cancel-btn' size='sm' onClick={() => setDeletePage(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DeleteSong;