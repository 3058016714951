import { useState, useEffect } from 'react';
import './Tag.scss';
import Pagination from 'react-responsive-pagination';
import {
    Card,
    CardHeader,
    CardFooter,
    Table,
    Container,
    Row,
    Button,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Input,
    Media
} from "reactstrap";
import usePageTitle from '../../shared/hooks/use-pageTitle';
import axios from '../../shared/hooks/use-customAxios';
import Skeleton from '../../shared/components/Skeleton/Skeleton';
import FilterTag from './FilterTag';
import DeleteTag from './DeleteTag';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import AddTag from './AddTag';
import EditTag from './EditTag';
import ViewTag from './ViewTag';
import { uiActions } from '../../shared/store/ui-Slice';

const Tag = () => {
    usePageTitle("Tag");
    const pageType: string = useSelector((state: any) => state.ui.pageType);
    const dispatch = useDispatch();
    const header = ["Sr No.", "Name", "Color"];
    const [filterName, setFilterName] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [tagData, setTagData] = useState<any>([]);
    const [deletePage, setDeletePage] = useState<boolean>(false);
    const [filterPage, setFilterPage] = useState<boolean>(false);
    const options = ["5", "10", "15", "20", "25"];
    const [editData, setEditData] = useState<any>();
    const [total, setTotal] = useState<number>(0);
    const [record, setRecord] = useState<string>(options[0]);
    const [page, setPage] = useState<number>(1);
    let totalPages: number = Math.ceil(total / +record);
    let start: number = (page - 1) * +record;
    let srIndex: number = start;
    const handleChange = (newPage: number): void => {
        setPage(newPage);
    }

    const featchingData = async (): Promise<void> => {
        try {
            let obj: any = {
                pageNo: page,
                recordPerPage: record,
            };

            if (filterName && filterName !== '') {
                obj.name = filterName;
            }

            const res: any = await axios.post('tag/list', obj);
            if (res && res.status === 200) {
                setIsLoading(false);
                setTagData(res.data.data.tag);
                if (res.data.data.totalRecords || res.data.data.totalRecords === 0) {
                    setTotal(res.data.data.totalRecords);
                }
                if (res.data.data.tag.length === 0) {
                    setPage(1);
                }
            }

        } catch (error: any) {
            setIsLoading(true);
        }
    };

    useEffect(() => {
        featchingData();
    }, [page, record]);

    return (
        <>
            {pageType === 'add' && <AddTag featchingData={featchingData} />}
            {pageType === 'edit' && <EditTag featchingData={featchingData} id={editData} />}
            {pageType === 'view' && <ViewTag id={editData}  />}
            {filterPage && <FilterTag filterPage={filterPage} setFilterPage={setFilterPage} filterName={filterName} setFilterName={setFilterName} featchingData={featchingData} />}
            {deletePage && <DeleteTag deletePage={deletePage} setDeletePage={setDeletePage} editData={editData} featchingData={featchingData} />}
            {pageType === '' && (isLoading ? <Skeleton col={4} title="Tag" /> :
                <div className='tag'>
                    <Container className="mt--7" fluid>
                        <Row>
                            <div className="col">
                                <Card>
                                    <CardHeader className="border-0  d-flex justify-content-between">
                                        <div>
                                            <h3>Tag [{total}]</h3>
                                        </div>
                                        <div className="header-btn">
                                            <Button className='btn' onClick={() => dispatch(uiActions.pageType('add'))}><i className="bi bi-plus"></i> Add</Button>
                                            <Button className='filter' onClick={() => setFilterPage(true)}>Filter</Button>
                                        </div>
                                    </CardHeader>
                                    <Table className="align-items-center table-flush mb-0" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                {Array.isArray(header) && header.map((item: string, index: number) => (
                                                    <th scope="col" key={index}>{item}</th>
                                                ))}
                                                <th scope="col" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(tagData) && tagData.map((item: any, index: number) => (
                                                <tr key={index}>
                                                    <td>{++srIndex}</td>
                                                    <td>{item?.name}</td>
                                                    <td>
                                                        <Media className="align-items-center d-flex gap-3">
                                                            <div className="avatar rounded-circle mr-3" style={{ backgroundColor: `${item?.color}` }}>
                                                            </div>
                                                        </Media>
                                                    </td>
                                                    <td className="text-right">
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle className="btn-icon-only text-light" size="sm" color="">
                                                                <i className="bi bi-three-dots-vertical"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-arrow">
                                                                <DropdownItem onClick={() => { dispatch(uiActions.pageType('view')); setEditData(item?._id); }}>View</DropdownItem>
                                                                <DropdownItem onClick={() => { dispatch(uiActions.pageType('edit')); setEditData(item?._id); }}>Edit</DropdownItem>
                                                                <DropdownItem onClick={() => { setDeletePage(true); setEditData(item) }}>Delete</DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <CardFooter className="p-2">
                                        <Input type="select" value={record} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRecord(e.target.value)}>
                                            {Array.isArray(options) && options.map((item: string, index: number) => (
                                                <option key={index} value={item}>{item}</option>

                                            ))};
                                        </Input>
                                        <Pagination
                                            current={page}
                                            total={totalPages}
                                            onPageChange={handleChange}
                                            maxWidth={5}
                                        />
                                    </CardFooter>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                </div>)}
        </>
    );
};

export default Tag;
