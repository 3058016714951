import { useEffect, useState } from 'react';
import './Audiobook.scss';
import Pagination from 'react-responsive-pagination';
import {
    Card,
    CardHeader,
    CardFooter,
    Media,
    Table,
    Container,
    Row,
    Button,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Input
} from "reactstrap";
import Skeleton from '../../shared/components/Skeleton/Skeleton';
import usePageTitle from '../../shared/hooks/use-pageTitle';
import Moment from 'moment';
import axios from '../../shared/hooks/use-customAxios';
import DeleteAudiobook from './DeleteAudiobook';
import FilterAudioBook from './FilterAudioBook';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import AddAudiobook from './AddAudiobook';
import EditAudiobook from './EditAudiobook';
import ViewAudiobook from './ViewAudiobook';
import { uiActions } from '../../shared/store/ui-Slice';

const Audiobook = () => {
    usePageTitle("Audiobook");
    const pageType: string = useSelector((state: any) => state.ui.pageType);
    const dispatch = useDispatch();
    const header = ["Sr No.", "Name", "Publisher", "Author", "Category", "Language", "Created At"];
    const [filterName, setFilterName] = useState<any>('');
    const [filterPublisher, setFilterPublisher] = useState<any>('');
    const [filterAuthor, setFilterAuthor] = useState<any>('');
    const [filterSearchKeywords, setFilterSearchKeywords] = useState<any>('');
    const [filterCategory, setFilterCategory] = useState<any>('');
    const [filterLanguage, setFilterLanguage] = useState<any>('');
    const [filterTag, setFilterTag] = useState<any>('');
    const [filterIsFree, setFilterIsFree] = useState<any>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [audioBookData, setAudioBookData] = useState<any>([]);
    const [deletePage, setDeletePage] = useState<boolean>(false);
    const [editData, setEditData] = useState<any>();
    const [filterPage, setFilterPage] = useState<boolean>(false);
    const options = ["5", "10", "15", "20", "25"];
    const [total, setTotal] = useState<number>(0);
    const [record, setRecord] = useState<string>(options[0]);
    const [page, setPage] = useState<number>(1);
    let totalPages: number = Math.ceil(total / +record);
    let start: number = (page - 1) * +record;
    let srIndex: number = start;

    const handleChange = (newPage: number): void => {
        setPage(newPage);
    }

    const featchingData = async (): Promise<void> => {
        try {
            let obj: any = {
                pageNo: page,
                recordPerPage: record
            };

            if ((filterName && filterName !== '') || (filterPublisher && filterPublisher !== '') ||
                (filterAuthor && filterAuthor !== '') || (filterCategory && filterCategory !== '') ||
                (filterLanguage && filterLanguage !== '') || (filterTag && filterTag !== '') ||
                (filterTag && filterTag !== '') || (filterIsFree && filterIsFree !== '') ||
                (filterSearchKeywords && filterSearchKeywords !== '')) {
                let categories: any = [];
                if (!!filterCategory) {
                    categories = Array.isArray(filterCategory) && filterCategory.map((i: any) => i.value);
                }
                obj.name = filterName;
                obj.publisher = filterPublisher.value;
                obj.author = filterAuthor.value;
                obj.category = categories.length === 0 ? '' : categories;
                obj.language = filterLanguage;
                obj.tag = filterTag;
                obj.searchKeywords = filterSearchKeywords;
                obj.forGuestUser = filterIsFree;
            }

            const res: any = await axios.post('audiobook/list', obj);
            if (res && res.status === 200) {
                setIsLoading(false);
                setAudioBookData(res.data.data.audiobook);
                if (res.data.data.totalRecords || res.data.data.totalRecords === 0) {
                    setTotal(res.data.data.totalRecords);
                }
                if (res.data.data.audiobook.length === 0) {
                    setPage(1);
                }
            }

        } catch (error: any) {
            setIsLoading(true);
        }
    };

    useEffect(() => {
        featchingData();
    }, [page, record]);

    return (
        <>
            {pageType === 'add' && <AddAudiobook featchingData={featchingData} />}
            {pageType === 'edit' && <EditAudiobook featchingData={featchingData} eid={editData} />}
            {pageType === 'view' && <ViewAudiobook id={editData}  />}
            {filterPage && <FilterAudioBook
                filterPage={filterPage}
                setFilterPage={setFilterPage}
                filterName={filterName}
                setFilterName={setFilterName}
                filterPublisher={filterPublisher}
                setFilterPublisher={setFilterPublisher}
                filterAuthor={filterAuthor}
                setFilterAuthor={setFilterAuthor}
                filterCategory={filterCategory}
                setFilterCategory={setFilterCategory}
                filterLanguage={filterLanguage}
                setFilterLanguage={setFilterLanguage}
                filterTag={filterTag}
                setFilterTag={setFilterTag}
                filterIsFree={filterIsFree}
                setFilterIsFree={setFilterIsFree}
                filterSearchKeywords={filterSearchKeywords}
                setFilterSearchKeywords={setFilterSearchKeywords}
                featchingData={featchingData}
            />}
            {deletePage && <DeleteAudiobook deletePage={deletePage} setDeletePage={setDeletePage} editData={editData} featchingData={featchingData} />}
            {pageType === '' && (isLoading ? <Skeleton col={8} title="Audiobook" /> :
                <div className='audiobook'>
                    <Container className="mt--7" fluid>
                        <Row>
                            <div className="col">
                                <Card>
                                    <CardHeader className="border-0 d-flex justify-content-between">
                                        <div>
                                            <h3>Audiobook [{total}]</h3>
                                        </div>
                                        <div className="header-btn">
                                            <Button className='btn'  onClick={() => dispatch(uiActions.pageType('add'))}><i className="bi bi-plus"></i> Add</Button>
                                            <Button className='filter' onClick={() => setFilterPage(true)}>Filter</Button>
                                        </div>
                                    </CardHeader>
                                    <Table className="align-items-center table-flush" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                {Array.isArray(header) && header.map((item: string, index: number) => (
                                                    <th scope="col" key={index}>{item}</th>
                                                ))}
                                                <th scope="col" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(audioBookData) && audioBookData.map((item: any, index: number) => (
                                                <tr key={index}>
                                                    <td>{++srIndex}</td>
                                                    <td>
                                                        <Media className="align-items-center d-flex gap-3">
                                                            <div className="avatar rounded-circle mr-3">
                                                                <img alt="..." src={item?.img} />
                                                            </div>
                                                            <Media>
                                                                <span className="mb-0 text-sm">{item?.name}</span>
                                                            </Media>
                                                        </Media>
                                                    </td>
                                                    <td>{item?.publisher?.name}</td>
                                                    <td>{item?.author?.name}</td>
                                                    <td>{(Array.isArray(item?.category) && item?.category.map((i: any, index: number) => (i?.name) + `${item?.category.length !== index + 1 ? ', ' : ' '}`))}</td>
                                                    <td>{item?.language?.name}</td>
                                                    <td>{Moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                    <td className="text-right">
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle className="btn-icon-only text-light" size="sm" color="">
                                                                <i className="bi bi-three-dots-vertical"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-arrow">
                                                                <DropdownItem onClick={() => { dispatch(uiActions.pageType('view')); setEditData(item?._id); }}>View</DropdownItem>
                                                                <DropdownItem onClick={() => { dispatch(uiActions.pageType('edit')); setEditData(item?._id); }}>Edit</DropdownItem>
                                                                <DropdownItem onClick={() => { setDeletePage(true); setEditData(item) }}>Delete</DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <CardFooter className="p-2">
                                        <Input type="select" value={record} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRecord(e.target.value)}>
                                            {Array.isArray(options) && options.map((item: string, index: number) => (
                                                <option key={index} value={item}>{item}</option>

                                            ))}
                                        </Input>
                                        <Pagination
                                            current={page}
                                            total={totalPages}
                                            onPageChange={handleChange}
                                            maxWidth={5}
                                        />
                                    </CardFooter>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                </div>)}
        </>
    );
};

export default Audiobook;