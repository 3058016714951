let routes: any = [
    {
        path: "/",
        name: "Dashboard",
        icon: "bi bi-tv text-default"
    },
    // {
    //     path: "/role",
    //     name: "Role",
    //     icon: "bi bi-person-fill-lock text-primary"
    // },
    // {
    //     path: "/admin/user",
    //     name: "Admin User",
    //     icon: "bi bi-person-circle text-warning"
    // },
    {
        path: "/app/user",
        name: "App User",
        icon: "bi bi-people-fill text-success"
    },
    {
        path: "/publisher",
        name: "Publisher",
        icon: "bi bi-book-fill text-danger"
    },
    {
        path: "/author",
        name: "Author",
        icon: "bi bi-person-workspace text-primary"
    },
    {
        path: "/speaker",
        name: "Speaker",
        icon: "bi bi-universal-access text-info"
    },
    {
        path: "/sponsor",
        name: "Sponsor",
        icon: "bi bi-coin text-warning"
    },
    {
        name: "Category",
        icon: "bi bi-list-ul text-success",
        title: ['Category For Audiobook', 'Category For Bauddhik', 'Category For Song'],
        flag: false,
        children: [
            {
                path: "/category/audiobook",
                name: "Audiobook",
                icon: "bi bi-headphones text-warning",
                flag: false
            },
            {
                path: "/category/bauddhik",
                name: "Bauddhik",
                icon: "bi bi-speaker-fill text-primary",
                flag: false
            },
            {
                path: "/category/song",
                name: "Song",
                icon: "bi bi-file-music-fill text-default",
                flag: false
            }
        ]
    },
    {
        path: "/language",
        name: "Language",
        icon: "bi bi-translate text-default"
    },
    {
        path: "/tag",
        name: "Tag",
        icon: "bi bi-bookmark-fill text-danger"
    },
    {
        path: "/audiobook",
        name: "Audiobook",
        icon: "bi bi-headphones text-warning"
    },
    {
        path: "/bauddhik",
        name: "Bauddhik",
        icon: "bi bi-speaker-fill text-primary"
    },
    {
        path: "/song",
        name: "Song",
        icon: "bi bi-file-music-fill text-default"
    },
    {
        path: "/banner",
        name: "Banner",
        icon: "bi bi-badge-ad-fill text-info"
    },
    {
        name: "Section",
        icon: "bi bi-phone-fill text-primary",
        title: ['Section For Audiobook', 'Section For Bauddhik', 'Section For Song'],
        flag: false,
        children: [
            {
                path: "/section/audiobook",
                name: "Audiobook",
                icon: "bi bi-headphones text-warning",
                flag: false
            },
            {
                path: "/section/bauddhik",
                name: "Bauddhik",
                icon: "bi bi-speaker-fill text-primary",
                flag: false
            },
            {
                path: "/section/song",
                name: "Song",
                icon: "bi bi-file-music-fill text-default",
                flag: false
            }
        ]
    },
    {
        name: "Recommended",
        icon: "bi bi-hand-thumbs-up-fill text-success",
        title: ['Recommended For Audiobook', 'Recommended For Bauddhik', 'Recommended For Song'],
        flag: false,
        children: [
            {
                path: "/recommended/audiobook",
                name: "Audiobook",
                icon: "bi bi-headphones text-warning",
                flag: false
            },
            {
                path: "/recommended/bauddhik",
                name: "Bauddhik",
                icon: "bi bi-speaker-fill text-primary",
                flag: false
            },
            {
                path: "/recommended/song",
                name: "Song",
                icon: "bi bi-file-music-fill text-default",
                flag: false
            }
        ]
    },
    {
        name: "Search",
        icon: "bi bi-search text-danger",
        title: ['Search For Audiobook', 'Search For Bauddhik', 'Search For Song'],
        flag: false,
        children: [
            {
                path: "/search/audiobook",
                name: "Audiobook",
                icon: "bi bi-headphones text-warning",
                flag: false
            },
            {
                path: "/search/bauddhik",
                name: "Bauddhik",
                icon: "bi bi-speaker-fill text-primary",
                flag: false
            },
            {
                path: "/search/song",
                name: "Song",
                icon: "bi bi-file-music-fill text-default",
                flag: false
            }
        ]
    },
    {
        path: "/noticeBoard",
        name: "Notice Board",
        icon: "bi bi-newspaper text-info"
    },
    {
        path: "/noSearchFound",
        name: "No Search Found",
        icon: "bi bi-file-x-fill text-default"
    },
    {
        path: "/notification",
        name: "Notification",
        icon: "bi bi-bell-fill text-warning"
    },
    {
        path: "/history",
        name: "History",
        icon: "bi bi-clock-history text-danger"
    },
];

export default routes;