const useSelect = (): any => {
    let styles: any = {
        option: (provided: any, state: any) => ({
            ...provided,
            fontWeight: "400",
            fontSize: "0.875rem",
            color: state.isSelected ? "#fff" : state.isDisabled ? "#dcdcdc" : "#626262",
            backgroundColor: state.isSelected ? "#1e90ff" : "normal",
            "&:hover": {
                backgroundColor: state.isDisabled ? "normal" : "#1e90ff",
                color: state.isDisabled ? "#dcdcdc" : "#fff",
            },
        }),
        singleValue: (provided: any, state: any) => ({
            ...provided,
            color: "#626262",
            fontSize: "0.875rem",
            fontWeight: "400",
        }),
        control: (provided: any, state: any) => ({
            ...provided,
            border: state.isFocused ? "1px solid #ccc" : "1px solid #ccc",
            boxShadow: state.isFocused ? null : null,
            padding: "0.15rem 0.15rem",
            "&:hover": {
                border: state.isFocused ? "1px solid #ccc" : "1px solid #ccc",
            },
        }),
        placeholder: (provided: any, state: any) => ({
            ...provided,
            color: "#626262",
            fontSize: "0.875rem",
            fontWeight: "400",
        }),
    };

    return styles;
};

export default useSelect;