import "./SectionPodcast.scss";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    CardFooter,
    Label,
    Media,
    Spinner
} from "reactstrap";
import { useState, useEffect } from 'react';
import { Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import axios from '../../shared/hooks/use-customAxios';
import { success } from '../../shared/hooks/use-toastify';
import { useDispatch } from "react-redux";
import { uiActions } from "../../shared/store/ui-Slice";
import Select from 'react-select';
import { useSelector } from "react-redux";
import AddPodcastList from "./AddPodcastList";
import useSelect from '../../shared/hooks/use-select';
import { config } from '../../config/config';
import { useNavigate } from "react-router-dom";
import usePageTitle from '../../shared/hooks/use-pageTitle';

const AddSectionPodcast: React.FC<any> = ({featchingData}) => {
    usePageTitle("Section For Bauddhik");
    const podcastsItem: any = useSelector((state: any) => state.ui.podcastsItem);
    const bannerPodcast: any = useSelector((state: any) => state.ui.bannerPodcast);
    const [speakerList, setSpeakerList] = useState<any[]>([]);
    const [podcastModal, setPodcastModal] = useState<boolean>(false);
    const [bannerPodCastError, setBannerPodCastError] = useState<any>('');
    const [podCastError, setPodCastError] = useState<any>('');
    const [img, setImg] = useState<any>('');
    const [isLoading, setIsLoading] = useState<any>(true);
    const [bannerImg, setBannerImg] = useState<any>('');
    const [isBanner, setIsBanner] = useState<boolean>(false);
    const [imgError, setImgError] = useState<any>('');
    const [error, setError] = useState<any>();
    const dispatch = useDispatch();
    let styles: any = useSelect();
    const navigate = useNavigate();

    const sectionPodcastSchema = yup.object().shape({
        type: yup.string().required('Type is required'),
        speaker: yup.array().when("type", {
            is: (type: string) => type === "speaker",
            then: yup.array().min(1).required('Speaker is required')
        }),
        linkType: yup.string().when("type", {
            is: (type: string) => type === "banner",
            then: yup.string().required('Link Type is required')
        }),
        bannerSpeaker: yup.object().when("linkType", {
            is: (linkType: string) => linkType === "speaker",
            then: yup.object().required('Speaker is required')
        }),
        video: yup.string().when("linkType", {
            is: (linkType: string) => linkType === "video",
            then: yup.string().required('Video is required')
        }),
        url: yup.string().when("linkType", {
            is: (linkType: string) => linkType === "url",
            then: yup.string().required('Url is required')
        })
    });

    useEffect(() => {
        const selectData = async (): Promise<void> => {
            try {
                let res: any = await axios.post(`master/list`);
                if (res && res.status === 200) {
                    let speakers: any[] = res?.data?.data?.speakers;
                    if (Array.isArray(speakers) && speakers.length > 0) speakers = speakers.map((item: any) => ({ label: item?.name + "(" + item?.totalPodcast + ")", value: item?._id, isDisabled: item?.totalPodcast === 0 ? true : false }));
                    setSpeakerList(speakers);
                }
            } catch (error: any) { }
        };

        selectData();
    }, []);

    const uploadImgHandler = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        let files: FileList | null = e.target.files;

        if (!files) return;
        if (!files[0].name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
            setImgError("Please Select Valid File");
            return;
        }
        setBannerImg(files[0]);

        let fileData = new FormData();
        fileData.append("file", files[0]);

        try {
            let res: any = await axios.post(`section/uploadPhoto`, fileData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            if (res && res.status === 200) {
                setIsLoading(false);
                setImg(res.data.data.filename);
                setImgError("");
            }
        } catch (error: any) {
            if (error && error.response && error.response.status === 400) {
                (document.getElementById("image") as HTMLInputElement).value = "";
                setIsLoading(true);
                setBannerImg("");
                setImg("");
            }
        }
    };

    const removeImgHandler = async (): Promise<void> => {
        try {
            let res: any = await axios.post(`banner/removePhoto`, { filename: img });
            if (res && res.status === 200) {
                (document.getElementById("image") as HTMLInputElement).value = "";
                setIsLoading(true);
                setBannerImg("");
                setImg("");
            }
        } catch (error: any) { }
    };

    const podcastHandler = (type: string): void => {
        setPodcastModal(true);
        if (type === "podcast") {
            setIsBanner(false);
        } else {
            setIsBanner(true);
        }
    };

    const submitHandler = (values: any) => {
        if ((values.linkType === "podcast" && !bannerPodcast && bannerPodcast === null) ||
            (values.type === "podcast" && podcastsItem.length === 0) ||
            (values.type === "banner" && !img && img === "") ||
            (values.type === "banner" && isLoading && bannerImg !== "")) {
            if (values.linkType === "podcast" && !bannerPodcast && bannerPodcast === null) {
                setBannerPodCastError("Bauddhik is required");
            }
            if (values.type === "podcast" && podcastsItem.length === 0) {
                setPodCastError("Bauddhik is required");
            }
            if (values.type === "banner" && !img && img === "") {
                setImgError("Image is required");
            }
            if (values.type === "banner" && isLoading && bannerImg !== "") return;
            return;
        }
    };

    const clearHandler = (): void => {
        dispatch(uiActions.removeBannerPodcastHandler());
        dispatch(uiActions.updatePodcastHandler([]));
        dispatch(uiActions.pageType(''));
    };

    return (
        <div className="form-section-podcast">
            {podcastModal && <AddPodcastList podcastModal={podcastModal} setPodcastModal={setPodcastModal} setPodCastError={setPodCastError} isBanner={isBanner} setBannerPodCastError={setBannerPodCastError} />}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="8">
                        <Card>
                            <Formik
                                initialValues={{
                                    useFor: 'podcast',
                                    title: '',
                                    type: '',
                                    speaker: '',
                                    linkType: '',
                                    bannerSpeaker: '',
                                    video: '',
                                    url: '',
                                    status: ''
                                }}
                                validationSchema={sectionPodcastSchema}
                                onSubmit={async (values: any, { resetForm }): Promise<void> => {
                                    try {
                                        if ((values.linkType === "podcast" && !bannerPodcast && bannerPodcast === null) ||
                                            (values.type === "podcast" && podcastsItem.length === 0) ||
                                            (values.type === "banner" && !img && img === "") ||
                                            (values.type === "banner" && isLoading && bannerImg !== "")) {
                                            if (values.linkType === "podcast" && !bannerPodcast && bannerPodcast === null) {
                                                setBannerPodCastError("Bauddhik is required");
                                            }
                                            if (values.type === "podcast" && podcastsItem.length === 0) {
                                                setPodCastError("Bauddhik is required");
                                            }
                                            if (values.type === "banner" && !img && img === "") {
                                                setImgError("Image is required");
                                            }
                                            if (values.type === "banner" && isLoading && bannerImg !== "") return;
                                            return;
                                        }

                                        let podCasts: any = Array.isArray(podcastsItem) && podcastsItem.map((i: any) => i?._id);
                                        let speakers: any = Array.isArray(values.speaker) && values.speaker.map((i: any) => i?.value);
                                        let banner: any = {
                                            img: img,
                                            linkType: values.linkType,
                                        };

                                        if (values.linkType === "podcast") banner.podCast = bannerPodcast?._id;
                                        if (values.linkType === "speaker") banner.speaker = values.bannerSpeaker.value;
                                        if (values.linkType === "video") banner.video = values.video;
                                        if (values.linkType === "url") banner.url = values.url;

                                        let payload: any = {
                                            useFor: "podcast",
                                            title: values.title,
                                            type: values.type,
                                            status: values.status === true ? 1 : 0
                                        };

                                        if (values.type === "podcast") payload.podCast = podCasts;
                                        if (values.type === "speaker") payload.speaker = speakers;
                                        if (values.type === "banner") payload.banner = banner;

                                        let res: any = await axios.post('section/add', payload);
                                        if (res && res.status === 200) {
                                            let payload: any = {
                                                moduleName: "section for bauddhik",
                                                moduleLink: `${config.front_url}section/bauddhik/view/${res?.data?.data?.section}`,
                                                activity: "insert",
                                                message: `The ${values.title} section for buaddhik record has been inserted.`
                                            }
                                            await axios.post("history/add", payload);
                                            success('Section Created Successfully.');
                                            resetForm({ values: '' });
                                            setBannerImg("");
                                            setImg("");
                                            dispatch(uiActions.removeBannerPodcastHandler());
                                            dispatch(uiActions.updatePodcastHandler([]));
                                            featchingData();
                                            dispatch(uiActions.pageType(''));
                                        }
                                    } catch (error: any) {
                                        if (error.response) {
                                            setError(error.response.data.error);
                                        }
                                    }
                                }} >
                                {({ handleBlur, handleChange, values, setFieldValue, setFieldTouched }) => (
                                    <Form>
                                        <CardBody className="pb-2">
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-type">Type <span className="text-danger">*</span></label>
                                                            <Input
                                                                id="input-type"
                                                                name="type"
                                                                type="select"
                                                                placeholder="Select"
                                                                className="form-control-alternative"
                                                                value={values.type} onChange={handleChange} onBlur={handleBlur}
                                                            >
                                                                <option disabled value="">Select</option>
                                                                <option value="podcast">Bauddhik</option>
                                                                <option value="speaker">Speaker</option>
                                                                <option value="banner">Banner</option>
                                                                <option value="language">Language</option>
                                                            </Input>
                                                            {error ? <div className="error-message">{error?.type ? error?.type : ''}</div> : <ErrorMessage component="div" className="error-message" name="type" />}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-title">Title</label>
                                                            <Input className="form-control-alternative" name="title" id="input-title" placeholder="Title" type="text" onChange={handleChange} onBlur={handleBlur} value={values.title} />
                                                            <ErrorMessage component="div" className="error-message" name="title" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup switch>
                                                            <Label check className="switch">Status</Label>
                                                            <Input type="switch" role="switch" name="status" checked={values.status} onChange={handleChange} onBlur={handleBlur} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                {(values.type !== '' && values.type !== 'language') && <><h6 className="heading-small text-muted mb-2">
                                                    {values.type === "podcast" ? "Bauddhik" : values.type} Details
                                                </h6>
                                                    <hr className="my-3" /></>}
                                                <Row>
                                                    {values.type === "speaker" && <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-speaker">Speaker <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="speaker"
                                                                options={speakerList}
                                                                value={values.speaker}
                                                                onChange={(e) => { setFieldValue("speaker", e) }}
                                                                onBlur={(e: any) => { setFieldTouched("speaker", e) }}
                                                                styles={styles}
                                                                placeholder="Select"
                                                            />
                                                            {error ? <div className="error-message">{error?.speaker ? error?.speaker : ''}</div> : <ErrorMessage component="div" className="error-message" name="speaker" />}
                                                        </FormGroup>
                                                    </Col>}
                                                    {values.type === "banner" && <Col lg="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="image">Image (700x90) <span className="text-danger">*</span></label>
                                                            <div className='file-card'>
                                                                <div className='file-inputs'>
                                                                    <Input className="form-control-alternative file-upload" disabled={bannerImg} id="image" type="file" multiple={false} onChange={uploadImgHandler} />
                                                                    <button className='file-btn' disabled={bannerImg}><i className="bi bi-cloud-arrow-up file-svg"></i>Upload</button>
                                                                </div>
                                                            </div>
                                                            {bannerImg && bannerImg !== '' && <ul className='file-list'>
                                                                <li className='list-item' >
                                                                    {isLoading ? <Spinner
                                                                        className="m-4"
                                                                    >
                                                                        Loading...
                                                                    </Spinner> :
                                                                        <>
                                                                            <img className="file-img" src={img} alt="img" />
                                                                            <div className='actions'>
                                                                                <i className="bi bi-x" onClick={removeImgHandler}></i>
                                                                            </div>
                                                                        </>}
                                                                </li>
                                                            </ul>}
                                                        </FormGroup>
                                                        {(imgError && imgError !== '') && <div className="error-img-message">{imgError}</div>}
                                                    </Col>}
                                                    {values.type === "banner" && <Col lg="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-type">Link Type <span className="text-danger">*</span></label>
                                                            <Input
                                                                id="input-type"
                                                                name="linkType"
                                                                type="select"
                                                                placeholder="Select"
                                                                className="form-control-alternative"
                                                                value={values.linkType} onChange={handleChange} onBlur={handleBlur}
                                                            >
                                                                <option disabled value="">Select</option>
                                                                <option value="none">None</option>
                                                                <option value="podcast">Bauddhik</option>
                                                                <option value="speaker">Speaker</option>
                                                                <option value="video">Video</option>
                                                                <option value="url">URL</option>
                                                            </Input>
                                                            {error ? <div className="error-message">{error?.banner_linkType ? error?.banner_linkType : ''}</div> : <ErrorMessage component="div" className="error-message" name="linkType" />}
                                                        </FormGroup>
                                                    </Col>}
                                                </Row>
                                                <Row>
                                                    {((values.type === "podcast") || (values.linkType === "podcast")) && <Col lg="12">
                                                        <FormGroup className="d-grid">
                                                            <label className="form-control-label" htmlFor="input-audiobook">Bauddhik <span className="text-danger">*</span></label>
                                                            <Button className='primary-btn' onClick={() => podcastHandler(values.type)}><i className="bi bi-plus"></i> Add</Button>
                                                        </FormGroup>
                                                        {(podCastError && podCastError !== '') ? <div className="error-img-message">{podCastError}</div> : (bannerPodCastError && bannerPodCastError !== '') ? <div className="error-img-message">{bannerPodCastError}</div> : ''}
                                                    </Col>}
                                                </Row>
                                                <Row>
                                                    {((values.type === "banner") && (values.linkType === "speaker")) && <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-speaker">Speaker <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="bannerSpeaker"
                                                                options={speakerList}
                                                                value={values.bannerSpeaker}
                                                                onChange={(e) => { setFieldValue("bannerSpeaker", e) }}
                                                                onBlur={(e: any) => { setFieldTouched("bannerSpeaker", e) }}
                                                                styles={styles}
                                                                placeholder="Select"
                                                            />
                                                            {error ? <div className="error-message">{error?.banner_speaker ? error?.banner_speaker : ''}</div> : <ErrorMessage component="div" className="error-message" name="bannerSpeaker" />}
                                                        </FormGroup>
                                                    </Col>}
                                                    {((values.type === "banner") && (values.linkType === "video")) && <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-video">Video <span className="text-danger">*</span></label>
                                                            <Input className="form-control-alternative" name="video" id="input-video" placeholder="Video" type="text" onChange={handleChange} onBlur={handleBlur} value={values.video} />
                                                            {error ? <div className="error-message">{error?.banner_video ? error?.banner_video : ''}</div> : <ErrorMessage component="div" className="error-message" name="video" />}
                                                        </FormGroup>
                                                    </Col>}
                                                    {((values.type === "banner") && (values.linkType === "url")) && <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-url">Url <span className="text-danger">*</span></label>
                                                            <Input className="form-control-alternative" name="url" id="input-url" placeholder="URL" type="text" onChange={handleChange} onBlur={handleBlur} value={values.url} />
                                                            {error ? <div className="error-message">{error?.banner_url ? error?.banner_url : ''}</div> : <ErrorMessage component="div" className="error-message" name="url" />}
                                                        </FormGroup>
                                                    </Col>}
                                                </Row>
                                                <Row>
                                                    {((values.type === "podcast" && podcastsItem.length === 0) || (values.linkType === "podcast" && bannerPodcast === null)) && <h6 className="p-4 text-muted d-flex align-items-center justify-content-center"><i className="bi bi-x-circle text-danger"></i>&nbsp;No Bauddhik Found</h6>}
                                                    {values.type === "podcast" && Array.isArray(podcastsItem) && podcastsItem.map((item: any, index: number) => (
                                                        <Card className="file-list-card" key={index}>
                                                            <CardBody className="p-3">
                                                                <div className="file-first-section">
                                                                    <label className="form-control-label-first" htmlFor="input-file-name">{index + 1}</label>
                                                                    <Media className="align-items-center d-flex gap-2">
                                                                        <div className="avatar rounded-circle mr-3">
                                                                            <img alt="..." src={item?.img} />
                                                                        </div>
                                                                        <Media>
                                                                            <span className="mb-0">{item?.name}</span>
                                                                        </Media>
                                                                    </Media>
                                                                </div>
                                                                <i className="bi bi-x-circle-fill text-danger" onClick={() => dispatch(uiActions.removePodcastHandler(item?._id))}></i>
                                                            </CardBody>
                                                        </Card>
                                                    ))}
                                                    {((values.type === "banner") && (values.linkType === "podcast") && (bannerPodcast !== null)) &&
                                                        <Card className="file-list-card">
                                                            <CardBody className="p-3">
                                                                <div className="file-first-section">
                                                                    <Media className="align-items-center d-flex gap-2">
                                                                        <div className="avatar rounded-circle mr-3">
                                                                            <img alt="..." src={bannerPodcast?.img} />
                                                                        </div>
                                                                        <Media>
                                                                            <span className="mb-0">{bannerPodcast?.name}</span>
                                                                        </Media>
                                                                    </Media>
                                                                </div>
                                                                <i className="bi bi-x-circle-fill text-danger" onClick={() => dispatch(uiActions.removeBannerPodcastHandler())}></i>
                                                            </CardBody>
                                                        </Card>}
                                                </Row>
                                            </div>
                                        </CardBody>
                                        <CardFooter className="bg-white border-0 pl-lg-4 ">
                                            <div className="form-button">
                                                <Button className="primary-btn" size="sm" type="submit" onClick={() => submitHandler(values)}>Submit</Button>
                                                <Button className="cancel-btn" size="sm" onClick={clearHandler}>Cancel</Button>
                                            </div>
                                        </CardFooter>
                                    </Form>
                                )}
                            </Formik>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default AddSectionPodcast; 