import routes from "../../routes";

export const route = (route: any[]): any[] => {
    let user: any = JSON.parse(localStorage.getItem("user") as any);
    let routeArr: any = [];
    Array.isArray(route) && route.map((item: any) => {
        user?.permission.forEach((el: any) => {
            let key: any = item?.props?.path?.toString()?.replace(/\/:id/g, "");
            if (key == el.key) {
                routeArr.push(item);
            }
        });
    })

    return routeArr;
};

export const sidebarRoute = (): any[] => {
    let user: any = JSON.parse(localStorage.getItem("user") as any);
    let routeArr: any = [{
        path: "/",
        name: "Dashboard",
        icon: "bi bi-tv text-default"
    }];
    Array.isArray(routes) && routes.map((item: any) => {
        user?.permission.forEach((el: any) => {
            if (item?.path == el.key) {
                routeArr.push(item);
            } else if (item?.children) {
                let arr: any = Array.isArray(item?.children) && item?.children.map((i: any) => {
                    if (i?.path == el?.key) {
                        i.path = i?.path;
                        i.name = i?.name;
                        i.icon = i?.icon;
                        i.flag = true;
                    }
                    return i;
                })
                let obj: any = {};
                obj.name = item?.name;
                obj.icon = item?.icon;
                obj.title = item?.title;
                obj.flag = true;
                obj.children = arr;
                let router: any = Array.isArray(routeArr) && routeArr.some((item: any) => item?.name == obj.name);
                if (!router) {
                    routeArr.push(obj);
                } 
            }
        });
    })

    return routeArr;
};