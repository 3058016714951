// import { useEffect, useState } from 'react';
import usePageTitle from '../../shared/hooks/use-pageTitle';
import './Profile.scss';

const Profile = () => {
    usePageTitle("My Profile");
    // const [user, setUser] = useState<any>({userRole: '', username: ''});

    // useEffect(() => {
    //     let users: any = JSON.parse(localStorage.getItem("user") as any);
    //     setUser({userRole: users?.userRole, username: users?.username});
    // }, []);

    return (
        <div className='profile'>
            <div className='main-background'></div>
            <div className='background-block'></div>
            <div className="profile-avatar">
                <div className="avatar">
                    <img className='img-fluid rounded-circle' alt="admin" src={require("../../assets/images/admin.png")} />
                </div>
                <div className="name">
                    <h3 className='title'>Admin</h3>
                    <h6>admin.audiokumbh@gmail.com</h6>
                </div>
            </div>
        </div>
    );
};

export default Profile;