import './Audiobook.scss';
import { useState, useEffect } from "react";
import {
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col,
    CardFooter,
    Table,
} from "reactstrap";
import Moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../shared/hooks/use-customAxios';
import usePageTitle from '../../shared/hooks/use-pageTitle';
import { useDispatch } from 'react-redux';
import { uiActions } from '../../shared/store/ui-Slice';

const ViewAudiobook: React.FC<any> = ({ id }) => {
    usePageTitle("Audiobook");
    const [editData, setEditData] = useState<any>(null);
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const view = async (): Promise<void> => {
            try {
                let res: any = await axios.post(`audiobook/view`, { id: !!id ? id : params?.id });
                if (res && res.status === 200) {
                    setEditData(res?.data?.data?.audiobook);
                }
            } catch (error: any) {
                if (error && error.response && error.response.status === 400) {
                    if (error.response.data.message) {
                        navigate('/audiobook');
                    }
                }
            }
        };

        view();
    }, []);

    const cancelHandler = () => {
        if (!!id) {
            dispatch(uiActions.pageType(''))
        } else {
            dispatch(uiActions.pageType(''));
            navigate("/history");
        }
    };

    return (
        <div className="form-audiobook">
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="7">
                        <Card>
                            <CardBody>
                                <Row className='view-audiobook'>
                                    <Col lg="6" xs="6">
                                        <p>Name : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.name}</p>
                                    </Col>
                                </Row>
                                <Row className='view-audiobook'>
                                    <Col lg="6" xs="6">
                                        <p>Publisher : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.publisher?.name}</p>
                                    </Col>
                                </Row>
                                <Row className='view-audiobook'>
                                    <Col lg="6" xs="6">
                                        <p>Author : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.author?.name}</p>
                                    </Col>
                                </Row>
                                <Row className='view-audiobook'>
                                    <Col lg="6" xs="6">
                                        <p>Category : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{(Array.isArray(editData?.category) && editData?.category.map((i: any, index: number) => (i?.name) + `${editData?.category?.length !== index + 1 ? ', ' : ' '}`))}</p>
                                    </Col>
                                </Row>
                                <Row className='view-audiobook'>
                                    <Col lg="6" xs="6">
                                        <p>Language : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.language?.name}</p>
                                    </Col>
                                </Row>
                                <Row className='view-audiobook'>
                                    <Col lg="6" xs="6">
                                        <p>Image : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <img className="file-img" src={editData?.img} alt="img" />
                                    </Col>
                                </Row>
                                <Row className='view-audiobook'>
                                    <Col lg="6" xs="6">
                                        <p>Search Keywords : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.searchKeywords}</p>
                                    </Col>
                                </Row>
                                <Row className='view-audiobook'>
                                    <Col lg="6" xs="6">
                                        <p>Sponsor : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.sponsor?.name}</p>
                                    </Col>
                                </Row>
                                <Row className='view-audiobook'>
                                    <Col lg="6" xs="6">
                                        <p>Description : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.description}</p>
                                    </Col>
                                </Row>
                                <Row className='view-audiobook'>
                                    <Col lg="6" xs="6">
                                        <p>Hard Cover Link : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.hardCoverLink}</p>
                                    </Col>
                                </Row>
                                <Row className='view-audiobook'>
                                    <Col lg="6" xs="6">
                                        <p>Share Message : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.shareMessage}</p>
                                    </Col>
                                </Row>
                                <Row className='view-audiobook'>
                                    <Col lg="6" xs="6">
                                        <p>Tag : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.tag?.name}</p>
                                    </Col>
                                </Row>
                                <Row className='view-audiobook'>
                                    <Col lg="6" xs="6">
                                        <p>Size : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.size}</p>
                                    </Col>
                                </Row>
                                <Row className='view-audiobook'>
                                    <Col lg="6" xs="6">
                                        <p>Length : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.length?.hours.toString()?.length === 1 ? '0' + editData?.length?.hours : editData?.length?.hours} : {editData?.length?.minutes.toString()?.length === 1 ? '0' + editData?.length?.minutes : editData?.length?.minutes} : {editData?.length?.seconds.toString()?.length === 1 ? '0' + editData?.length?.seconds : editData?.length?.seconds}</p>
                                    </Col>
                                </Row>
                                <Row className='view-audiobook'>
                                    <Col lg="6" xs="6">
                                        <p>PDF : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{(editData?.pdf && editData?.pdf !== null) ? <a href={editData?.pdf} target="_blank" style={{ textDecoration: "none", color: "#626575" }}>View</a> : ''}</p>
                                    </Col>
                                </Row>
                                <Row className='view-audiobook'>
                                    <Col lg="6" xs="6">
                                        <p>For Guest User : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.forGuestUser === 0 ? 'No' : 'Yes'}</p>
                                    </Col>
                                </Row>
                                <Row className='view-audiobook'>
                                    <Col lg="6" xs="6">
                                        <p>Is Live : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.isLive === 0 ? 'No' : 'Yes'}</p>
                                    </Col>
                                </Row>
                                <h6 className="heading-small text-muted">
                                    Play List
                                </h6>
                                <hr className="my-2" />
                                {(editData?.playList && editData?.playList?.length > 0) ? <Row>
                                    <Col>
                                        <Card className="file-list-audiobook">
                                            <Table className="align-items-center table-flush" responsive>
                                                <tbody>
                                                    {Array.isArray(editData?.playList) && editData?.playList.map((item: any, index: number) => (
                                                        <tr key={index}>
                                                            <td>{item?.position}</td>
                                                            <td>{item?.title}</td>
                                                            <td>
                                                                <audio controls>
                                                                    <source src={item?.file} type="audio/mpeg" />
                                                                </audio>
                                                            </td>
                                                            <td>{item?.size}</td>
                                                            <td>{item?.length?.hours.toString()?.length === 1 ? '0' + item?.length?.hours : item?.length?.hours} :
                                                                {item?.length?.minutes.toString()?.length === 1 ? '0' + item?.length?.minutes : item?.length?.minutes} :
                                                                {item?.length?.seconds.toString()?.length === 1 ? '0' + item?.length?.seconds : item?.length?.seconds}</td>
                                                            <td>{item?.forGuestUser === 1 ? <i className="bi bi-check-circle-fill text-success"></i> : <i className="bi bi-x-circle-fill text-danger"></i>}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Card>
                                    </Col>
                                </Row> :
                                    <p className="p-3 d-flex align-items-center justify-content-center view-audiobook"><i className="bi bi-x-circle text-danger"></i>&nbsp;No PlayFile Found</p>}
                                <Row className='view-audiobook'>
                                    <Col lg="6" xs="6">
                                        <p>Created At : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{Moment(editData?.createdAt).format('DD-MM-YYYY')}</p>
                                    </Col>
                                </Row>
                                <Row className='view-audiobook'>
                                    <Col lg="6" xs="6">
                                        <p>Updated At : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{Moment(editData?.updatedAt).format('DD-MM-YYYY')}</p>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className="bg-white border-0 pl-lg-4 ">
                                <div className="form-button">
                                    <Button className='cancel-btn' size="sm" onClick={cancelHandler}>Cancel</Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default ViewAudiobook;