import { createSlice } from '@reduxjs/toolkit';

interface UIState {
    sidebarIsVisible: boolean,
    pageTitle: string,
    audiobooksItem: Array<any>,
    podcastsItem: Array<any>,
    songsItem: Array<any>,
    bannerAudiobook: any,
    bannerPodcast: any,
    bannerSong: any,
    pageType: string
}

const initialState: UIState = {
    sidebarIsVisible: false, pageTitle: "Dashboard", pageType: '', audiobooksItem: [], podcastsItem: [], songsItem: [], bannerAudiobook: null, bannerPodcast: null, bannerSong: null
}

const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        toggle(state) {
            state.sidebarIsVisible = !state.sidebarIsVisible;
        },
        pageHandler(state, action) {
            state.pageTitle = action.payload;
        },
        pageType(state, action) {
            state.pageType = action.payload;
        },
        addAudiobookHandler(state, action) {
            state.audiobooksItem.push(action.payload);
        },
        updateAudiobookHandler(state, action) {
            state.audiobooksItem = action.payload;
        },
        removeAudiobookHandler(state, action) {
            state.audiobooksItem = state.audiobooksItem.filter((item: any) => item._id !== action.payload);
        },
        addPodcastHandler(state, action) {
            state.podcastsItem.push(action.payload);
        },
        updatePodcastHandler(state, action) {
            state.podcastsItem = action.payload;
        },
        removePodcastHandler(state, action) {
            state.podcastsItem = state.podcastsItem.filter((item: any) => item._id !== action.payload);
        },
        addSongHandler(state, action) {
            state.songsItem.push(action.payload);
        },
        updateSongHandler(state, action) {
            state.songsItem = action.payload;
        },
        removeSongHandler(state, action) {
            state.songsItem = state.songsItem.filter((item: any) => item._id !== action.payload);
        },
        addBannerAudiobookHandler(state, action) {
            state.bannerAudiobook = action.payload;
        },
        removeBannerAudiobookHandler(state) {
            state.bannerAudiobook = null;
        },
        addBannerPodcastHandler(state, action) {
            state.bannerPodcast = action.payload;
        },
        removeBannerPodcastHandler(state) {
            state.bannerPodcast = null;
        },
        addBannerSongHandler(state, action) {
            state.bannerSong = action.payload;
        },
        removeBannerSongHandler(state) {
            state.bannerSong = null;
        }
    }
});

export default uiSlice;
export const uiActions = uiSlice.actions;