import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './Navbar.scss';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import { uiActions } from '../../store/ui-Slice';
import { authActions } from '../../store/auth-Slice';

const Navbar: React.FC<any> = ({ setIsLogin }) => {
    const pageTitle: string = useSelector((state: any) => state.ui.pageTitle);
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    // const [userRole, setUserRole] = useState<any>('');
    const dispatch = useDispatch();

    const toggle = (): void => setDropdownOpen((prevState: boolean) => !prevState);
    const sidebarVisisbleHandler = (): void => {
        dispatch(uiActions.toggle());
    };

    const logoutHandler = (): void => {
        localStorage.clear();
        setIsLogin(false);
        dispatch(authActions.logout());
    };

    // useEffect(() => {
    //     let user: any = JSON.parse(localStorage.getItem("user") as any);
    //     setUserRole(user?.userRole);
    // }, []);

    return (
        <>
            <div className='admin-navbar'>
                <div className='sidebar_btn'>
                    <button className='btn' onClick={sidebarVisisbleHandler}><i className="bi bi-justify"></i></button>
                    <h6 className='name'>{pageTitle}</h6>
                </div>
                <div className='admin'>
                    <div className="avtar">
                        <img alt="admin" src={require("../../../assets/images/admin.png")} />
                    </div>
                    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                        <DropdownToggle caret>Admin</DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem header>WELCOME!</DropdownItem>
                            <DropdownItem><NavLink to="/my-profile" className={({ isActive }) => (isActive ? 'active' : 'inactive')}><i className="bi bi-person-fill"></i><span>My profile</span></NavLink></DropdownItem>
                            <DropdownItem className='inactive'><i className="bi bi-gear-fill"></i><span>Settings</span></DropdownItem>
                            <DropdownItem divider />
                            <DropdownItem className='inactive' onClick={logoutHandler}><i className="bi bi-box-arrow-right" ></i><span>Logout</span></DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>
        </>
    );
};

export default Navbar;