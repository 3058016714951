import "./Podcast.scss";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormGroup,
    Input,
    Col,
    Label,
    Row,
    Progress
} from 'reactstrap';
import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import axios from '../../shared/hooks/use-customAxios';
import { useState } from "react";
import { success } from "../../shared/hooks/use-toastify";
import { config } from '../../config/config';
import usePageTitle from '../../shared/hooks/use-pageTitle';

const AddFile: React.FC<any> = ({ filePage, setFilePage, id, length, name }) => {
    usePageTitle("Bauddhik");
    const [audioFile, setAudioFile] = useState<any>("");
    const [file, setFile] = useState<any>("");
    const [hours, setHours] = useState<any>(null);
    const [minutes, setMinutes] = useState<any>(null);
    const [seconds, setSeconds] = useState<any>(null);
    const [size, setSize] = useState<any>(null);
    const [duration, setDuration] = useState<any>(null);
    const [audioFileError, setAudioFileError] = useState<any>('');
    const [uploaded, setUploaded] = useState<any>(null);
    const [error, setError] = useState<any>();
    const fileSchema = yup.object().shape({
        title: yup.string().required('Title is required')
    });

    const uploadFileHandler = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        let files: FileList | null = e.target.files;

        if (!files) return;
        if (!files[0]) {
            setAudioFileError("File is required");
            return;
        } else if (!files[0].name.match(/\.(mp3|MP3)$/)) {
            setAudioFileError("Please Select Valid File");
            return;
        }
        setAudioFile(files[0]);

        let fileData = new FormData();
        fileData.append("file", files[0]);

        const options = {
            onUploadProgress: (data: any) => {
                let percent: number = Math.floor((data.loaded / data.total) * 100);
                if (percent < 100) {
                    setUploaded(percent);
                }
            }
        }

        try {
            let res: any = await axios.post(`podcast/uploadAudio`, fileData, options);
            if (res && res.status === 200) {
                setUploaded(100);
                setFile(res.data.data.filename);
                setAudioFileError("");
                setSize(+(files[0].size / 1024 / 1024).toFixed(2));
                let audio: any = document.createElement('audio');
                audio.setAttribute("id", "audio");
                audio.src = res.data.data.filename;
                audio.addEventListener('loadedmetadata', function () {
                    let seconds: any = audio.duration.toString().split(".", 1).toString();
                    setDuration(+seconds);
                    setHours(Math.floor(+seconds / 3600));
                    setMinutes(Math.floor(+seconds % 3600 / 60));
                    setSeconds(Math.floor(+seconds % 3600 % 60));
                    audio.removeEventListener('loadedmetadata', function () { });
                }, false);
            }
        } catch (error: any) {
            if (error.response.status === 400) {
                (document.getElementById("file") as HTMLInputElement).value = "";
                setUploaded(null);
                setAudioFile("");
                setFile("");
            }
        }
    };

    const removeFileHandler = async () => {
        try {
            let res: any = await axios.post(`podcast/removeAudio`, { filename: file });
            if (res && res.status === 200) {
                (document.getElementById("file") as HTMLInputElement).value = "";
                setUploaded(null);
                setAudioFile("");
                setFile("");
            }
        } catch (error: any) { }
    };

    const submitHandler = (): void => {
        if ((!file && file === "") || (uploaded !== null && uploaded >= 0 && uploaded < 100)) {
            if (!file && file === "") {
                setAudioFileError("File is required");
                return;
            }
            if (uploaded !== null && uploaded >= 0 && uploaded < 100) return;
            return;
        }
    };

    return (
        <div>
            <Modal className="file-form" isOpen={filePage} centered={true}>
                <ModalHeader>Add PlayFile</ModalHeader>
                <Formik
                    initialValues={{
                        title: '',
                        forGuestUser: false
                    }}
                    validationSchema={fileSchema}
                    onSubmit={async (values: any, { resetForm }): Promise<void> => {
                        try {
                            if ((!file && file === "") || (uploaded !== null && uploaded >= 0 && uploaded < 100)) {
                                if (!file && file === "") {
                                    setAudioFileError("File is required");
                                    return;
                                }
                                if (uploaded !== null && uploaded >= 0 && uploaded < 100) return;
                                return;
                            }

                            let payload: any = {
                                id: id,
                                title: values.title,
                                file: file,
                                length: {
                                    hours: hours,
                                    minutes: minutes,
                                    seconds: seconds
                                },
                                duration: duration,
                                size: size,
                                position: length + 1,
                                forGuestUser: values.forGuestUser === true ? 1 : 0
                            };

                            let res: any = await axios.post('podcast/addPlayFile', payload);
                            if (res && res.status === 200) {
                                let payload: any = {
                                    moduleName: "bauddhik",
                                    moduleLink: `${config.front_url}bauddhik/view/${id}`,
                                    activity: "update",
                                    message: `The ${name} bauddhik in ${values.title} play file record has been inserted.`
                                }
                                await axios.post("history/add", payload);
                                success("PlayFile Created Successfully.")
                                resetForm({ values: "" });
                                setFile("");
                                setAudioFile("");
                                setFilePage(false);
                            }
                        } catch (error: any) {
                            if (error.response) {
                                setError(error.response.data.error);
                            }
                        }
                    }}
                >
                    {({ handleBlur, handleChange, values }) => (
                        <Form>
                            <ModalBody>
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col lg="12">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="input-audio-title">Audio Title <span className="text-danger">*</span></label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-audio-title"
                                                    placeholder="Audio Title"
                                                    type="text"
                                                    name="title"
                                                    value={values.title}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                                {error ? <div className="error-message">{error?.title ? error?.title : ''}</div> : <ErrorMessage component="div" className="error-message" name="title" />}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <FormGroup>
                                                <label className="form-control-label" htmlFor="audio">Audio File <span className="text-danger">*</span></label>
                                                <div className='file-card'>
                                                    <div className='file-inputs'>
                                                        <Input className="form-control-alternative file-upload" disabled={audioFile} id="file" type="file" multiple={false} onChange={uploadFileHandler} />
                                                        <button className='file-btn' disabled={audioFile}><i className="bi bi-cloud-arrow-up file-svg"></i>Upload</button>
                                                    </div>
                                                </div>
                                                {(audioFile && audioFile !== "") && <ul className='file-list'>
                                                    <li className='audio-file'>
                                                        <Progress
                                                            className="mt-2"
                                                            value={uploaded}
                                                            color="secondary"
                                                            min={0}
                                                            max={100}
                                                            animated={true}
                                                            striped={true}
                                                        >
                                                            {uploaded}%
                                                        </Progress>
                                                        <div className='list-item-audio'>
                                                            <audio controls src={file}>
                                                                <source src={file} type="audio/mpeg" />
                                                            </audio>
                                                            {uploaded === 100 && <div className='actions'>
                                                                <i className="bi bi-x" onClick={removeFileHandler}></i>
                                                            </div>}
                                                        </div>
                                                    </li>
                                                </ul>}
                                            </FormGroup>
                                            {error ? <div className="error-file-message">{error?.file ? error?.file : ''}</div> : (audioFileError && audioFileError !== '') && <div className="error-file-message">{audioFileError}</div>}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <FormGroup switch>
                                                <Label check className="switch">For Guest User</Label>
                                                <Input type="switch" role="switch" name="forGuestUser" onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    checked={values.forGuestUser} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button className="primary-btn" size="sm" type="submit" onClick={submitHandler}>
                                    Submit
                                </Button>
                                <Button className="cancel-btn" size="sm" onClick={() => setFilePage(false)}>Cancel</Button>
                            </ModalFooter>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div >
    );
};

export default AddFile;