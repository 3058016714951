import './Dashboard.scss';
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import usePageTitle from '../../shared/hooks/use-pageTitle';
import { useEffect, useState } from 'react';
import axios from '../../shared/hooks/use-customAxios';
import { Link } from 'react-router-dom';

const Dashboard = () => {
    usePageTitle("Dashboard");
    const [count, setCount] = useState<any>({ audiobook: '', podcast: '', song: '', user: '', publisher: '', author: '', speaker: '', sponsor: '', banner: '', category: '' });

    useEffect(() => {
        const countHandler = async (): Promise<void> => {
            try {
                let res: any = await axios.post('master/count');
                if (res && res.status === 200) {
                    setCount({
                        audiobook: res.data.data.totalAudiobook,
                        podcast: res.data.data.totalPodcast,
                        song: res.data.data.totalSong,
                        user: res.data.data.totalUser,
                        publisher: res.data.data.totalPublisher,
                        author: res.data.data.totalAuthor,
                        speaker: res.data.data.totalSpeaker,
                        sponsor: res.data.data.totalSponsor,
                        banner: res.data.data.totalBanner,
                        category: res.data.data.totalCategory
                    });
                }
            } catch (error: any) { }
        };

        countHandler();
    }, []);

    return (
        <>
            <div className="header bg-gradient-info">
                <Container fluid>
                    <div className="header-body">
                        <Row>
                            <Col lg="6" xl="3">
                                <Card className="card-stats">
                                    <Link to="/user">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">TOTAL APP USER</CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">{count.user}</span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                                                        <i className="bi bi-people-fill" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Link>
                                </Card>
                            </Col>
                            <Col lg="6" xl="3">
                                <Card className="card-stats">
                                    <Link to="/publisher">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">TOTAL PUBLISHER</CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">{count.publisher}</span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                        <i className="bi bi-book-fill" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Link>
                                </Card>
                            </Col>
                            <Col lg="6" xl="3">
                                <Card className="card-stats">
                                    <Link to="/author">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">TOTAL AUTHOR</CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">{count.author}</span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                                                        <i className="bi bi-person-workspace" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Link>
                                </Card>
                            </Col>
                            <Col lg="6" xl="3">
                                <Card className="card-stats">
                                    <Link to="/speaker">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">TOTAL SPEAKER</CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">{count.speaker}</span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                                                        <i className="bi bi-universal-access" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Link>
                                </Card>
                            </Col>
                            <Col lg="6" xl="3">
                                <Card className="card-stats">
                                    <Link to="/sponsor">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">TOTAL SPONSOR</CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">{count.sponsor}</span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                                        <i className="bi bi-coin" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Link>
                                </Card>
                            </Col>
                            <Col lg="6" xl="3">
                                <Card className="card-stats">
                                    <Link to="/category/audiobook">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">TOTAL CATEGORY</CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">{count.category}</span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-dark text-white rounded-circle shadow">
                                                        <i className="bi bi-diagram-2-fill" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Link>
                                </Card>
                            </Col>
                            <Col lg="6" xl="3">
                                <Card className="card-stats">
                                    <Link to="/audiobook">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">TOTAL AUDIOBOOK</CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">{count.audiobook}</span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                                                        <i className="bi bi-headphones" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Link>
                                </Card>
                            </Col>
                            <Col lg="6" xl="3">
                                <Card className="card-stats">
                                    <Link to="/bauddhik">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">TOTAL BAUDDHIK</CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">{count.podcast}</span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                                                        <i className="bi bi-speaker-fill" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Link>
                                </Card>
                            </Col>
                            <Col lg="6" xl="3">
                                <Card className="card-stats">
                                    <Link to="/song">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">TOTAL SONG</CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">{count.song}</span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                                                        <i className="bi bi-file-music-fill" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Link>
                                </Card>
                            </Col>
                            <Col lg="6" xl="3">
                                <Card className="card-stats">
                                    <Link to="/banner">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle tag="h5" className="text-uppercase text-muted mb-0">TOTAL BANNER</CardTitle>
                                                    <span className="h2 font-weight-bold mb-0">{count.banner}</span>
                                                </div>
                                                <Col className="col-auto">
                                                    <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                                                        <i className="bi bi-badge-ad-fill" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Link>
                                </Card>
                            </Col>
                            <Col lg="6" xl="3">
                                <Card className="card-stats">
                                    <CardBody>
                                        <Row>
                                            <div className="col">
                                                <CardTitle tag="h5" className="text-uppercase text-muted mb-0">LATEST APP VERISON</CardTitle>
                                                <span className="h2 font-weight-bold mb-0">1.3.0+8</span>
                                            </div>
                                            <Col className="col-auto">
                                                <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                                                    <i className="bi bi-pie-chart-fill" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default Dashboard;