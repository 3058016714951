import "./SectionAudiobook.scss";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    CardFooter,
    Label,
    Media,
    Spinner
} from "reactstrap";
import { useState, useEffect } from 'react';
import { Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import axios from '../../shared/hooks/use-customAxios';
import { success } from '../../shared/hooks/use-toastify';
import { useDispatch } from "react-redux";
import { uiActions } from "../../shared/store/ui-Slice";
import Select from 'react-select';
import AddAudiobookList from "./AddAudiobookList";
import { useSelector } from "react-redux";
import useSelect from '../../shared/hooks/use-select';
import { config } from '../../config/config';
import usePageTitle from '../../shared/hooks/use-pageTitle';

const AddSectionAudiobook: React.FC<any> = ({featchingData}) => {
    usePageTitle("Section For Audiobook");
    const audiobooksItem: any = useSelector((state: any) => state.ui.audiobooksItem);
    const bannerAudiobook: any = useSelector((state: any) => state.ui.bannerAudiobook);
    const [authorList, setAuthorList] = useState<any[]>([]);
    const [publisherList, setPublisherList] = useState<any[]>([]);
    const [audiobookModal, setAudiobookModal] = useState<boolean>(false);
    const [audioBookError, setAudioBookError] = useState<any>('');
    const [bannerAudioBookError, setBannerAudioBookError] = useState<any>('');
    const [img, setImg] = useState<any>('');
    const [isLoading, setIsLoading] = useState<any>(true);
    const [bannerImg, setBannerImg] = useState<any>('');
    const [isBanner, setIsBanner] = useState<boolean>(false);
    const [imgError, setImgError] = useState<any>('');
    const [error, setError] = useState<any>();
    const dispatch = useDispatch();
    let styles: any = useSelect();

    const sectionAudiobookSchema = yup.object().shape({
        type: yup.string().required('Type is required'),
        author: yup.array().when("type", {
            is: (type: string) => type === "author",
            then: yup.array().min(1).required('Author is required')
        }),
        publisher: yup.array().when("type", {
            is: (type: string) => type === "publisher",
            then: yup.array().min(1).required('Publisher is required')
        }),
        linkType: yup.string().when("type", {
            is: (type: string) => type === "banner",
            then: yup.string().required('Link Type is required')
        }),
        bannerAuthor: yup.object().when("linkType", {
            is: (linkType: string) => linkType === "author",
            then: yup.object().required('Author is required')
        }),
        bannerPublisher: yup.object().when("linkType", {
            is: (linkType: string) => linkType === "publisher",
            then: yup.object().required('Publisher is required')
        }),
        video: yup.string().when("linkType", {
            is: (linkType: string) => linkType === "video",
            then: yup.string().required('Video is required')
        }),
        url: yup.string().when("linkType", {
            is: (linkType: string) => linkType === "url",
            then: yup.string().required('Url is required')
        })
    });

    useEffect(() => {
        const selectData = async (): Promise<void> => {
            try {
                let res: any = await axios.post(`master/list`);
                if (res && res.status === 200) {
                    let authors: any[] = res?.data?.data?.authors;
                    if (Array.isArray(authors) && authors.length > 0) authors = authors.map((item: any) => ({ label: item?.name + "(" + item?.totalAudiobook + ")", value: item?._id, isDisabled: item?.totalAudiobook === 0 ? true : false }));
                    setAuthorList(authors);

                    let publishers: any[] = res?.data?.data?.publishers;
                    if (Array.isArray(publishers) && publishers.length > 0) publishers = publishers.map((item: any) => ({ label: item?.name + "(" + item?.totalAudiobook + ")", value: item?._id, isDisabled: item?.totalAudiobook === 0 ? true : false }));
                    setPublisherList(publishers);
                }
            } catch (error: any) { }
        };

        selectData();
    }, []);

    const uploadImgHandler = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        let files: FileList | null = e.target.files;

        if (!files) return;
        if (!files[0].name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
            setImgError("Please Select Valid File");
            return;
        }
        setBannerImg(files[0]);

        let fileData = new FormData();
        fileData.append("file", files[0]);

        try {
            let res: any = await axios.post(`section/uploadPhoto`, fileData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            if (res && res.status === 200) {
                setIsLoading(false);
                setImg(res.data.data.filename);
                setImgError("");
            }
        } catch (error: any) {
            if (error && error.response && error.response.status === 400) {
                (document.getElementById("image") as HTMLInputElement).value = "";
                setIsLoading(true);
                setBannerImg("");
                setImg("");
            }
        }
    };

    const removeImgHandler = async (): Promise<void> => {
        try {
            let res: any = await axios.post(`banner/removePhoto`, { filename: img });
            if (res && res.status === 200) {
                (document.getElementById("image") as HTMLInputElement).value = "";
                setIsLoading(true);
                setBannerImg("");
                setImg("");
            }
        } catch (error: any) { }
    };

    const audiobookHandler = (type: string): void => {
        setAudiobookModal(true);
        if (type === "audiobook") {
            setIsBanner(false);
        } else {
            setIsBanner(true);
        }
    };

    const submitHandler = (values: any) => {
        if ((values.type === "audiobook" && audiobooksItem.length === 0) ||
            (values.linkType === "audiobook" && !bannerAudiobook && bannerAudiobook === null) ||
            (values.type === "banner" && !img && img === "") ||
            (values.type === "banner" && isLoading && bannerImg !== "")) {
            if (values.type === "audiobook" && audiobooksItem.length === 0) {
                setAudioBookError("Audiobook is required");
            }
            if (values.linkType === "audiobook" && !bannerAudiobook && bannerAudiobook === null) {
                setBannerAudioBookError("Audiobook is required");
            }
            if (values.type === "banner" && !img && img === "") {
                setImgError("Image is required");
            }
            if (values.type === "banner" && isLoading && bannerImg !== "") return;
            return;
        }
    };

    const clearHandler = (): void => {
        dispatch(uiActions.removeBannerAudiobookHandler());
        dispatch(uiActions.updateAudiobookHandler([]));
        dispatch(uiActions.pageType(''));
    };

    return (
        <div className="form-section-audiobook">
            {audiobookModal && <AddAudiobookList audiobookModal={audiobookModal} setAudiobookModal={setAudiobookModal} setAudioBookError={setAudioBookError} isBanner={isBanner} setBannerAudioBookError={setBannerAudioBookError} />}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="8">
                        <Card>
                            <Formik
                                initialValues={{
                                    useFor: 'audiobook',
                                    title: '',
                                    type: '',
                                    author: '',
                                    publisher: '',
                                    linkType: '',
                                    bannerAuthor: '',
                                    bannerPublisher: '',
                                    video: '',
                                    url: '',
                                    status: ''
                                }}
                                validationSchema={sectionAudiobookSchema}
                                onSubmit={async (values: any, { resetForm }): Promise<void> => {
                                    try {
                                        if ((values.type === "audiobook" && audiobooksItem.length === 0) ||
                                            (values.linkType === "audiobook" && !bannerAudiobook && bannerAudiobook === null) ||
                                            (values.type === "banner" && !img && img === "") ||
                                            (values.type === "banner" && isLoading && bannerImg !== "")) {
                                            if (values.type === "audiobook" && audiobooksItem.length === 0) {
                                                setAudioBookError("Audiobook is required");
                                            }
                                            if (values.linkType === "audiobook" && !bannerAudiobook && bannerAudiobook === null) {
                                                setBannerAudioBookError("Audiobook is required");
                                            }
                                            if (values.type === "banner" && !img && img === "") {
                                                setImgError("Image is required");
                                            }
                                            if (values.type === "banner" && isLoading && bannerImg !== "") return;
                                            return;
                                        }

                                        let audioBooks: any = Array.isArray(audiobooksItem) && audiobooksItem.map((i: any) => i?._id);
                                        let authors: any = Array.isArray(values.author) && values.author.map((i: any) => i?.value);
                                        let publishers: any = Array.isArray(values.publisher) && values.publisher.map((i: any) => i?.value);
                                        let banner: any = {
                                            img: img,
                                            linkType: values.linkType,
                                        };

                                        if (values.linkType === "audiobook") banner.audioBook = bannerAudiobook?._id;
                                        if (values.linkType === "author") banner.author = values.bannerAuthor.value;
                                        if (values.linkType === "publisher") banner.publisher = values.bannerPublisher.value;
                                        if (values.linkType === "video") banner.video = values.video;
                                        if (values.linkType === "url") banner.url = values.url;

                                        let payload: any = {
                                            useFor: "audiobook",
                                            title: values.title,
                                            type: values.type,
                                            status: values.status === true ? 1 : 0
                                        };

                                        if (values.type === "audiobook") payload.audioBook = audioBooks;
                                        if (values.type === "author") payload.author = authors;
                                        if (values.type === "publisher") payload.publisher = publishers;
                                        if (values.type === "banner") payload.banner = banner;

                                        let res: any = await axios.post('section/add', payload);
                                        if (res && res.status === 200) {
                                            let payload: any = {
                                                moduleName: "section for audiobook",
                                                moduleLink: `${config.front_url}section/audiobook/view/${res?.data?.data?.section}`,
                                                activity: "insert",
                                                message: `The ${values.title} section for audiobook record has been inserted.`
                                            }
                                            await axios.post("history/add", payload);
                                            success('Section Created Successfully.');
                                            resetForm({ values: '' });
                                            setBannerImg("");
                                            setImg("");
                                            dispatch(uiActions.removeBannerAudiobookHandler());
                                            dispatch(uiActions.updateAudiobookHandler([]));
                                            featchingData();
                                            dispatch(uiActions.pageType(''));
                                        }
                                    } catch (error: any) {
                                        if (error.response) {
                                            setError(error.response.data.error);
                                        }
                                    }
                                }} >
                                {({ handleBlur, handleChange, values, setFieldValue, setFieldTouched }) => (
                                    <Form>
                                        <CardBody className="pb-2">
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-type">Type <span className="text-danger">*</span></label>
                                                            <Input
                                                                id="input-type"
                                                                name="type"
                                                                type="select"
                                                                placeholder="Select"
                                                                className="form-control-alternative"
                                                                value={values.type} onChange={handleChange} onBlur={handleBlur}
                                                            >
                                                                <option disabled value="">Select</option>
                                                                <option value="audiobook">Audiobook</option>
                                                                <option value="author">Author</option>
                                                                <option value="publisher">Publisher</option>
                                                                <option value="banner">Banner</option>
                                                                <option value="language">Language</option>
                                                            </Input>
                                                            {error ? <div className="error-message">{error?.type ? error?.type : ''}</div> : <ErrorMessage component="div" className="error-message" name="type" />}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-title">Title</label>
                                                            <Input className="form-control-alternative" name="title" id="input-title" placeholder="Title" type="text" onChange={handleChange} onBlur={handleBlur} value={values.title} />
                                                            <ErrorMessage component="div" className="error-message" name="title" />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup switch>
                                                            <Label check className="switch">Status</Label>
                                                            <Input type="switch" role="switch" name="status" checked={values.status} onChange={handleChange} onBlur={handleBlur} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                {(values.type !== '' && values.type !== 'language') && <><h6 className="heading-small text-muted mb-2">
                                                    {values.type} Details
                                                </h6>
                                                    <hr className="my-3" /></>}
                                                <Row>
                                                    {values.type === "author" && <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-author">Author <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="author"
                                                                options={authorList}
                                                                value={values.author}
                                                                onChange={(e) => { setFieldValue("author", e) }}
                                                                onBlur={(e: any) => { setFieldTouched("author", e) }}
                                                                styles={styles}
                                                                placeholder="Select"
                                                            />
                                                            {error ? <div className="error-message">{error?.author ? error?.author : ''}</div> : <ErrorMessage component="div" className="error-message" name="author" />}
                                                        </FormGroup>
                                                    </Col>}
                                                    {values.type === "publisher" && <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-speaker">Publisher <span className="text-danger">*</span></label>
                                                            <Select
                                                                isMulti
                                                                name="publisher"
                                                                options={publisherList}
                                                                value={values.publisher}
                                                                onChange={(e) => { setFieldValue("publisher", e) }}
                                                                onBlur={(e: any) => { setFieldTouched("publisher", e) }}
                                                                styles={styles}
                                                                placeholder="Select"
                                                            />
                                                            {error ? <div className="error-message">{error?.publisher ? error?.publisher : ''}</div> : <ErrorMessage component="div" className="error-message" name="publisher" />}
                                                        </FormGroup>
                                                    </Col>}
                                                    {values.type === "banner" && <Col lg="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="image">Image (700x90) <span className="text-danger">*</span></label>
                                                            <div className='file-card'>
                                                                <div className='file-inputs'>
                                                                    <Input className="form-control-alternative file-upload" disabled={bannerImg} id="image" type="file" multiple={false} onChange={uploadImgHandler} />
                                                                    <button className='file-btn' disabled={bannerImg}><i className="bi bi-cloud-arrow-up file-svg"></i>Upload</button>
                                                                </div>
                                                            </div>
                                                            {bannerImg && bannerImg !== '' && <ul className='file-list'>
                                                                <li className='list-item' >
                                                                    {isLoading ? <Spinner
                                                                        className="m-4"
                                                                    >
                                                                        Loading...
                                                                    </Spinner> :
                                                                        <>
                                                                            <img className="file-img" src={img} alt="img" />
                                                                            <div className='actions'>
                                                                                <i className="bi bi-x" onClick={removeImgHandler}></i>
                                                                            </div>
                                                                        </>}
                                                                </li>
                                                            </ul>}
                                                        </FormGroup>
                                                        {(imgError && imgError !== '') && <div className="error-img-message">{imgError}</div>}
                                                    </Col>}
                                                    {values.type === "banner" && <Col lg="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-type">Link Type <span className="text-danger">*</span></label>
                                                            <Input
                                                                id="input-type"
                                                                name="linkType"
                                                                type="select"
                                                                placeholder="Select"
                                                                className="form-control-alternative"
                                                                value={values.linkType} onChange={handleChange} onBlur={handleBlur}
                                                            >
                                                                <option disabled value="">Select</option>
                                                                <option value="none">None</option>
                                                                <option value="audiobook">Audiobook</option>
                                                                <option value="author">Author</option>
                                                                <option value="publisher">Publisher</option>
                                                                <option value="video">Video</option>
                                                                <option value="url">URL</option>

                                                            </Input>
                                                            {error ? <div className="error-message">{error?.banner_linkType ? error?.banner_linkType : ''}</div> : <ErrorMessage component="div" className="error-message" name="linkType" />}
                                                        </FormGroup>
                                                    </Col>}
                                                </Row>
                                                <Row>
                                                    {((values.type === "audiobook") || (values.linkType === "audiobook")) && <Col lg="12">
                                                        <FormGroup className="d-grid">
                                                            <label className="form-control-label" htmlFor="input-audiobook">Audiobook <span className="text-danger">*</span></label>
                                                            <Button className='primary-btn' onClick={() => audiobookHandler(values.type)}><i className="bi bi-plus"></i> Add</Button>
                                                        </FormGroup>
                                                        {(audioBookError && audioBookError !== '') ? <div className="error-img-message">{audioBookError}</div> : (bannerAudioBookError && bannerAudioBookError !== '') ? <div className="error-img-message">{bannerAudioBookError}</div> : ''}
                                                    </Col>}
                                                </Row>
                                                <Row>
                                                    {((values.type === "banner") && (values.linkType === "author")) && <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-author">Author <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="bannerAuthor"
                                                                options={authorList}
                                                                value={values.bannerAuthor}
                                                                onChange={(e) => { setFieldValue("bannerAuthor", e) }}
                                                                onBlur={(e: any) => { setFieldTouched("bannerAuthor", e) }}
                                                                styles={styles}
                                                                placeholder="Select"
                                                            />
                                                            {error ? <div className="error-message">{error?.banner_author ? error?.banner_author : ''}</div> : <ErrorMessage component="div" className="error-message" name="bannerAuthor" />}
                                                        </FormGroup>
                                                    </Col>}
                                                    {((values.type === "banner") && (values.linkType === "publisher")) && <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-publisher">Publisher <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="bannerPublisher"
                                                                options={publisherList}
                                                                value={values.bannerPublisher}
                                                                onChange={(e) => { setFieldValue("bannerPublisher", e) }}
                                                                onBlur={(e: any) => { setFieldTouched("bannerPublisher", e) }}
                                                                styles={styles}
                                                                placeholder="Select"
                                                            />
                                                            {error ? <div className="error-message">{error?.banner_publisher ? error?.banner_publisher : ''}</div> : <ErrorMessage component="div" className="error-message" name="bannerPublisher" />}
                                                        </FormGroup>
                                                    </Col>}
                                                    {((values.type === "banner") && (values.linkType === "video")) && <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-video">Video <span className="text-danger">*</span></label>
                                                            <Input className="form-control-alternative" name="video" id="input-video" placeholder="Video" type="text" onChange={handleChange} onBlur={handleBlur} value={values.video} />
                                                            {error ? <div className="error-message">{error?.banner_video ? error?.banner_video : ''}</div> : <ErrorMessage component="div" className="error-message" name="video" />}
                                                        </FormGroup>
                                                    </Col>}
                                                    {((values.type === "banner") && (values.linkType === "url")) && <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-url">Url <span className="text-danger">*</span></label>
                                                            <Input className="form-control-alternative" name="url" id="input-url" placeholder="URL" type="text" onChange={handleChange} onBlur={handleBlur} value={values.url} />
                                                            {error ? <div className="error-message">{error?.banner_url ? error?.banner_url : ''}</div> : <ErrorMessage component="div" className="error-message" name="url" />}
                                                        </FormGroup>
                                                    </Col>}
                                                </Row>
                                                <Row>
                                                    {((values.type === "audiobook" && audiobooksItem.length === 0) || (values.linkType === "audiobook" && bannerAudiobook === null)) && <h6 className="p-4 text-muted d-flex align-items-center justify-content-center"><i className="bi bi-x-circle text-danger"></i>&nbsp;No Audiobook Found</h6>}
                                                    {values.type === "audiobook" && Array.isArray(audiobooksItem) && audiobooksItem.map((item: any, index: number) => (
                                                        <Card className="file-list-card" key={index}>
                                                            <CardBody className="p-3">
                                                                <div className="file-first-section">
                                                                    <label className="form-control-label-first" htmlFor="input-file-name">{index + 1}</label>
                                                                    <Media className="align-items-center d-flex gap-2">
                                                                        <div className="avatar rounded-circle mr-3">
                                                                            <img alt="..." src={item?.img} />
                                                                        </div>
                                                                        <Media>
                                                                            <span className="mb-0">{item?.name}</span>
                                                                        </Media>
                                                                    </Media>
                                                                </div>
                                                                <i className="bi bi-x-circle-fill text-danger" onClick={() => dispatch(uiActions.removeAudiobookHandler(item?._id))}></i>
                                                            </CardBody>
                                                        </Card>
                                                    ))}
                                                    {((values.type === "banner") && (values.linkType === "audiobook") && (bannerAudiobook !== null)) &&
                                                        <Card className="file-list-card">
                                                            <CardBody className="p-3">
                                                                <div className="file-first-section">
                                                                    <Media className="align-items-center d-flex gap-2">
                                                                        <div className="avatar rounded-circle mr-3">
                                                                            <img alt="..." src={bannerAudiobook?.img} />
                                                                        </div>
                                                                        <Media>
                                                                            <span className="mb-0">{bannerAudiobook?.name}</span>
                                                                        </Media>
                                                                    </Media>
                                                                </div>
                                                                <i className="bi bi-x-circle-fill text-danger" onClick={() => dispatch(uiActions.removeBannerAudiobookHandler())}></i>
                                                            </CardBody>
                                                        </Card>}
                                                </Row>
                                            </div>
                                        </CardBody>
                                        <CardFooter className="bg-white border-0 pl-lg-4 ">
                                            <div className="form-button">
                                                <Button className="primary-btn" size="sm" type="submit" onClick={() => submitHandler(values)}>Submit</Button>
                                                <Button className="cancel-btn" size="sm" onClick={clearHandler}>Cancel</Button>
                                            </div>
                                        </CardFooter>
                                    </Form>
                                )}
                            </Formik>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default AddSectionAudiobook; 