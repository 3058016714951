import "./Tag.scss";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import axios from '../../shared/hooks/use-customAxios';
import { success } from "../../shared/hooks/use-toastify";
import usePageTitle from '../../shared/hooks/use-pageTitle';

const DeleteTag: React.FC<any> = ({ deletePage, setDeletePage, editData, featchingData }) => {
    usePageTitle("Tag");
    const deleteHandler = async (): Promise<void> => {
        try {
            let res: any = await axios.post('tag/delete', { id: editData?._id });
            if (res && res.status === 200) {
                let payload: any = {
                    moduleName: "tag",
                    activity: "delete",
                    message: `The ${editData?.name} tag record has been deleted.`
                }
                await axios.post("history/add", payload);
                success('Tag Deleted Successfully.');
                setDeletePage(false);
                featchingData();
            }
        } catch (error: any) { }
    };
    return (
        <div>
            <Modal isOpen={deletePage} centered={true}>
                <ModalBody>
                    Are you sure to delete the Tag?
                </ModalBody>
                <ModalFooter>
                    <Button className="primary-btn" size="sm" onClick={deleteHandler}>
                        Delete
                    </Button>
                    <Button className="cancel-btn" size="sm" onClick={() => setDeletePage(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DeleteTag;