import axios from "axios";
import { config } from '../../config/config';
import { warning } from "./use-toastify";

const useCustomAxios = axios.create({
    baseURL: config.api_url
});

const requestHandler = (req: any): any => {
    req.headers['x-auth-token'] = localStorage.getItem('token');
    return req;
};

const responseHandler = (res: any): any => {
    return res;
};

const errorHandler = (error: any): any => {
    if (error && error.response && error.response.status === 401) {
        localStorage.clear();
        document.location.href = '/';
    }
    if (error && error.response && error.response.status === 400) {
        if (error.response.data.message) {
            warning(error.response.data.message);
        }
    }
    return Promise.reject(error);
};

useCustomAxios.interceptors.request.use(
    (req: any) => requestHandler(req),
    (error: any) => errorHandler(error)
);

useCustomAxios.interceptors.response.use(
    (res: any) => responseHandler(res),
    (error: any) => errorHandler(error)
);

export default useCustomAxios;