import "./Notification.scss";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    CardFooter,
    Spinner
} from "reactstrap";
import usePageTitle from "../../shared/hooks/use-pageTitle";
import axios from '../../shared/hooks/use-customAxios';
import { Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { success } from "../../shared/hooks/use-toastify";
import { useState } from "react";

const Notification = () => {
    usePageTitle("Notification");
    const [img, setImg] = useState<any>('');
    const [notificationImg, setNotificationImg] = useState<any>('');
    const [imgError, setImgError] = useState<any>('');
    const [isLoading, setIsLoading] = useState<any>(true);
    const [error, setError] = useState<any>();

    const notificationSchema = yup.object().shape({
        title: yup.string().required('Title is required'),
        description: yup.string().required('Description is required')
    });

    const uploadImgHandler = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        let files: FileList | null = e.target.files;

        if (!files) return;
        if (!files[0].name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
            setImgError("Please Select Valid File");
            return;
        }
        setNotificationImg(files[0]);

        let fileData = new FormData();
        fileData.append("file", files[0]);

        try {
            let res: any = await axios.post(`notification/uploadPhoto`, fileData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            if (res && res.status === 200) {
                setIsLoading(false);
                setImg(res.data.data.filename);
                setImgError("");
            }
        } catch (error: any) {
            if (error && error.response && error.response.status === 400) {
                (document.getElementById("image") as HTMLInputElement).value = "";
                setIsLoading(true);
                setNotificationImg("");
                setImg('');
            }
        }
    };

    const removeImgHandler = async () => {
        try {
            let res: any = await axios.post(`notification/removePhoto`, { filename: img });
            if (res && res.status === 200) {
                (document.getElementById("image") as HTMLInputElement).value = "";
                setIsLoading(true);
                setNotificationImg("");
                setImg('');
            }
        } catch (error: any) { }
    };

    return (
        <div className="form-notification">
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="8">
                        <Card>
                            <Formik
                                initialValues={{ title: '', description: '' }}
                                validationSchema={notificationSchema}
                                onSubmit={async (values: any, { resetForm }): Promise<void> => {
                                    try {
                                        let payload: any = {
                                            title: values.title,
                                            description: values.description,
                                            img: img
                                        };
                                        let res: any = await axios.post('notification/sendNotification', payload);
                                        if (res && res.status === 200) {
                                            let payload: any = {
                                                moduleName: "notification",
                                                activity: "send notification",
                                                message: `The ${values.title} notification send all app user.`
                                            }
                                            await axios.post("history/add", payload);
                                            success('Notification Send Successfully.');
                                            setNotificationImg("");
                                            (document.getElementById("image") as HTMLInputElement).value = "";
                                            setImg('');
                                            resetForm({ values: '' });
                                        }
                                    } catch (error: any) {
                                        if (error.response) {
                                            setError(error.response.data.error);
                                        }
                                    }
                                }} >
                                {({ handleBlur, handleChange, values }) => (
                                    <Form>
                                        <CardBody className="pb-2">
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-title">Title <span className="text-danger">*</span></label>
                                                            <Input className="form-control-alternative" id="input-title" placeholder="Title" type="text" name="title" onChange={handleChange} onBlur={handleBlur} value={values.title} />
                                                            {error ? <div className="error-message">{error?.title ? error?.title : ''}</div> : <ErrorMessage component="div" className="error-message" name="title" />}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label>Description <span className="text-danger">*</span></label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                placeholder="Description"
                                                                name="description"
                                                                rows="4"
                                                                type="textarea"
                                                                onChange={handleChange} onBlur={handleBlur} value={values.description}
                                                            />
                                                            {error ? <div className="error-message">{error?.description ? error?.description : ''}</div> : <ErrorMessage component="div" className="error-message" name="description" />}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="image">Image</label>
                                                            <div className='file-card'>
                                                                <div className='file-inputs'>
                                                                    <Input className="form-control-alternative file-upload" disabled={notificationImg} id="image" type="file" multiple={false} onChange={uploadImgHandler} />
                                                                    <button className='file-btn' disabled={notificationImg}><i className="bi bi-cloud-arrow-up file-svg"></i>Upload</button>
                                                                </div>
                                                            </div>
                                                            {notificationImg && notificationImg !== '' && <ul className='file-list'>
                                                                <li className='list-item' >
                                                                    {isLoading ? <Spinner
                                                                        className="m-4"
                                                                    >
                                                                        Loading...
                                                                    </Spinner> :
                                                                        <>
                                                                            <img className="file-img" src={img} alt="img" />
                                                                            <div className='actions'>
                                                                                <i className="bi bi-x" onClick={removeImgHandler}></i>
                                                                            </div>
                                                                        </>}
                                                                </li>
                                                            </ul>}
                                                        </FormGroup>
                                                        {(imgError && imgError !== '') && <div className="error-img-message">{imgError}</div>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardBody>
                                        <CardFooter className="bg-white border-0 pl-lg-4 ">
                                            <div className="form-button">
                                                <Button color="primary" size="sm"><i className="bi bi-bell-fill"></i> Send Notification</Button>
                                            </div>
                                        </CardFooter>
                                    </Form>
                                )}
                            </Formik>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default Notification;