import "./NoticeBoard.scss";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    CardFooter,
    Label
} from "reactstrap";
import { useState, useEffect } from 'react';
import { Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import axios from '../../shared/hooks/use-customAxios';
import { success } from "../../shared/hooks/use-toastify";
import { config } from '../../config/config';
import usePageTitle from '../../shared/hooks/use-pageTitle';
import { useDispatch } from "react-redux";
import { uiActions } from "../../shared/store/ui-Slice";

const EditNoticeBoard: React.FC<any> = ({featchingData, id}) => {
    usePageTitle("Notice Board");
    const [editData, setEditData] = useState<any>([]);
    const [error, setError] = useState<any>();
    const dispatch = useDispatch();

    const languageSchema = yup.object().shape({
        title: yup.string().required('Title is required')
    });

    useEffect(() => {
        const view = async (): Promise<void> => {
            try {
                let res: any = await axios.post(`noticeBoard/view`, { id: id });
                if (res && res.status === 200) {
                    setEditData(res?.data?.data?.noticeBoard);
                }
            } catch (error: any) {}
        };

        view();
    }, []);

    return (
        <div className="form-language">
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="8">
                        <Card>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{ title: editData?.title, content: !!editData?.content ? editData?.content :  '', img: !!editData?.img ? editData?.img :  '', video: !!editData?.video ? editData?.video :  '', status: editData?.status === 1 ? true : false }}
                                validationSchema={languageSchema}
                                onSubmit={async (values: any, { resetForm }): Promise<void> => {
                                    try {
                                        let payload: any = {
                                            id: editData?._id,
                                            title: values.title,
                                            content: !!values.content ? values.content : null,
                                            img: !!values.img ? values.img : null,
                                            video: !!values.video ? values.video : null,
                                            status: values.status === true ? 1 : 0
                                        };
                                        let res: any = await axios.post('noticeBoard/update', payload);
                                        if (res && res.status === 200) {
                                            let payload: any = {
                                                moduleName: "noticeBoard",
                                                moduleLink: `${config.front_url}noticeBoard/view/${editData?._id}`,
                                                activity: "update",
                                                message: `The ${values.title} notice board record has been updated.`
                                            }
                                            await axios.post("history/add", payload);
                                            success('Notice Board Updated Successfully.');
                                            resetForm({ values: '' });
                                            dispatch(uiActions.pageType(''));
                                            featchingData();
                                        }
                                    } catch (error: any) {
                                        if (error.response) {
                                            setError(error.response.data.error);
                                        }
                                    }
                                }} >
                                {({ handleBlur, handleChange, values }) => (
                                    <Form>
                                        <CardBody className="pb-2">
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-name">Title <span className="text-danger">*</span></label>
                                                            <Input className="form-control-alternative" name="title" id="input-name" placeholder="Title" type="text" onChange={handleChange} onBlur={handleBlur} value={values.title} />
                                                            {error ? <div className="error-message">{error?.title ? error?.title : ''}</div> : <ErrorMessage component="div" className="error-message" name="title" />}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label>Content</label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                placeholder="Content"
                                                                name="content"
                                                                rows="4"
                                                                type="textarea"
                                                                value={values.content} onChange={handleChange} onBlur={handleBlur}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-img">Image</label>
                                                            <Input className="form-control-alternative" name="img" id="input-img" placeholder="Image" type="text" onChange={handleChange} onBlur={handleBlur} value={values.img} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-video">Video</label>
                                                            <Input className="form-control-alternative" name="video" id="input-video" placeholder="Video" type="text" onChange={handleChange} onBlur={handleBlur} value={values.video} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup switch>
                                                            <Label check className="switch">Status</Label>
                                                            <Input type="switch" role="switch" name="status" checked={values.status} onChange={handleChange} onBlur={handleBlur} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardBody>
                                        <CardFooter className="bg-white border-0 pl-lg-4 ">
                                            <div className="form-button">
                                                <Button className="primary-btn" size="sm" type="submit">Submit</Button>
                                                <Button className="cancel-btn" size="sm" onClick={() => dispatch(uiActions.pageType(''))}>Cancel</Button>
                                            </div>
                                        </CardFooter>
                                    </Form>
                                )}
                            </Formik>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default EditNoticeBoard;