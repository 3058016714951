import "./Language.scss";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    CardFooter,
    Label
} from "reactstrap";
import { useEffect, useState } from 'react';
import { Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import axios from '../../shared/hooks/use-customAxios';
import { success } from '../../shared/hooks/use-toastify';
import useSelect from '../../shared/hooks/use-select';
import CreatableSelect from 'react-select/creatable';
import { config } from '../../config/config';
import usePageTitle from '../../shared/hooks/use-pageTitle';
import { useDispatch } from "react-redux";
import { uiActions } from "../../shared/store/ui-Slice";

const AddLanguage: React.FC<any> = ({featchingData}) => {
    usePageTitle("Language");
    const [error, setError] = useState<any>();
    const [languageList, setLanguageList] = useState<any[]>([]);
    let styles: any = useSelect();
    const dispatch = useDispatch();

    const languageSchema = yup.object().shape({
        name: yup.object().required('Name is required')
    });

    useEffect(() => {
        const list = async () => {
            try {
                const res: any = await axios.post(`master/list`);
                if (res && res.status === 200) {
                    let languages: any[] = res?.data?.data?.languages;
                    if (Array.isArray(languages) && languages.length > 0) languages = languages.map((item: any) => ({ label: item?.name, value: item?.name }));
                    setLanguageList(languages);
                }

            } catch (error: any) { }
        };

        list();
    }, []);

    return (
        <div className="form-language">
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="8">
                        <Card>
                            <Formik
                                initialValues={{ name: '', status: '' }}
                                validationSchema={languageSchema}
                                onSubmit={async (values: any, { resetForm }): Promise<void> => {
                                    try {
                                        let payload: any = {
                                            name: values.name.value,
                                            status: values.status === true ? 1 : 0
                                        };
                                        let res: any = await axios.post('language/add', payload);
                                        if (res && res.status === 200) {
                                            let payload: any = {
                                                moduleName: "language",
                                                moduleLink: `${config.front_url}language/view/${res?.data?.data?.language}`,
                                                activity: "insert",
                                                message: `The ${values.name.value} language record has been inserted.`
                                            }
                                            await axios.post("history/add", payload);
                                            success('Language Created Successfully.');
                                            resetForm({ values: '' });
                                            featchingData();
                                            dispatch(uiActions.pageType(''));
                                        }
                                    } catch (error: any) {
                                        if (error.response) {
                                            setError(error.response.data.error);
                                        }
                                    }
                                }} >
                                {({ handleBlur, handleChange, values, setFieldValue, setFieldTouched }) => (
                                    <Form>
                                        <CardBody className="pb-2">
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-reader">Name <span className="text-danger">*</span></label>
                                                            <CreatableSelect
                                                                name="name"
                                                                onChange={(e) => { setFieldValue("name", e) }}
                                                                onBlur={(e: any) => { setFieldTouched("name", e) }}
                                                                value={values.name}
                                                                styles={styles}
                                                                placeholder="Name"
                                                                options={languageList}
                                                            />
                                                            {error ? <div className="error-message">{error?.name ? error?.name : ''}</div> : <ErrorMessage component="div" className="error-message" name="name" />}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup switch>
                                                            <Label check className="switch">Status</Label>
                                                            <Input type="switch" role="switch" name="status" checked={values.status} onChange={handleChange} onBlur={handleBlur} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardBody>
                                        <CardFooter className="bg-white border-0 pl-lg-4 ">
                                            <div className="form-button">
                                                <Button className="primary-btn" size="sm" type="submit">Submit</Button>
                                                <Button className="cancel-btn" size="sm" onClick={() => dispatch(uiActions.pageType(''))}>Cancel</Button>
                                            </div>
                                        </CardFooter>
                                    </Form>
                                )}
                            </Formik>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default AddLanguage;