import { NavLink } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import "./Sidebar.scss";
import { useDispatch, useSelector } from "react-redux";
import routes from "../../../routes";
import { uiActions } from "../../store/ui-Slice";
import { useEffect, useState } from "react";
import { sidebarRoute } from "../../hooks/user-role";

const Sidebar = () => {
    const [active, setActive] = useState<any>({
        Category: false,
        Section: false,
        Recommended: false,
        Search: false,
    });
    const isShow: boolean = useSelector(
        (state: any) => state.ui.sidebarIsVisible
    );
    const pageTitle: string = useSelector((state: any) => state.ui.pageTitle);
    const dispatch = useDispatch();

    const sidebarVisisbleHandler = (): void => {
        dispatch(uiActions.toggle());
    };

    const closeHandler = (): void => {
        if (isShow && window.screen.width <= 768) {
            sidebarVisisbleHandler();
        }
    };

    useEffect(() => {
        if (localStorage.getItem("user")) {
            let arr: any[] = sidebarRoute();
            console.log(arr);
        }

        setTimeout(() => {
            if (!isShow && window.screen.width > 768) {
                sidebarVisisbleHandler();
            } else if (isShow && window.screen.width <= 768) {
                sidebarVisisbleHandler();
            }
        }, 1000);
    }, []);

    const changeHandler = (name: string): void => {
        if (name === "Category") {
            setActive({ ...active, Category: !active[name] });
        } else if (name === "Section") {
            setActive({ ...active, Section: !active[name] });
        } else if (name === "Recommended") {
            setActive({ ...active, Recommended: !active[name] });
        } else {
            setActive({ ...active, Search: !active[name] });
        }
    };

    return (
        <>
            <div className={`${!isShow ? "hide" : ""} sidebar`}>
                <div className="logo">
                    <img src={logo} alt="logo" />
                </div>
                <ul className="scrollar-hide">
                    {Array.isArray(routes) &&
                        routes.map((item: any, index: number) => (
                            <li key={index}>
                                {Array.isArray(item.children) && item.children.length > 0 ? (
                                    <>
                                        <div
                                            className={
                                                active[`${item.name}`]
                                                    ? `sidebar-item drop-open ${item.title.includes(pageTitle)
                                                        ? "is-open-active"
                                                        : ""
                                                    }`
                                                    : `sidebar-item ${item.title.includes(pageTitle)
                                                        ? "is-close-active"
                                                        : ""
                                                    }`
                                            }
                                        >
                                            <div
                                                onClick={() => changeHandler(item.name)}
                                                className="sidebar-title main-color"
                                            >
                                                <i className={item.icon}></i>
                                                <span>{item.name}</span>
                                                <i className="bi bi-caret-down-fill"></i>
                                            </div>
                                            {Array.isArray(item.children) &&
                                                item.children.map((i: any, index: number) => (
                                                    <div className="sidebar-content" key={index}>
                                                        <NavLink
                                                            to={i.path}
                                                            className={({ isActive }) =>
                                                                isActive
                                                                    ? "sidebar-item active-child"
                                                                    : "sidebar-item"
                                                            }
                                                            onClick={() => {
                                                                closeHandler();
                                                                dispatch(uiActions.pageType(""));
                                                            }}
                                                        >
                                                            <div className="sidebar-title">
                                                                <span>
                                                                    <i className={i.icon}></i>
                                                                    {i.name}
                                                                </span>
                                                            </div>
                                                        </NavLink>
                                                    </div>
                                                ))}
                                        </div>
                                    </>
                                ) : (
                                    <NavLink
                                        to={item.path}
                                        className={({ isActive }) =>
                                            isActive ? "active" : "inactive"
                                        }
                                        onClick={() => {
                                            closeHandler();
                                            dispatch(uiActions.pageType(""));
                                        }}
                                    >
                                        <i className={item.icon}></i>
                                        <span>{item.name}</span>
                                    </NavLink>
                                )}
                            </li>
                        ))}
                </ul>
            </div>
            <div
                className={`${isShow ? "show" : ""} sidebar-overlay`}
                onClick={sidebarVisisbleHandler}
            ></div>
        </>
    );
};

export default Sidebar;
