import { Link, useLocation } from 'react-router-dom';
import './Footer.scss';

const Footer = () => {
    const location = useLocation();
    const year: number = new Date().getFullYear()
    return (
        <div className={`${location?.pathname == "/my-profile" ? "d-none" : ""} footer `}>© {year}
            <Link to="/">AudioKumbh</Link>
        </div>
    );
};

export default Footer;