import "./CategorySong.scss";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    CardFooter,
    Spinner
} from "reactstrap";
import { useState, useEffect } from 'react';
import { Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import axios from '../../shared/hooks/use-customAxios';
import { success } from "../../shared/hooks/use-toastify";
import useSelect from '../../shared/hooks/use-select';
import CreatableSelect from 'react-select/creatable';
import { config } from '../../config/config';
import { useNavigate, useParams } from "react-router-dom";
import usePageTitle from '../../shared/hooks/use-pageTitle';
import { useDispatch } from "react-redux";
import { uiActions } from "../../shared/store/ui-Slice";

const EditCategorySong: React.FC<any> = ({featchingData, id}) => {
    usePageTitle("Category For Song");
    const [img, setImg] = useState<any>("");
    const [imgList, setImgList] = useState<any>([]);
    const [editData, setEditData] = useState<any>([]);
    const [categoryList, setCategoryList] = useState<any[]>([]);
    const [categoryImg, setCategoryImg] = useState<any>('');
    const [imgError, setImgError] = useState<any>('');
    const [isLoading, setIsLoading] = useState<any>(true);
    const [error, setError] = useState<any>();
    let styles: any = useSelect();
    const dispatch = useDispatch();

    useEffect(() => {
        const view = async (): Promise<void> => {
            try {
                let res: any = await axios.post(`category/view`, { id: id });
                if (res && res.status === 200) {
                    setEditData(res?.data?.data?.category);
                }
            } catch (error: any) {}
        };

        const list = async () => {
            try {
                const res: any = await axios.post(`master/list`, { useFor: 'song' });
                if (res && res.status === 200) {
                    let categories: any[] = res?.data?.data?.categories;
                    if (Array.isArray(categories) && categories.length > 0) categories = categories.map((item: any) => ({ label: item?.name, value: item?.name }));
                    setCategoryList(categories);
                }

            } catch (error: any) { }
        };

        list();
        view();
    }, []);

    useEffect(() => {
        if (!!editData?.img) {
            setIsLoading(false);
            setCategoryImg(editData?.img);
            setImg(editData?.img);
        }
    }, [editData]);

    const categorySchema = yup.object().shape({
        name: yup.object().required('Name is required')
    });

    const uploadImgHandler = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        let files: FileList | null = e.target.files;

        if (!files) return;
        if (!files[0]) {
            setImgError("Image is required");
            return;
        }
        if (!files[0].name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
            setImgError("Please Select Valid File");
            return;
        }
        setCategoryImg(files[0]);

        let fileData = new FormData();
        fileData.append("file", files[0]);

        try {
            let res: any = await axios.post(`category/uploadPhoto`, fileData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            if (res && res.status === 200) {
                setIsLoading(false);
                setImg(res.data.data.filename);
                setImgError("");
            }
        } catch (error: any) {
            if (error && error.response && error.response.status === 400) {
                (document.getElementById("image") as HTMLInputElement).value = "";
                setIsLoading(true);
                setCategoryImg("");
                setImg(null);
            }
        }
    };

    const removeImgHandler = (): void => {
        setImgList([...imgList, img]);
        (document.getElementById("image") as HTMLInputElement).value = "";
        setIsLoading(true);
        setCategoryImg("");
        setImg(null);
    };

    const submitHandler = (): void => {
        if ((isLoading && categoryImg !== "") ||
            (!img && img === "")) {
            if (!img && img === "") {
                setImgError("Image is required");
            }
            if (isLoading && categoryImg !== "") return;
            return;
        }
    };

    return (
        <div className="form-category-song">
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="8">
                        <Card>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{ name: { label: editData?.name, value: editData?.name }, shareMessage: editData?.shareMessage === null ? '' : editData?.shareMessage }}
                                validationSchema={categorySchema}
                                onSubmit={async (values: any, { resetForm }): Promise<void> => {
                                    try {
                                        if ((isLoading && categoryImg !== "") ||
                                            (!img && img === "")) {
                                            if (!img && img === "") {
                                                setImgError("Image is required");
                                            }
                                            if (isLoading && categoryImg !== "") return;
                                            return;
                                        }
                                        let payload: any = {
                                            id: editData?._id,
                                            name: values.name.value,
                                            shareMessage: values.shareMessage === '' ? null : values.shareMessage,
                                            useFor: 'song',
                                            img: img
                                        };
                                        let res: any = await axios.post('category/update', payload);
                                        if (res && res.status === 200) {
                                            if (imgList.length > 0) {
                                                imgList.forEach((el: any) => {
                                                    axios.post(`category/removePhoto`, { filename: el }).then((result) => { }).catch((error: any) => { });
                                                });
                                            }
                                            let payload: any = {
                                                moduleName: "category for song",
                                                moduleLink: `${config.front_url}category/song/view/${editData?._id}`,
                                                activity: "update",
                                                message: `The ${values.name.value} category of song record has been song.`
                                            }
                                            await axios.post("history/add", payload);
                                            success('Category Updated Successfully.');
                                            resetForm({ values: '' });
                                            setCategoryImg("");
                                            setImg("");
                                            dispatch(uiActions.pageType(''));
                                            featchingData();
                                        }
                                    } catch (error: any) {
                                        if (error.response) {
                                            setError(error.response.data.error);
                                        }
                                    }
                                }} >
                                {({ handleBlur, handleChange, values, setFieldValue, setFieldTouched }) => (
                                    <Form>
                                        <CardBody className="pb-2">
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-reader">Name <span className="text-danger">*</span></label>
                                                            <CreatableSelect
                                                                name="name"
                                                                onChange={(e) => { setFieldValue("name", e) }}
                                                                onBlur={(e: any) => { setFieldTouched("name", e) }}
                                                                value={values.name}
                                                                styles={styles}
                                                                placeholder="Name"
                                                                options={categoryList}
                                                            />
                                                            {error ? <div className="error-message">{error?.name ? error?.name : ''}</div> : <ErrorMessage component="div" className="error-message" name="name" />}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label>Share Message</label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                placeholder="Share Message"
                                                                name="shareMessage"
                                                                rows="4"
                                                                type="textarea"
                                                                value={values.shareMessage} onChange={handleChange} onBlur={handleBlur}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="image">Image <span className="text-danger">*</span></label>
                                                            <div className='file-card'>
                                                                <div className='file-inputs'>
                                                                    <Input className="form-control-alternative file-upload" disabled={categoryImg} id="image" type="file" multiple={false} onChange={uploadImgHandler} />
                                                                    <button className='file-btn' disabled={categoryImg}><i className="bi bi-cloud-arrow-up file-svg"></i>Upload</button>
                                                                </div>
                                                            </div>
                                                            {categoryImg && categoryImg !== '' && <ul className='file-list'>
                                                                <li className='list-item' >
                                                                    {isLoading ? <Spinner
                                                                        className="m-4"
                                                                    >
                                                                        Loading...
                                                                    </Spinner> :
                                                                        <>
                                                                            <img className="file-img" src={img} alt="img" />
                                                                            <div className='actions'>
                                                                                <i className="bi bi-x" onClick={removeImgHandler}></i>
                                                                            </div>
                                                                        </>}
                                                                </li>
                                                            </ul>}
                                                        </FormGroup>
                                                        {(imgError && imgError !== '') && <div className="error-img-message">{imgError}</div>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardBody>
                                        <CardFooter className="bg-white border-0 pl-lg-4 ">
                                            <div className="form-button">
                                                <Button className="primary-btn" size="sm" type="submit" onClick={submitHandler}>Submit</Button>
                                                <Button className="cancel-btn" size="sm"  onClick={() => dispatch(uiActions.pageType(''))}>Cancel</Button>
                                            </div>
                                        </CardFooter>
                                    </Form>
                                )}
                            </Formik>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default EditCategorySong;