import './LoginAs.scss';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import axios from '../../shared/hooks/use-customAxios';
import { success } from '../../shared/hooks/use-toastify';
import { useDispatch } from 'react-redux';
import { authActions } from '../../shared/store/auth-Slice';
import { useState } from 'react';

const LoginAs: React.FC<any> = ({ setIsLogin }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [error, setError] = useState<any>('');
    const loginSchema = yup.object().shape({
        username: yup.string().email().required('Username is required'),
        password: yup.string().required('Password is required'),
    });

    return (
        <div className="login-as">
            <div className="login-as-form">
                <Formik
                    initialValues={{ username: '', password: '' }}
                    validationSchema={loginSchema}
                    onSubmit={async (values: any, { resetForm }): Promise<void> => {
                        try {
                            let payload: any = {
                                username: values.username,
                                password: values.password
                            };
                            let res: any = await axios.post('login/login', payload);
                            if (res && res.status === 200) {
                                dispatch(authActions.login());
                                setIsLogin(true);
                                if (res.headers['x-auth-token'] && res.headers['x-auth-token'] != null) {
                                    localStorage.setItem('token', res.headers['x-auth-token']);
                                }
                                resetForm({ values: '' });
                                success('Login Successfully.');
                                navigate('/');
                            }
                        } catch (error: any) {
                            if (error.response) {
                                setError(error.response.data.error);
                            }
                        }
                    }}
                >
                    {({ handleBlur, handleChange, values }) => (
                        <Form>
                            <h2 className="text-dark mb-3">Login</h2>
                            <div className="group">
                                <input type="text" placeholder="Username" name='username' value={values.username} onChange={handleChange} onBlur={handleBlur} />
                                <i className="bi bi-envelope-fill"></i>
                            </div>
                            {error ? <div className="error-message">{error?.username ? error?.username : ''}</div> : <ErrorMessage component="div" className="error-message" name="username" />}
                            <div className="group">
                                <input type="password" placeholder="Password" name='password' value={values.password} onChange={handleChange} onBlur={handleBlur} />
                                <i className="bi bi-shield-lock-fill"></i>
                            </div>
                            {error ? <div className="error-message">{error?.password ? error?.password : ''}</div> : <ErrorMessage component="div" className="error-message" name="password" />}
                            <button type='submit'>Login</button>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default LoginAs;