import "./Banner.scss";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    CardFooter,
    Label,
    Media,
    Spinner
} from "reactstrap";
import { useState, useEffect } from 'react';
import { Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import axios from '../../shared/hooks/use-customAxios';
import { success } from '../../shared/hooks/use-toastify';
import { useDispatch } from "react-redux";
import { uiActions } from "../../shared/store/ui-Slice";
import Select from 'react-select';
import AddAudiobookList from "./AddAudiobookList";
import { useSelector } from "react-redux";
import AddPodcastList from "./AddPodcastList";
import AddSongList from "./AddSongList";
import useSelect from '../../shared/hooks/use-select';
import { config } from '../../config/config';
import usePageTitle from '../../shared/hooks/use-pageTitle';

const AddBanner: React.FC<any> = ({featchingData}) => {
    usePageTitle("Banner");
    const bannerAudiobook: any = useSelector((state: any) => state.ui.bannerAudiobook);
    const bannerPodcast: any = useSelector((state: any) => state.ui.bannerPodcast);
    const bannerSong: any = useSelector((state: any) => state.ui.bannerSong);
    const [authorList, setAuthorList] = useState<any[]>([]);
    const [speakerList, setSpeakerList] = useState<any[]>([]);
    const [publisherList, setPublisherList] = useState<any[]>([]);
    const [audiobookModal, setAudiobookModal] = useState<boolean>(false);
    const [podcastModal, setPodcastModal] = useState<boolean>(false);
    const [songModal, setSongModal] = useState<boolean>(false);
    const [bannerAudioBookError, setBannerAudioBookError] = useState<any>('');
    const [bannerPodCastError, setBannerPodCastError] = useState<any>('');
    const [bannerSongError, setBannerSongError] = useState<any>('');
    const [img, setImg] = useState<any>('');
    const [isLoading, setIsLoading] = useState<any>(true);
    const [bannerImg, setBannerImg] = useState<any>('');
    const [imgError, setImgError] = useState<any>('');
    const [error, setError] = useState<any>();
    const dispatch = useDispatch();
    let styles: any = useSelect();

    const sectionSchema = yup.object().shape({
        useFor: yup.string().required('Use For is required'),
        linkType: yup.string().required('Link Type is required'),
        bannerAuthor: yup.object().when("linkType", {
            is: (linkType: string) => linkType === "author",
            then: yup.object().required('Author is required')
        }),
        bannerPublisher: yup.object().when("linkType", {
            is: (linkType: string) => linkType === "publisher",
            then: yup.object().required('Publisher is required')
        }),
        bannerSpeaker: yup.object().when("linkType", {
            is: (linkType: string) => linkType === "speaker",
            then: yup.object().required('Speaker is required')
        }),
        video: yup.string().when("linkType", {
            is: (linkType: string) => linkType === "video",
            then: yup.string().required('Video is required')
        }),
        url: yup.string().when("linkType", {
            is: (linkType: string) => linkType === "url",
            then: yup.string().required('Url is required')
        }),
        startScheduleTime: yup.date().required('Start Schedule Time is required'),
        endScheduleTime: yup.date().required('End Schedule Time is required')
    });

    useEffect(() => {
        const selectData = async (): Promise<void> => {
            try {
                let res: any = await axios.post(`master/list`);
                if (res && res.status === 200) {
                    let authors: any[] = res?.data?.data?.authors;
                    if (Array.isArray(authors) && authors.length > 0) authors = authors.map((item: any) => ({ label: item?.name, value: item?._id }));
                    setAuthorList(authors);

                    let publishers: any[] = res?.data?.data?.publishers;
                    if (Array.isArray(publishers) && publishers.length > 0) publishers = publishers.map((item: any) => ({ label: item?.name, value: item?._id }));
                    setPublisherList(publishers);

                    let speakers: any[] = res?.data?.data?.speakers;
                    if (Array.isArray(speakers) && speakers.length > 0) speakers = speakers.map((item: any) => ({ label: item?.name, value: item?._id }));
                    setSpeakerList(speakers);
                }
            } catch (error: any) { }
        };

        selectData();
    }, []);

    const uploadImgHandler = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        let files: FileList | null = e.target.files;

        if (!files) return;
        if (!files[0].name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
            setImgError("Please Select Valid File");
            return;
        }
        setBannerImg(files[0]);

        let fileData = new FormData();
        fileData.append("file", files[0]);

        try {
            let res: any = await axios.post(`banner/uploadPhoto`, fileData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            if (res && res.status === 200) {
                setIsLoading(false);
                setImg(res.data.data.filename);
                setImgError("");
            }
        } catch (error: any) {
            if (error && error.response && error.response.status === 400) {
                (document.getElementById("image") as HTMLInputElement).value = "";
                setIsLoading(true);
                setBannerImg("");
                setImg("");
            }
        }
    };

    const removeImgHandler = async (): Promise<void> => {
        try {
            let res: any = await axios.post(`banner/removePhoto`, { filename: img });
            if (res && res.status === 200) {
                (document.getElementById("image") as HTMLInputElement).value = "";
                setIsLoading(true);
                setBannerImg("");
                setImg("");
            }
        } catch (error: any) { }
    };

    const submitHandler = (linkType: string): void => {
        if ((linkType === "audiobook" && !bannerAudiobook && bannerAudiobook === null) ||
            (linkType === "podcast" && !bannerPodcast && bannerPodcast === null) ||
            (linkType === "song" && !bannerSong && bannerSong === null) ||
            (!img && img === "") ||
            (isLoading && bannerImg !== "")) {
            if (linkType === "audiobook" && !bannerAudiobook && bannerAudiobook === null) {
                setBannerAudioBookError("Audiobook is required");
            }
            if (linkType === "podcast" && !bannerPodcast && bannerPodcast === null) {
                setBannerPodCastError("Bauddhik is required");
            }
            if (linkType === "song" && !bannerSong && bannerSong === null) {
                setBannerSongError("Song is required");
            }
            if (!img && img === "") {
                setImgError("Image is required");
            }
            if (isLoading && bannerImg !== "") return;
            return;
        }
    };

    const clearHandler = (): void => {
        dispatch(uiActions.removeBannerAudiobookHandler());
        dispatch(uiActions.removeBannerPodcastHandler());
        dispatch(uiActions.removeBannerSongHandler());
        dispatch(uiActions.pageType(''));
    };

    return (
        <div className="form-banner">
            {audiobookModal && <AddAudiobookList audiobookModal={audiobookModal} setAudiobookModal={setAudiobookModal} setBannerAudioBookError={setBannerAudioBookError} />}
            {podcastModal && <AddPodcastList podcastModal={podcastModal} setPodcastModal={setPodcastModal} setBannerPodCastError={setBannerPodCastError} />}
            {songModal && <AddSongList songModal={songModal} setSongModal={setSongModal} setBannerSongError={setBannerSongError} />}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="8">
                        <Card>
                            <Formik
                                initialValues={{
                                    useFor: '',
                                    linkType: '',
                                    bannerAuthor: '',
                                    bannerPublisher: '',
                                    bannerSpeaker: '',
                                    video: '',
                                    url: '',
                                    status: '',
                                    startScheduleTime: '',
                                    endScheduleTime: ''
                                }}
                                validationSchema={sectionSchema}
                                onSubmit={async (values: any, { resetForm }): Promise<void> => {
                                    try {
                                        if ((values.linkType === "audiobook" && !bannerAudiobook && bannerAudiobook === null) ||
                                            (values.linkType === "podcast" && !bannerPodcast && bannerPodcast === null) ||
                                            (values.linkType === "song" && !bannerSong && bannerSong === null) ||
                                            (!img && img === "") ||
                                            (isLoading && bannerImg !== "")) {
                                            if (values.linkType === "audiobook" && !bannerAudiobook && bannerAudiobook === null) {
                                                setBannerAudioBookError("Audiobook is required");
                                            }
                                            if (values.linkType === "podcast" && !bannerPodcast && bannerPodcast === null) {
                                                setBannerPodCastError("Bauddhik is required");
                                            }
                                            if (values.linkType === "song" && !bannerSong && bannerSong === null) {
                                                setBannerSongError("Song is required");
                                            }
                                            if (!img && img === "") {
                                                setImgError("Image is required");
                                            }
                                            if (isLoading && bannerImg !== "") return;
                                            return;
                                        }
                                        let payload: any = {
                                            useFor: values.useFor,
                                            img: img,
                                            linkType: values.linkType,
                                            status: values.status === true ? 1 : 0,
                                            startScheduleTime: values.startScheduleTime,
                                            endScheduleTime: values.endScheduleTime
                                        };

                                        if (values.linkType === "audiobook") payload.audioBook = bannerAudiobook?._id;
                                        if (values.linkType === "podcast") payload.podCast = bannerPodcast?._id;
                                        if (values.linkType === "song") payload.song = bannerSong?._id;
                                        if (values.linkType === "author") payload.author = values.bannerAuthor.value;
                                        if (values.linkType === "publisher") payload.publisher = values.bannerPublisher.value;
                                        if (values.linkType === "speaker") payload.speaker = values.bannerSpeaker.value;
                                        if (values.linkType === "video") payload.video = values.video;
                                        if (values.linkType === "url") payload.url = values.url;

                                        let res: any = await axios.post('banner/add', payload);
                                        if (res && res.status === 200) {
                                            let payload: any = {
                                                moduleName: "banner",
                                                moduleLink: `${config.front_url}banner/view/${res?.data?.data?.banner}`,
                                                activity: "insert",
                                                message: `The ${values.useFor === "podcast" ? "buddhik" : values.useFor} of banner record has been inserted.`
                                            }
                                            await axios.post("history/add", payload);
                                            success('Banner Created Successfully.');
                                            resetForm({ values: '' });
                                            setBannerImg("");
                                            setImg("");
                                            setImgError("");
                                            dispatch(uiActions.removeBannerAudiobookHandler());
                                            dispatch(uiActions.removeBannerPodcastHandler());
                                            dispatch(uiActions.removeBannerSongHandler());
                                            featchingData();
                                            dispatch(uiActions.pageType(''));
                                        }
                                    } catch (error: any) {
                                        if (error.response) {
                                            setError(error.response.data.error);
                                        }
                                    }
                                }} >
                                {({ handleBlur, handleChange, values, setFieldValue, setFieldTouched }) => (
                                    <Form>
                                        <CardBody className="pb-2">
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-useFor">Use For <span className="text-danger">*</span></label>
                                                            <Input
                                                                id="input-useFor"
                                                                name="useFor"
                                                                type="select"
                                                                placeholder="Select"
                                                                className="form-control-alternative"
                                                                value={values.useFor} onChange={(e) => { handleChange(e); setFieldValue("linkType", "") }} onBlur={handleBlur}
                                                            >
                                                                <option disabled value="">Select</option>
                                                                <option value="audiobook">Audiobook</option>
                                                                <option value="podcast">Bauddhik</option>
                                                                <option value="song">Song</option>
                                                            </Input>
                                                            {error ? <div className="error-message">{error?.useFor ? error?.useFor : ''}</div> : <ErrorMessage component="div" className="error-message" name="useFor" />}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-type">Link Type <span className="text-danger">*</span></label>
                                                            <Input
                                                                id="input-type"
                                                                name="linkType"
                                                                type="select"
                                                                placeholder="Select"
                                                                className="form-control-alternative"
                                                                value={values.linkType} onChange={handleChange} onBlur={handleBlur}
                                                            >
                                                                <option value="">Select</option>
                                                                {values.useFor === "audiobook" && <>
                                                                    <option value="none">None</option>
                                                                    <option value="audiobook">Audiobook</option>
                                                                    <option value="author">Author</option>
                                                                    <option value="publisher">Publisher</option>
                                                                    <option value="video">Video</option>
                                                                    <option value="url">URL</option></>}
                                                                {values.useFor === "podcast" && <>
                                                                    <option value="none">None</option>
                                                                    <option value="podcast">Bauddhik</option>
                                                                    <option value="speaker">Speaker</option>
                                                                    <option value="video">Video</option>
                                                                    <option value="url">URL</option></>}
                                                                {values.useFor === "song" && <>
                                                                    <option value="none">None</option>
                                                                    <option value="song">Song</option>
                                                                    <option value="video">Video</option>
                                                                    <option value="url">URL</option></>}
                                                            </Input>
                                                            {error ? <div className="error-message">{error?.linkType ? error?.linkType : ''}</div> : <ErrorMessage component="div" className="error-message" name="linkType" />}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-date">Start Schedule Time <span className="text-danger">*</span></label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-date"
                                                                type="date"
                                                                name="startScheduleTime"
                                                                value={values.startScheduleTime}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            {error ? <div className="error-message">{error?.startScheduleTime ? error?.startScheduleTime : ''}</div> : <ErrorMessage component="div" className="error-message" name="startScheduleTime" />}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-date">End Schedule Time <span className="text-danger">*</span></label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                id="input-date"
                                                                type="date"
                                                                name="endScheduleTime"
                                                                value={values.endScheduleTime}
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                            />
                                                            {error ? <div className="error-message">{error?.endScheduleTime ? error?.endScheduleTime : ''}</div> : <ErrorMessage component="div" className="error-message" name="endScheduleTime" />}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="image">Image (700x400) <span className="text-danger">*</span></label>
                                                            <div className='file-card'>
                                                                <div className='file-inputs'>
                                                                    <Input className="form-control-alternative file-upload" disabled={bannerImg} id="image" type="file" multiple={false} onChange={uploadImgHandler} />
                                                                    <button className='file-btn' disabled={bannerImg}><i className="bi bi-cloud-arrow-up file-svg"></i>Upload</button>
                                                                </div>
                                                            </div>
                                                            {bannerImg && bannerImg !== '' && <ul className='file-list'>
                                                                <li className='list-item' >
                                                                    {isLoading ? <Spinner
                                                                        className="m-4"
                                                                    >
                                                                        Loading...
                                                                    </Spinner> :
                                                                        <>
                                                                            <img className="file-img" src={img} alt="img" />
                                                                            <div className='actions'>
                                                                                <i className="bi bi-x" onClick={removeImgHandler}></i>
                                                                            </div>
                                                                        </>}
                                                                </li>
                                                            </ul>}
                                                        </FormGroup>
                                                        {(imgError && imgError !== '') && <div className="error-img-message">{imgError}</div>}
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup switch>
                                                            <Label check className="switch">Status</Label>
                                                            <Input type="switch" role="switch" name="status" checked={values.status} onChange={handleChange} onBlur={handleBlur} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                {(values.linkType !== '' && values.linkType !== "none") && <><h6 className="heading-small text-muted mb-2">
                                                    {values.linkType === "podcast" ? "Bauddhik" : values.linkType} Details
                                                </h6>
                                                    <hr className="my-3" /></>}
                                                <Row>
                                                    {(values.useFor === "audiobook" && (values.linkType === "audiobook")) && <Col lg="12">
                                                        <FormGroup className="d-grid">
                                                            <label className="form-control-label" htmlFor="input-audiobook">Audiobook <span className="text-danger">*</span></label>
                                                            <Button className='primary-btn' onClick={() => setAudiobookModal(true)}><i className="bi bi-plus"></i> Add</Button>
                                                        </FormGroup>
                                                        {(bannerAudioBookError && bannerAudioBookError !== '') && <div className="error-img-message">{bannerAudioBookError}</div>}
                                                    </Col>}
                                                    {(values.useFor === "podcast" && (values.linkType === "podcast")) && <Col lg="12">
                                                        <FormGroup className="d-grid">
                                                            <label className="form-control-label" htmlFor="input-audiobook">Bauddhik <span className="text-danger">*</span></label>
                                                            <Button className='primary-btn' onClick={() => setPodcastModal(true)}><i className="bi bi-plus"></i> Add</Button>
                                                        </FormGroup>
                                                        {(bannerPodCastError && bannerPodCastError !== '') && <div className="error-img-message">{bannerPodCastError}</div>}
                                                    </Col>}
                                                    {(values.useFor === "song" && (values.linkType === "song")) && <Col lg="12">
                                                        <FormGroup className="d-grid">
                                                            <label className="form-control-label" htmlFor="input-audiobook">Song <span className="text-danger">*</span></label>
                                                            <Button className='primary-btn' onClick={() => setSongModal(true)}><i className="bi bi-plus"></i> Add</Button>
                                                        </FormGroup>
                                                        {(bannerSongError && bannerSongError !== '') && <div className="error-img-message">{bannerSongError}</div>}
                                                    </Col>}
                                                </Row>
                                                <Row>
                                                    {(values.useFor === "audiobook" && values.linkType === "author") && <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-author">Author <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="bannerAuthor"
                                                                options={authorList}
                                                                value={values.bannerAuthor}
                                                                onChange={(e) => { setFieldValue("bannerAuthor", e) }}
                                                                onBlur={(e: any) => { setFieldTouched("bannerAuthor", e) }}
                                                                styles={styles}
                                                                placeholder="Select"
                                                            />
                                                            {error ? <div className="error-message">{error?.author ? error?.author : ''}</div> : <ErrorMessage component="div" className="error-message" name="bannerAuthor" />}
                                                        </FormGroup>
                                                    </Col>}
                                                    {(values.useFor === "audiobook" && values.linkType === "publisher") && <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-publisher">Publisher <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="bannerPublisher"
                                                                options={publisherList}
                                                                value={values.bannerPublisher}
                                                                onChange={(e) => { setFieldValue("bannerPublisher", e) }}
                                                                onBlur={(e: any) => { setFieldTouched("bannerPublisher", e) }}
                                                                styles={styles}
                                                                placeholder="Select"
                                                            />
                                                            {error ? <div className="error-message">{error?.publisher ? error?.publisher : ''}</div> : <ErrorMessage component="div" className="error-message" name="bannerPublisher" />}
                                                        </FormGroup>
                                                    </Col>}
                                                    {(values.useFor === "podcast" && values.linkType === "speaker") && <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-speaker">Speaker <span className="text-danger">*</span></label>
                                                            <Select
                                                                name="bannerSpeaker"
                                                                options={speakerList}
                                                                value={values.bannerSpeaker}
                                                                onChange={(e) => { setFieldValue("bannerSpeaker", e) }}
                                                                onBlur={(e: any) => { setFieldTouched("bannerSpeaker", e) }}
                                                                styles={styles}
                                                                placeholder="Select"
                                                            />
                                                            {error ? <div className="error-message">{error?.peaker ? error?.speaker : ''}</div> : <ErrorMessage component="div" className="error-message" name="bannerSpeaker" />}
                                                        </FormGroup>
                                                    </Col>}
                                                    {values.linkType === "video" && <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-video">Video <span className="text-danger">*</span></label>
                                                            <Input className="form-control-alternative" name="video" id="input-video" placeholder="Video" type="text" onChange={handleChange} onBlur={handleBlur} value={values.video} />
                                                            {error ? <div className="error-message">{error?.video ? error?.video : ''}</div> : <ErrorMessage component="div" className="error-message" name="video" />}
                                                        </FormGroup>
                                                    </Col>}
                                                    {values.linkType === "url" && <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-url">Url <span className="text-danger">*</span></label>
                                                            <Input className="form-control-alternative" name="url" id="input-url" placeholder="URL" type="text" onChange={handleChange} onBlur={handleBlur} value={values.url} />
                                                            {error ? <div className="error-message">{error?.url ? error?.url : ''}</div> : <ErrorMessage component="div" className="error-message" name="url" />}
                                                        </FormGroup>
                                                    </Col>}
                                                </Row>
                                                <Row>
                                                    {(values.linkType === "audiobook" && bannerAudiobook === null) && <h6 className="p-4 text-muted d-flex align-items-center justify-content-center"><i className="bi bi-x-circle text-danger"></i>&nbsp;No Audiobook Found</h6>}
                                                    {(values.linkType === "podcast" && bannerPodcast === null) && <h6 className="p-4 text-muted d-flex align-items-center justify-content-center"><i className="bi bi-x-circle text-danger"></i>&nbsp;No Bauddhik Found</h6>}
                                                    {(values.linkType === "song" && bannerSong === null) && <h6 className="p-4 text-muted d-flex align-items-center justify-content-center"><i className="bi bi-x-circle text-danger"></i>&nbsp;No Song Found</h6>}
                                                    {(values.linkType === "audiobook" && bannerAudiobook !== null) &&
                                                        <Card className="file-list-card">
                                                            <CardBody className="p-3">
                                                                <div className="file-first-section">
                                                                    <Media className="align-items-center d-flex gap-2">
                                                                        <div className="avatar rounded-circle mr-3">
                                                                            <img alt="..." src={bannerAudiobook?.img} />
                                                                        </div>
                                                                        <Media>
                                                                            <span className="mb-0">{bannerAudiobook?.name}</span>
                                                                        </Media>
                                                                    </Media>
                                                                </div>
                                                                <i className="bi bi-x-circle-fill text-danger" onClick={() => dispatch(uiActions.removeBannerAudiobookHandler())}></i>
                                                            </CardBody>
                                                        </Card>}
                                                    {(values.linkType === "podcast" && bannerPodcast !== null) &&
                                                        <Card className="file-list-card">
                                                            <CardBody className="p-3">
                                                                <div className="file-first-section">
                                                                    <Media className="align-items-center d-flex gap-2">
                                                                        <div className="avatar rounded-circle mr-3">
                                                                            <img alt="..." src={bannerPodcast?.img} />
                                                                        </div>
                                                                        <Media>
                                                                            <span className="mb-0">{bannerPodcast?.name}</span>
                                                                        </Media>
                                                                    </Media>
                                                                </div>
                                                                <i className="bi bi-x-circle-fill text-danger" onClick={() => dispatch(uiActions.removeBannerPodcastHandler())}></i>
                                                            </CardBody>
                                                        </Card>}
                                                    {(values.linkType === "song" && bannerSong !== null) &&
                                                        <Card className="file-list-card">
                                                            <CardBody className="p-3">
                                                                <div className="file-first-section">
                                                                    <Media className="align-items-center d-flex gap-2">
                                                                        <div className="avatar rounded-circle mr-3">
                                                                            <img alt="..." src={bannerSong?.img} />
                                                                        </div>
                                                                        <Media>
                                                                            <span className="mb-0">{bannerSong?.name}</span>
                                                                        </Media>
                                                                    </Media>
                                                                </div>
                                                                <i className="bi bi-x-circle-fill text-danger" onClick={() => dispatch(uiActions.removeBannerSongHandler())}></i>
                                                            </CardBody>
                                                        </Card>}
                                                </Row>
                                            </div>
                                        </CardBody>
                                        <CardFooter className="bg-white border-0 pl-lg-4 ">
                                            <div className="form-button">
                                                <Button className="primary-btn" size="sm" type="submit" onClick={() => submitHandler(values.linkType)}>Submit</Button>
                                                <Button className="cancel-btn" size="sm" onClick={clearHandler}>Cancel</Button>
                                            </div>
                                        </CardFooter>
                                    </Form>
                                )}
                            </Formik>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default AddBanner; 