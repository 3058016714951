import "./Tag.scss";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    CardFooter,
} from "reactstrap";
import { useState, useEffect } from 'react';
import { Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import axios from '../../shared/hooks/use-customAxios';
import { success } from "../../shared/hooks/use-toastify";
import useSelect from '../../shared/hooks/use-select';
import CreatableSelect from 'react-select/creatable';
import { config } from '../../config/config';
import usePageTitle from '../../shared/hooks/use-pageTitle';
import { useDispatch } from "react-redux";
import { uiActions } from "../../shared/store/ui-Slice";

const EditTag: React.FC<any> = ({featchingData, id}) => {
    usePageTitle("Tag");
    const [error, setError] = useState<any>();
    const [editData, setEditData] = useState<any>([]);
    const [tagList, setTagList] = useState<any[]>([]);
    let styles: any = useSelect();
    const dispatch = useDispatch();

    const tagSchema = yup.object().shape({
        name: yup.object().required('Name is required')
    });

    useEffect(() => {
        const view = async (): Promise<void> => {
            try {
                let res: any = await axios.post(`tag/view`, { id: id });
                if (res && res.status === 200) {
                    setEditData(res?.data?.data?.tag);
                }
            } catch (error: any) {}
        };

        const list = async () => {
            try {
                const res: any = await axios.post(`master/list`);
                if (res && res.status === 200) {
                    let tags: any[] = res?.data?.data?.tags;
                    if (Array.isArray(tags) && tags.length > 0) tags = tags.map((item: any) => ({ label: item?.name, value: item?.name }));
                    setTagList(tags);
                }

            } catch (error: any) { }
        };

        list();
        view();
    }, []);

    return (
        <div className="form-tag">
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="8">
                        <Card>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{ name: { label: editData?.name, value: editData?.name }, color: editData?.color }}
                                validationSchema={tagSchema}
                                onSubmit={async (values: any, { resetForm }): Promise<void> => {
                                    try {
                                        let payload: any = {
                                            id: editData?._id,
                                            name: values.name.value,
                                            color: values.color
                                        };
                                        let res: any = await axios.post('tag/update', payload);
                                        if (res && res.status === 200) {
                                            let payload: any = {
                                                moduleName: "tag",
                                                moduleLink: `${config.front_url}tag/view/${editData?._id}`,
                                                activity: "update",
                                                message: `The ${values.name.value} tag record has been updated.`
                                            }
                                            await axios.post("history/add", payload);
                                            success('Tag Updated Successfully.');
                                            resetForm({ values: '' });
                                            dispatch(uiActions.pageType(''));
                                            featchingData();
                                        }
                                    } catch (error: any) {
                                        if (error.response) {
                                            setError(error.response.data.error);
                                        }
                                    }
                                }} >
                                {({ handleBlur, handleChange, values, setFieldValue, setFieldTouched }) => (
                                    <Form>
                                        <CardBody className="pb-2">
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-reader">Name <span className="text-danger">*</span></label>
                                                            <CreatableSelect
                                                                name="name"
                                                                onChange={(e) => { setFieldValue("name", e) }}
                                                                onBlur={(e: any) => { setFieldTouched("name", e) }}
                                                                value={values.name}
                                                                styles={styles}
                                                                placeholder="Name"
                                                                options={tagList}
                                                            />
                                                            {error ? <div className="error-message">{error?.name ? error?.name : ''}</div> : <ErrorMessage component="div" className="error-message" name="name" />}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-color">Color</label>
                                                            <Input className="form-control-alternative form-color" pattern="#[a-f0-9]{6}" name="color" id="input-color" placeholder="Color" type="color" onChange={handleChange} onBlur={handleBlur} value={values.color} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardBody>
                                        <CardFooter className="bg-white border-0 pl-lg-4 ">
                                            <div className="form-button">
                                                <Button className="primary-btn" size="sm" type="submit">Submit</Button>
                                                <Button className="cancel-btn" size="sm" onClick={() => dispatch(uiActions.pageType(''))}>Cancel</Button>
                                            </div>
                                        </CardFooter>
                                    </Form>
                                )}
                            </Formik>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default EditTag;