import "./Audiobook.scss";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    CardFooter,
    Label,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Spinner,
    Progress
} from "reactstrap";
import { Formik, Form, ErrorMessage } from 'formik';
import * as yup from 'yup';
import axios from '../../shared/hooks/use-customAxios';
import { useEffect, useState } from "react";
import AddFile from "./AddFile";
import EditFile from "./EditFile";
import DeleteFile from "./DeleteFile";
import { success } from "../../shared/hooks/use-toastify";
import useSelect from '../../shared/hooks/use-select';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { config } from '../../config/config';
import { useNavigate } from "react-router-dom";
import usePageTitle from '../../shared/hooks/use-pageTitle';
import { useDispatch } from "react-redux";
import { uiActions } from "../../shared/store/ui-Slice";

const AddAudiobook: React.FC<any> = ({featchingData}) => {
    usePageTitle("Audiobook");
    const [id, setId] = useState<any>('');
    const [name, setName] = useState<any>('');
    const [length, setLength] = useState<number>();
    const [publisherList, setPublisherList] = useState<any[]>([]);
    const [authorList, setAuthorList] = useState<any[]>([]);
    const [sponsorList, setSponsorList] = useState<any[]>([]);
    const [categoryList, setCategoryList] = useState<any[]>([]);
    const [languageList, setLanguageList] = useState<any[]>([]);
    const [audiobookList, setAudiobookList] = useState<any[]>([]);
    const [playList, setPlayList] = useState<any[]>([]);
    const [audioBookOpen, setAudioBookOpen] = useState<boolean>(true);
    const [playListOpen, setPlayListOpen] = useState<boolean>(false);
    const [tagList, setTagList] = useState<any[]>([]);
    const [img, setImg] = useState<any>("");
    const [pdf, setPdf] = useState<any>("");
    const [imgLoader, setImgLoader] = useState<any>(true);
    const [audioImg, setAudioImg] = useState<any>("");
    const [audioImgError, setAudioImgError] = useState<any>('');
    const [audioPdf, setAudioPdf] = useState<any>("");
    const [uploaded, setUploaded] = useState<any>(null);
    const [audioPdfError, setAudioPdfError] = useState<any>('');
    const [filePage, setFilePage] = useState<boolean>(false);
    const [editFilePage, setEditFilePage] = useState<boolean>(false);
    const [deleteFilePage, setDeleteFilePage] = useState<boolean>(false);
    const [editFile, setEditFile] = useState<any>();
    const [deleteFile, setDeleteFile] = useState<any>();
    const [error, setError] = useState<any>();
    let styles: any = useSelect();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const audioBookSchema = yup.object().shape({
        name: yup.object().required('Name is required'),
        category: yup.array().min(1).required('Category is required'),
        language: yup.string().required('Language is required')
    });

    useEffect(() => {
        const selectData = async (): Promise<void> => {
            try {
                let res: any = await axios.post(`master/list`, { useFor: "audiobook" });
                if (res && res.status === 200) {
                    let publishers: any[] = res?.data?.data?.publishers;
                    if (Array.isArray(publishers) && publishers.length > 0) publishers = publishers.map((item: any) => ({ label: item?.name, value: item?._id }));
                    setPublisherList(publishers);

                    let authors: any[] = res?.data?.data?.authors;
                    if (Array.isArray(authors) && authors.length > 0) authors = authors.map((item: any) => ({ label: item?.name, value: item?._id }));
                    setAuthorList(authors);

                    let sponsors: any[] = res?.data?.data?.sponsors;
                    if (Array.isArray(sponsors) && sponsors.length > 0) sponsors = sponsors.map((item: any) => ({ label: item?.name, value: item?._id }));
                    setSponsorList(sponsors);

                    let categories: any[] = res?.data?.data?.categories;
                    if (Array.isArray(categories) && categories.length > 0) categories = categories.map((item: any) => ({ label: item?.name, value: item?._id }));
                    setCategoryList(categories);
                    setLanguageList(res?.data?.data?.languages);
                    setTagList(res?.data?.data?.tags);

                    let audiobooks: any[] = res?.data?.data?.audiobooks;
                    if (Array.isArray(audiobooks) && audiobooks.length > 0) audiobooks = audiobooks.map((item: any) => ({ label: item?.name, value: item?.name }));
                    setAudiobookList(audiobooks);
                }
            } catch (error: any) { }
        };

        selectData();
    }, []);

    useEffect(() => {
        if (playListOpen) {
            const playFileList = async (): Promise<void> => {
                try {
                    let res: any = await axios.post(`audiobook/view`, { id: id });
                    if (res && res.status === 200) {
                        setPlayList(res?.data?.data?.audiobook?.playList);
                        setLength(res?.data?.data?.audiobook?.playList.length);
                    }
                } catch (error: any) { }
            };

            playFileList();
        }
    }, [filePage, editFilePage, deleteFilePage]);

    const audioBookHandler = (): void => {
        setPlayListOpen(false);
        setAudioBookOpen(true);
    };

    const playListHandler = (): void => {
        if (!id && id !== '') {
            setPlayListOpen(true);
            setAudioBookOpen(false);
        }
    };

    const uploadImgHandler = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        let files: FileList | null = e.target.files;

        if (!files) return;
        if (!files[0]) {
            setAudioImgError("Image is required");
            return;
        } else if (!files[0].name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
            setAudioImgError("Please Select Valid File");
            return;
        }
        setAudioImg(files[0]);

        let fileData = new FormData();
        fileData.append("file", files[0]);

        try {
            let res: any = await axios.post(`audiobook/uploadPhoto`, fileData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            if (res && res.status === 200) {
                setImgLoader(false);
                setImg(res.data.data.filename);
                setAudioImgError("");
            }
        } catch (error: any) {
            if (error && error.response && error.response.status === 400) {
                (document.getElementById("image") as HTMLInputElement).value = "";
                setImgLoader(true);
                setAudioImg("");
                setImg("");
            }
        }
    };

    const removeImgHandler = async (): Promise<void> => {
        try {
            let res: any = await axios.post(`audiobook/removePhoto`, { filename: img });
            if (res && res.status === 200) {
                (document.getElementById("image") as HTMLInputElement).value = "";
                setImgLoader(true);
                setAudioImg("");
                setImg("");
            }
        } catch (error: any) { }
    };

    const uploadPdfHandler = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        let files: FileList | null = e.target.files;

        if (!files) return;
        if (!files[0].name.match(/\.(pdf|PDF)$/)) {
            setAudioPdfError("Please Select Valid File");
            return;
        }
        setAudioPdf(files[0]);

        let fileData = new FormData();
        fileData.append("file", files[0]);

        const options = {
            onUploadProgress: (data: any) => {
                let percent: number = Math.floor((data.loaded / data.total) * 100);
                if (percent < 100) {
                    setUploaded(percent);
                }
            }
        }

        try {
            let res: any = await axios.post(`audiobook/uploadPDF`, fileData, options);
            if (res && res.status === 200) {
                setUploaded(100);
                setPdf(res.data.data.filename);
                setAudioPdfError("");
            }
        } catch (error: any) {
            if (error && error.response && error.response.status === 400) {
                (document.getElementById("pdf") as HTMLInputElement).value = "";
                setUploaded(null);
                setAudioPdf("");
                setPdf("");
            }
        }
    };

    const removePdfHandler = async (): Promise<void> => {
        try {
            let res: any = await axios.post(`audiobook/removePDF`, { filename: pdf });
            if (res && res.status === 200) {
                (document.getElementById("pdf") as HTMLInputElement).value = "";
                setUploaded(null);
                setAudioPdf("");
                setPdf("");
            }
        } catch (error: any) { }
    };

    const submitHandler = async (): Promise<void> => {
        if ((imgLoader && audioImg !== "") || (!img && img === "") || (uploaded !== null && uploaded >= 0 && uploaded < 100)) {
            if (!img && img === "") {
                setAudioImgError("Image is required");
            }
            if (uploaded !== null && uploaded >= 0 && uploaded < 100) return;
            if (imgLoader && audioImg !== "") return;
            return;
        }
    };

    const handleDrop = async (droppedItem: any): Promise<void> => {
        try {
            if (!droppedItem.destination) return;
            let updatedList = [...playList];
            const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
            updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
            updatedList = updatedList.map((item: any, index: number) => ({
                ...item,
                position: index + 1
            }));
            await axios.post('audiobook/updatePlayList', { id: id, playList: updatedList });
            let payload: any = {
                moduleName: "audiobook",
                activity: "update",
                message: `The audiobook play file position has been updated.`
            }
            await axios.post("history/add", payload);
            setPlayList(updatedList);
        } catch (error: any) { }
    };

    return (
        <div className="form-audiobook">
            {filePage && <AddFile id={id} name={name} length={length} filePage={filePage} setFilePage={setFilePage} />}
            {editFilePage && <EditFile id={id} name={name} editFile={editFile} editFilePage={editFilePage} setEditFilePage={setEditFilePage} />}
            {deleteFilePage && <DeleteFile id={id} name={name} deleteFile={deleteFile} deleteFilePage={deleteFilePage} setDeleteFilePage={setDeleteFilePage} />}
            <Container className="mt--7" fluid>
                <div className="card-title">
                    <Card onClick={audioBookHandler}>
                        <div>
                            <h3 className={`mb-0 form-title ${(audioBookOpen && !playListOpen) ? 'active' : ''}`}>AudioBook Detail</h3>
                        </div>
                    </Card>
                    <Card onClick={playListHandler}>
                        <div>
                            <h3 className={`mb-0 form-title ${(!audioBookOpen && playListOpen) ? 'active' : ''}`}>Play List</h3>
                        </div>
                    </Card>
                </div>

                <Row>
                    <Col className="order-xl-1" xl="8">
                        <Card>
                            <Formik
                                initialValues={{
                                    name: '',
                                    publisher: '',
                                    author: '',
                                    category: '',
                                    language: '',
                                    searchKeywords: '',
                                    description: '',
                                    hardCoverLink: '',
                                    shareMessage: '',
                                    tag: '',
                                    sponsor: '',
                                    forGuestUser: false,
                                    isLive: true
                                }}
                                validationSchema={audioBookSchema}
                                onSubmit={async (values: any, { resetForm }): Promise<void> => {
                                    try {
                                        if ((imgLoader && audioImg !== "") || (!img && img === "") || (uploaded !== null && uploaded >= 0 && uploaded < 100)) {
                                            if (!img && img === "") {
                                                setAudioImgError("Image is required");
                                            }
                                            if (uploaded !== null && uploaded >= 0 && uploaded < 100) return;
                                            if (imgLoader && audioImg !== "") return;
                                            return;
                                        }

                                        let categories: any = Array.isArray(values.category) && values.category.map((i: any) => i?.value);
                                        let searchKeywords: any = [];
                                        if (values.searchKeywords.length > 0) {
                                            searchKeywords = Array.isArray(values.searchKeywords) && values.searchKeywords.map((i: any) => i?.value);
                                        }
                                        let payload: any = {
                                            name: values.name.value,
                                            publisher: values.publisher === '' ? null : values.publisher.value,
                                            author: values.author === '' ? null : values.author.value,
                                            category: categories,
                                            language: values.language,
                                            img: img,
                                            searchKeywords: (searchKeywords === '' || searchKeywords.length === 0) ? null : searchKeywords.toString(),
                                            description: values.description === '' ? null : values.description,
                                            hardCoverLink: values.hardCoverLink === '' ? null : values.hardCoverLink,
                                            shareMessage: values.shareMessage === '' ? null : values.shareMessage,
                                            tag: values.tag === '' ? null : values.tag,
                                            sponsor: values.sponsor === '' ? null : values.sponsor.value,
                                            size: 0,
                                            length: {
                                                hours: 0,
                                                minutes: 0,
                                                seconds: 0
                                            },
                                            pdf: pdf === '' ? null : pdf,
                                            forGuestUser: values.forGuestUser === true ? 1 : 0,
                                            isLive: values.isLive === true ? 1 : 0,
                                        };

                                        let res: any = await axios.post('audiobook/add', payload);
                                        if (res && res.status === 200) {
                                            let payload: any = {
                                                moduleName: "audiobook",
                                                moduleLink: `${config.front_url}audiobook/view/${res?.data?.data?.audiobook}`,
                                                activity: "insert",
                                                message: `The ${values.name.value} audiobook record has been inserted.`
                                            }
                                            await axios.post("history/add", payload);
                                            success('Audiobook Created Successfully.');
                                            setId(res?.data?.data?.audiobook);
                                            setName(values.name.value);
                                            resetForm({ values: '' });
                                            setAudioImg("");
                                            setImg("");
                                            setAudioPdf("");
                                            setPdf("");
                                            setPlayListOpen(true);
                                            setAudioBookOpen(false);
                                            featchingData();
                                        }
                                    } catch (error: any) {
                                        if (error.response) {
                                            setError(error.response.data.error);
                                        }
                                    }
                                }}
                            >
                                {({ handleBlur, handleChange, values, setFieldValue, setFieldTouched }) => (
                                    <Form>
                                        <CardBody className="pb-2">
                                            {(audioBookOpen && !playListOpen) &&
                                                <>
                                                    <h6 className="heading-small text-muted mb-3">
                                                        Select Publisher and Author
                                                    </h6>
                                                    <div className="pl-lg-4">
                                                        <Row>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <label className="form-control-label" htmlFor="input-reader">Publisher</label>
                                                                    <Select
                                                                        options={publisherList}
                                                                        value={values.publisher}
                                                                        onChange={(e) => { setFieldValue("publisher", e) }}
                                                                        styles={styles}
                                                                        placeholder="Select"
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <label className="form-control-label" htmlFor="input-reader">Author</label>
                                                                    <Select
                                                                        options={authorList}
                                                                        value={values.author}
                                                                        onChange={(e) => { setFieldValue("author", e) }}
                                                                        styles={styles}
                                                                        placeholder="Select"
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <hr className="mt-2 mb-3" />
                                                    <h6 className="heading-small text-muted mb-3">
                                                        Audiobook Details
                                                    </h6>
                                                    <div className="pl-lg-4">
                                                        <Row>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <label className="form-control-label" htmlFor="input-reader">Name <span className="text-danger">*</span></label>
                                                                    <CreatableSelect
                                                                        name="name"
                                                                        onChange={(e) => { setFieldValue("name", e) }}
                                                                        onBlur={(e: any) => { setFieldTouched("name", e) }}
                                                                        value={values.name}
                                                                        styles={styles}
                                                                        placeholder="Name"
                                                                        options={audiobookList}
                                                                    />
                                                                    {error ? <div className="error-message">{error?.name ? error?.name : ''}</div> : <ErrorMessage component="div" className="error-message" name="name" />}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <label className="form-control-label" htmlFor="input-language">Language <span className="text-danger">*</span></label>
                                                                    <Input
                                                                        id="input-language"
                                                                        name="language"
                                                                        type="select"
                                                                        placeholder="Select"
                                                                        className="form-control-alternative"
                                                                        value={values.language} onChange={handleChange} onBlur={handleBlur}
                                                                    >
                                                                        <option disabled value="">Select</option>
                                                                        {Array.isArray(languageList) && languageList.map((item: any, index: number) => (
                                                                            <option value={item._id} key={index}>{item.name}</option>
                                                                        ))}
                                                                    </Input>
                                                                    {error ? <div className="error-message">{error?.language ? error?.language : ''}</div> : <ErrorMessage component="div" className="error-message" name="language" />}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <label className="form-control-label" htmlFor="input-tag">Tag</label>
                                                                    <Input
                                                                        id="input-tag"
                                                                        name="tag"
                                                                        type="select"
                                                                        placeholder="Select"
                                                                        className="form-control-alternative"
                                                                        value={values.tag} onChange={handleChange} onBlur={handleBlur}
                                                                    >
                                                                        <option disabled value="">Select</option>
                                                                        {Array.isArray(tagList) && tagList.map((item: any, index: number) => (
                                                                            <option value={item._id} key={index}>{item.name}</option>
                                                                        ))}
                                                                    </Input>
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="6">
                                                                <FormGroup>
                                                                    <label className="form-control-label" htmlFor="input-sponsoredBy">Sponsor</label>
                                                                    <Select
                                                                        options={sponsorList}
                                                                        value={values.sponsor}
                                                                        onChange={(e) => { setFieldValue("sponsor", e) }}
                                                                        styles={styles}
                                                                        placeholder="Select"
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="4">
                                                                <FormGroup>
                                                                    <label className="form-control-label" htmlFor="image">Image (300x450) <span className="text-danger">*</span></label>
                                                                    <div className='file-card'>
                                                                        <div className='file-inputs'>
                                                                            <Input className="form-control-alternative file-upload" disabled={audioImg} id="image" type="file" multiple={false} onChange={uploadImgHandler} />
                                                                            <button className='file-btn' disabled={audioImg}><i className="bi bi-cloud-arrow-up file-svg"></i>Upload</button>
                                                                        </div>
                                                                    </div>
                                                                    {(audioImg && audioImg !== "") && <ul className='file-list'>
                                                                        <li className='list-item' >
                                                                            {imgLoader ? <Spinner
                                                                                className="m-4"
                                                                            >
                                                                                Loading...
                                                                            </Spinner> :
                                                                                <>
                                                                                    <img className="file-img" src={img} alt="img" />
                                                                                    <div className='actions'>
                                                                                        <i className="bi bi-x" onClick={removeImgHandler}></i>
                                                                                    </div>
                                                                                </>}
                                                                        </li>
                                                                    </ul>}
                                                                </FormGroup>
                                                                {error ? <div className="error-img-message">{error?.img ? error?.img : ''}</div> : (audioImgError && audioImgError !== '') && <div className="error-img-message">{audioImgError}</div>}
                                                            </Col>
                                                            <Col lg="4">
                                                                <FormGroup>
                                                                    <label className="form-control-label" htmlFor="image">PDF</label>
                                                                    <div className='file-card'>
                                                                        <div className='file-inputs'>
                                                                            <Input className="form-control-alternative file-upload" disabled={audioPdf} id="pdf" type="file" multiple={false} onChange={uploadPdfHandler} />
                                                                            <button className='file-btn' disabled={audioPdf}><i className="bi bi-cloud-arrow-up file-svg"></i>Upload</button>
                                                                        </div>
                                                                    </div>
                                                                    {(audioPdf && audioPdf !== "") && <ul className='file-pdf-list'>
                                                                        <li>
                                                                            <Progress
                                                                                className="mt-2"
                                                                                value={uploaded}
                                                                                color="secondary"
                                                                                min={0}
                                                                                max={100}
                                                                                animated={true}
                                                                                striped={true}
                                                                            >
                                                                                {uploaded}%
                                                                            </Progress>
                                                                            <div className="list-item">
                                                                                <i className="bi bi-file-earmark-fill file"></i>
                                                                                <p className='item'>{audioPdf?.name}</p>
                                                                                {uploaded === 100 && <div className='actions'>
                                                                                    <i className="bi bi-x" onClick={removePdfHandler}></i>
                                                                                </div>}
                                                                            </div>
                                                                        </li>
                                                                    </ul>}
                                                                </FormGroup>
                                                                {error ? <div className="error-img-message">{error?.pdf ? error?.pdf : ''}</div> : (audioPdfError && audioPdfError !== '') && <div className="error-img-message">{audioPdfError}</div>}
                                                            </Col>
                                                            <Col lg="2">
                                                                <FormGroup switch>
                                                                    <Label check className="switch">For Guest User</Label>
                                                                    <Input type="switch" role="switch" name="forGuestUser" checked={values.forGuestUser} onChange={handleChange} onBlur={handleBlur} />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="2">
                                                                <FormGroup switch>
                                                                    <Label check className="switch">Is Live</Label>
                                                                    <Input type="switch" role="switch" name="isLive" checked={values.isLive} onChange={handleChange} onBlur={handleBlur} />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="12">
                                                                <FormGroup>
                                                                    <label className="form-control-label" htmlFor="input-category">Category <span className="text-danger">*</span></label>
                                                                    <Select
                                                                        isMulti
                                                                        name="category"
                                                                        options={categoryList}
                                                                        value={values.category}
                                                                        onChange={(e) => { setFieldValue("category", e) }}
                                                                        onBlur={(e: any) => { setFieldTouched("category", e) }}
                                                                        styles={styles}
                                                                        placeholder="Select"
                                                                    />
                                                                    {error ? <div className="error-message">{error?.category ? error?.category : ''}</div> : <ErrorMessage component="div" className="error-message" name="category" />}
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="12">
                                                                <FormGroup>
                                                                    <label>Search Keywords</label>
                                                                    <CreatableSelect
                                                                        isMulti
                                                                        name="searchKeywords"
                                                                        onChange={(e) => { setFieldValue("searchKeywords", e) }}
                                                                        value={values.searchKeywords}
                                                                        styles={styles}
                                                                        placeholder="Search Keywords"
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="12">
                                                                <FormGroup>
                                                                    <label>Description</label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        placeholder="Description"
                                                                        name="description"
                                                                        rows="4"
                                                                        type="textarea"
                                                                        value={values.description} onChange={handleChange} onBlur={handleBlur}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="12">
                                                                <FormGroup>
                                                                    <label className="form-control-label" htmlFor="input-hardCoverLink">Hard Cover Link</label>
                                                                    <Input className="form-control-alternative" id="input-hardCoverLink" name="hardCoverLink" value={values.hardCoverLink} onChange={handleChange} onBlur={handleBlur} placeholder="Hard Cover Link" type="text" />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg="12">
                                                                <FormGroup>
                                                                    <label>Share Message</label>
                                                                    <Input
                                                                        className="form-control-alternative"
                                                                        placeholder="Share Message"
                                                                        name="shareMessage"
                                                                        rows="4"
                                                                        type="textarea"
                                                                        value={values.shareMessage} onChange={handleChange} onBlur={handleBlur}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </>
                                            }
                                            {(!audioBookOpen && playListOpen) &&
                                                <>
                                                    <div className="d-flex justify-content-between mb-3">
                                                        <h6 className="heading-small text-muted mb-0 pb-0">
                                                            Play List
                                                        </h6>
                                                        <Button className="primary-btn" size="sm" onClick={() => setFilePage(true)}><i className="bi bi-plus"></i> Add</Button>
                                                    </div>
                                                    <Row>
                                                        {playList.length === 0 && <h6 className="pt-2 text-muted d-flex align-items-center justify-content-center"><i className="bi bi-x-circle text-danger"></i>&nbsp;No PlayFile Found</h6>}
                                                        <DragDropContext onDragEnd={handleDrop}>
                                                            <Droppable droppableId="card">
                                                                {(provided: any) => (
                                                                    <div
                                                                        {...provided.droppableProps}
                                                                        ref={provided.innerRef}
                                                                    >
                                                                        {Array.isArray(playList) && playList.map((list: any, index: number) => (
                                                                            <Draggable key={list?._id} draggableId={list?._id} index={index}>
                                                                                {(provided: any) => (
                                                                                    <div
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.dragHandleProps}
                                                                                        {...provided.draggableProps}
                                                                                    >
                                                                                        <Card className="file-list-card" key={index} >
                                                                                            <CardBody className="p-3">
                                                                                                <div className="file-first-section">
                                                                                                    <label className="form-control-label-first" htmlFor="input-file-name">{index + 1}</label>
                                                                                                    <label className="form-control-label-second" htmlFor="input-file-name">{list?.title}</label>
                                                                                                </div>
                                                                                                <div className="file-second-section">
                                                                                                    <div className='list-audio'>
                                                                                                        <audio controls src={list?.file}>
                                                                                                            <source src={list?.file} type="audio/mpeg" />
                                                                                                        </audio>
                                                                                                    </div>
                                                                                                    <UncontrolledDropdown>
                                                                                                        <DropdownToggle className="btn-icon-only text-dark border-0" size="sm" color="">
                                                                                                            <i className="bi bi-three-dots-vertical"></i>
                                                                                                        </DropdownToggle>
                                                                                                        <DropdownMenu className="dropdown-menu-arrow">
                                                                                                            <DropdownItem className="py-1" onClick={() => { setEditFilePage(true); setEditFile(list) }}>Edit</DropdownItem>
                                                                                                            <DropdownItem onClick={() => { setDeleteFilePage(true); setDeleteFile(list) }}>Delete</DropdownItem>
                                                                                                        </DropdownMenu>
                                                                                                    </UncontrolledDropdown>
                                                                                                </div>
                                                                                                <div className="btn-list">
                                                                                                    <Button className="btn-secondary" size="sm" onClick={() => { setEditFilePage(true); setEditFile(list) }}>Edit</Button>
                                                                                                    <Button className="btn-danger" size="sm" onClick={() => { setDeleteFilePage(true); setDeleteFile(list) }}>Delete</Button>
                                                                                                </div>
                                                                                            </CardBody>
                                                                                        </Card>
                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                        ))}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </DragDropContext>
                                                    </Row>
                                                </>
                                            }
                                        </CardBody>
                                        <CardFooter className="bg-white border-0 pl-lg-4 ">
                                            <div className="form-button">
                                                {(audioBookOpen && !playListOpen) && <Button className="primary-btn" size="sm" type="submit" onClick={submitHandler}>Save & Next</Button>}
                                                {(audioBookOpen && !playListOpen) && <Button className="cancel-btn" size="sm" onClick={() => dispatch(uiActions.pageType(''))}>Cancel</Button>}
                                                {(!audioBookOpen && playListOpen) && <Button className="primary-btn" size="sm" type="submit" onClick={() => { dispatch(uiActions.pageType('')); featchingData(); }}>Submit</Button>}
                                            </div>
                                        </CardFooter>
                                    </Form>
                                )}
                            </Formik>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default AddAudiobook;