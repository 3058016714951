import "./Audiobook.scss";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import axios from '../../shared/hooks/use-customAxios';
import { success } from '../../shared/hooks/use-toastify';
import { useEffect, useState } from "react";
import usePageTitle from '../../shared/hooks/use-pageTitle';

const DeleteAudiobook: React.FC<any> = ({ setDeletePage, deletePage, editData, featchingData }) => {
    usePageTitle("Audiobook");
    const [img, setImg] = useState<any>(editData?.img);
    const [pdf, setPdf] = useState<any>(editData?.pdf);
    const [fileList, setFileList] = useState<any>([]);

    useEffect(() => {
        setFileList(Array.isArray(editData?.playList) && editData?.playList.map((item: any) => (item?.file)));
    }, []);

    const deleteHandler = async (): Promise<void> => {
        try {
            let res: any = await axios.post('audiobook/delete', { id: editData?._id });
            if (res && res.status === 200) {
                if (!!img) {
                    axios.post(`audiobook/removePhoto`, { filename: img }).then((result) => { }).catch((error: any) => { });
                }
                if (!!pdf) {
                    axios.post(`audiobook/removePDF`, { filename: pdf }).then((result) => { }).catch((error: any) => { });
                }
                if (fileList.length > 0) {
                    fileList.forEach((el: any) => {
                        axios.post(`audiobook/removeAudio`, { filename: el }).then((result) => { }).catch((error: any) => { });
                    });
                }
                let payload: any = {
                    moduleName: "audiobook",
                    activity: "delete",
                    message: `The ${editData?.name} audiobook record has been deleted.`
                }
                await axios.post("history/add", payload);
                success('AudioBook Deleted Successfully.');
                setDeletePage(false);
                featchingData();
            }
        } catch (error: any) { }
    };

    return (
        <div>
            <Modal isOpen={deletePage} centered={true}>
                <ModalBody>
                    Are you sure to delete the Audiobook?
                </ModalBody>
                <ModalFooter>
                    <Button className='primary-btn' size='sm' onClick={deleteHandler}>
                        Delete
                    </Button>
                    <Button className='cancel-btn' size='sm' onClick={() => setDeletePage(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DeleteAudiobook;