import "./assets/scss/main.scss";
import Sidebar from "./shared/layout/Sidebar/Sidebar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { route } from "./shared/hooks/user-role";

import Login from "./pages/Login/Login";
import OtpVerify from "./pages/OtpVerify/OtpVerify";
import Navbar from "./shared/layout/Navbar/Navbar";
import Profile from "./pages/Profile/Profile";
import Dashboard from "./pages/Dashboard/Dashboard";

import Role from "./pages/Role/Role";
import AddRole from "./pages/Role/AddRole";
import EditRole from "./pages/Role/EditRole";
import ViewRole from "./pages/Role/ViewRole";

import AdminUser from "./pages/AdminUser/AdminUser";
import AddAdminUser from "./pages/AdminUser/AddAdminUser";

import AppUser from "./pages/AppUser/AppUser";
import ViewAppUser from "./pages/AppUser/ViewAppUser";

import Publisher from "./pages/Publisher/Publisher";
import ViewPublisher from "./pages/Publisher/ViewPublisher";

import Author from "./pages/Author/Author";
import ViewAuthor from "./pages/Author/ViewAuthor";

import Speaker from "./pages/Speaker/Speaker";
import ViewSpeaker from "./pages/Speaker/ViewSpeaker";

import Sponsor from "./pages/Sponsor/Sponsor";
import ViewSponsor from "./pages/Sponsor/ViewSponsor";

import CategoryAudiobook from "./pages/CategoryAudiobook/CategoryAudiobook";
import ViewCategoryAudiobook from "./pages/CategoryAudiobook/ViewCategoryAudiobook";

import CategoryPodcast from "./pages/CategoryPodcast/CategoryPodcast";
import ViewCategoryPodcast from "./pages/CategoryPodcast/ViewCategoryPodcast";

import CategorySong from "./pages/CategorySong/CategorySong";
import ViewCategorySong from "./pages/CategorySong/ViewCategorySong";

import Language from "./pages/Language/Language";
import ViewLanguage from "./pages/Language/ViewLanguage";

import Tag from "./pages/Tag/Tag";
import ViewTag from "./pages/Tag/ViewTag";

import Audiobook from "./pages/Audiobook/Audiobook";
import ViewAudiobook from "./pages/Audiobook/ViewAudiobook";

import Podcast from "./pages/Podcast/Podcast";
import ViewPodcast from "./pages/Podcast/ViewPodcast";

import Song from "./pages/Song/Song";
import ViewSong from "./pages/Song/ViewSong";

import Banner from "./pages/Banner/Banner";
import ViewBanner from "./pages/Banner/ViewBanner";

import SectionAudiobook from "./pages/SectionAudiobook/SectionAudiobook";
import ViewSectionAudiobook from "./pages/SectionAudiobook/ViewSectionAudiobook";

import SectionPodcast from "./pages/SectionPodcast/SectionPodcast";
import ViewSectionPodcast from "./pages/SectionPodcast/ViewSectionPodcast";

import SectionSong from "./pages/SectionSong/SectionSong";
import ViewSectionSong from "./pages/SectionSong/ViewSectionSong";

import RecommendedAudiobook from "./pages/RecommendedAudiobook/RecommendedAudiobook";
import ViewRecommendedAudiobook from "./pages/RecommendedAudiobook/ViewRecommendedAudiobook";

import RecommendedPodcast from "./pages/RecommendedPodcast/RecommendedPodcast";
import ViewRecommendedPodcast from "./pages/RecommendedPodcast/ViewRecommendedPodcast";

import RecommendedSong from "./pages/RecommendedSong/RecommendedSong";
import ViewRecommendedSong from "./pages/RecommendedSong/ViewRecommendedSong";

import SearchAudiobook from "./pages/SearchAudiobook/SearchAudiobook";
import ViewSearchAudiobook from "./pages/SearchAudiobook/ViewSearchAudiobook";

import SearchPodcast from "./pages/SearchPodcast/SearchPodcast";
import ViewSearchPodcast from "./pages/SearchPodcast/ViewSearchPodcast";

import SearchSong from "./pages/SearchSong/SearchSong";
import ViewSearchSong from "./pages/SearchSong/ViewSearchSong";

import NoticeBoard from "./pages/NoticeBoard/NoticeBoard";
import ViewNoticeBoard from "./pages/NoticeBoard/ViewNoticeBoard";

import NoSearchFound from "./pages/NoSearchFound/NoSearchFound";
import Notification from "./pages/Notification/Notification";
import History from "./pages/History/History";

import Footer from "./shared/layout/Footer/Footer";
import { useDispatch } from "react-redux";
import { uiActions } from "./shared/store/ui-Slice";
import LoginAs from "./pages/LoginAs/LoginAs";

const App = () => {
  const [isLogin, setIsLogin] = useState<any>(null);
  const [routeArr, setRouteArr] = useState<any[]>([]);
  const pageType: string = useSelector((state: any) => state.ui.pageType);
  const isShow: boolean = useSelector((state: any) => state.ui.sidebarIsVisible);
  let routes: any = [
      <Route path="/role" element={<Role />} />,
      <Route path="/role/view/:id" element={<ViewRole />} />,

      <Route path="/admin/user" element={<AdminUser />} />,

      <Route path="/app/user" element={<AppUser />} />,

      <Route path="/publisher" element={<Publisher />} />,
      <Route path="/publisher/view/:id" element={<ViewPublisher />} />,

      <Route path="/author" element={<Author />} />,
      <Route path="/author/view/:id" element={<ViewAuthor />} />,

      <Route path="/speaker" element={<Speaker />} />,
      <Route path="/speaker/view/:id" element={<ViewSpeaker />} />,

      <Route path="/sponsor" element={<Sponsor />} />,
      <Route path="/sponsor/view/:id" element={<ViewSponsor />} />,

      <Route path="/category/audiobook" element={<CategoryAudiobook />} />,
      <Route path="/category/audiobook/view/:id" element={<ViewCategoryAudiobook />} />,

      <Route path="/category/bauddhik" element={<CategoryPodcast />} />,
      <Route path="/category/bauddhik/view/:id" element={<ViewCategoryPodcast />} />,

      <Route path="/category/song" element={<CategorySong />} />,
      <Route path="/category/song/view/:id" element={<ViewCategorySong />} />,

      <Route path="/language" element={<Language />} />,
      <Route path="/language/view/:id" element={<ViewLanguage />} />,

      <Route path="/tag" element={<Tag />} />,
      <Route path="/tag/view/:id" element={<ViewTag />} />,

      <Route path="/audiobook" element={<Audiobook />} />,
      <Route path="/audiobook/view/:id" element={<ViewAudiobook />} />,

      <Route path="/bauddhik" element={<Podcast />} />,
      <Route path="/bauddhik/view/:id" element={<ViewPodcast />} />,

      <Route path="/song" element={<Song />} />,
      <Route path="/song/view/:id" element={<ViewSong />} />,

      <Route path="/banner" element={<Banner />} />,
      <Route path="/banner/view/:id" element={<ViewBanner />} />,

      <Route path="/section/audiobook" element={<SectionAudiobook />} />,
      <Route path="/section/audiobook/view/:id" element={<ViewSectionAudiobook />} />,

      <Route path="/section/bauddhik" element={<SectionPodcast />} />,
      <Route path="/section/bauddhik/view/:id" element={<ViewSectionPodcast />} />,

      <Route path="/section/song" element={<SectionSong />} />,
      <Route path="/section/song/view/:id" element={<ViewSectionSong />} />,
      
      <Route path="/recommended/audiobook" element={<RecommendedAudiobook />} />,
      <Route path="/recommended/audiobook/view/:id" element={<ViewRecommendedAudiobook />} />,

      <Route path="/recommended/bauddhik" element={<RecommendedPodcast />} />,
      <Route path="/recommended/bauddhik/view/:id" element={<ViewRecommendedPodcast />} />,

      <Route path="/recommended/song" element={<RecommendedSong />} />,
      <Route path="/recommended/song/view/:id" element={<ViewRecommendedSong />} />,

      <Route path="/search/audiobook" element={<SearchAudiobook />} />,
      <Route path="/search/audiobook/view/:id" element={<ViewSearchAudiobook />} />,

      <Route path="/search/bauddhik" element={<SearchPodcast />} />,
      <Route path="/search/bauddhik/view/:id" element={<ViewSearchPodcast />} />,
      
      <Route path="/search/song" element={<SearchSong />} />,
      <Route path="/search/song/view/:id" element={<ViewSearchSong />} />,

      <Route path="/noticeBoard" element={<NoticeBoard />} />,
      <Route path="/noticeBoard/view/:id" element={<ViewNoticeBoard />} />,

      <Route path="/noSearchFound" element={<NoSearchFound />} />,

      <Route path="/notification" element={<Notification />} />,

      <Route path="/history" element={<History />} />
  ];

  useEffect(() => {
    setIsLogin(localStorage.getItem("token") && localStorage.getItem("token") != null ? true : false);
    // if (localStorage.getItem("user")) {
    //     let arr: any[] = route(routes);
    //     setRouteArr(arr);
    // }
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (pageType === "") {
      dispatch(uiActions.updateAudiobookHandler([]));
      dispatch(uiActions.updatePodcastHandler([]));
      dispatch(uiActions.updateSongHandler([]));
      dispatch(uiActions.removeBannerAudiobookHandler());
      dispatch(uiActions.removeBannerPodcastHandler());
      dispatch(uiActions.removeBannerSongHandler());
    }
  }, [pageType]);

  return (
    <Router>
      <ToastContainer />
      {isLogin != null && (
        <>
          {!isLogin ? (
            <Routes>
              <Route path="/" element={<LoginAs setIsLogin={setIsLogin} />} />
              {/* <Route path="/otp-verify" element={<OtpVerify setIsLogin={setIsLogin} />} /> */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          ) : (
            <>
              <Sidebar />
              <div className={`${!isShow ? "hide" : ""} layout`}>
                <Navbar setIsLogin={setIsLogin} />
                <Routes>
                  <Route path="/" element={<Dashboard />} />

                  <Route path="/my-profile" element={<Profile />} />

                  {/* <Route path="/role" element={<Role />} />
                  <Route path="/role/view/:id" element={<ViewRole />} /> */}

                  {/* <Route path="/admin/user" element={<AdminUser />} /> */}

                  <Route path="/app/user" element={<AppUser />} />

                  <Route path="/publisher" element={<Publisher />} />,
                  <Route path="/publisher/view/:id" element={<ViewPublisher />} />

                  <Route path="/author" element={<Author />} />
                  <Route path="/author/view/:id" element={<ViewAuthor />} />

                  <Route path="/speaker" element={<Speaker />} />
                  <Route path="/speaker/view/:id" element={<ViewSpeaker />} />

                  <Route path="/sponsor" element={<Sponsor />} />
                  <Route path="/sponsor/view/:id" element={<ViewSponsor />} />

                  <Route path="/category/audiobook" element={<CategoryAudiobook />} />
                  <Route path="/category/audiobook/view/:id" element={<ViewCategoryAudiobook />} />

                  <Route path="/category/bauddhik" element={<CategoryPodcast />} />
                  <Route path="/category/bauddhik/view/:id" element={<ViewCategoryPodcast />} />

                  <Route path="/category/song" element={<CategorySong />} />
                  <Route path="/category/song/view/:id" element={<ViewCategorySong />} />

                  <Route path="/language" element={<Language />} />
                  <Route path="/language/view/:id" element={<ViewLanguage />} />

                  <Route path="/tag" element={<Tag />} />
                  <Route path="/tag/view/:id" element={<ViewTag />} />

                  <Route path="/audiobook" element={<Audiobook />} />
                  <Route path="/audiobook/view/:id" element={<ViewAudiobook />} />

                  <Route path="/bauddhik" element={<Podcast />} />
                  <Route path="/bauddhik/view/:id" element={<ViewPodcast />} />

                  <Route path="/song" element={<Song />} />
                  <Route path="/song/view/:id" element={<ViewSong />} />

                  <Route path="/banner" element={<Banner />} />
                  <Route path="/banner/view/:id" element={<ViewBanner />} />

                  <Route path="/section/audiobook" element={<SectionAudiobook />} />
                  <Route path="/section/audiobook/view/:id" element={<ViewSectionAudiobook />} />

                  <Route path="/section/bauddhik" element={<SectionPodcast />} />
                  <Route path="/section/bauddhik/view/:id" element={<ViewSectionPodcast />} />

                  <Route path="/section/song" element={<SectionSong />} />
                  <Route path="/section/song/view/:id" element={<ViewSectionSong />} />
                  
                  <Route path="/recommended/audiobook" element={<RecommendedAudiobook />} />
                  <Route path="/recommended/audiobook/view/:id" element={<ViewRecommendedAudiobook />} />

                  <Route path="/recommended/bauddhik" element={<RecommendedPodcast />} />
                  <Route path="/recommended/bauddhik/view/:id" element={<ViewRecommendedPodcast />} />

                  <Route path="/recommended/song" element={<RecommendedSong />} />
                  <Route path="/recommended/song/view/:id" element={<ViewRecommendedSong />} />

                  <Route path="/search/audiobook" element={<SearchAudiobook />} />
                  <Route path="/search/audiobook/view/:id" element={<ViewSearchAudiobook />} />

                  <Route path="/search/bauddhik" element={<SearchPodcast />} />
                  <Route path="/search/bauddhik/view/:id" element={<ViewSearchPodcast />} />
                  
                  <Route path="/search/song" element={<SearchSong />} />
                  <Route path="/search/song/view/:id" element={<ViewSearchSong />} />

                  <Route path="/noticeBoard" element={<NoticeBoard />} />
                  <Route path="/noticeBoard/view/:id" element={<ViewNoticeBoard />} />

                  <Route path="/noSearchFound" element={<NoSearchFound />} />

                  <Route path="/notification" element={<Notification />} />

                  <Route path="/history" element={<History />} />

                  <Route path="*" element={<Navigate to="/" />} />
                </Routes>
                <Footer />
              </div>
            </>
          )}
        </>
      )}
    </Router>
  );
};

export default App;
