import './Banner.scss';
import { useState, useEffect } from "react";
import {
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col,
    CardFooter,
    Table,
    Media
} from "reactstrap";
import Moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../shared/hooks/use-customAxios';
import usePageTitle from '../../shared/hooks/use-pageTitle';
import { useDispatch } from 'react-redux';
import { uiActions } from '../../shared/store/ui-Slice';

const ViewBanner: React.FC<any> = ({ id }) => {
    usePageTitle("Banner");
    const audioBookHeader = ["Name", "Publisher", "Author", "Category"];
    const podCastHeader = ["Name", "Speaker", "Category"];
    const songHeader = ["Name", "Category"];
    const [editData, setEditData] = useState<any>(null);
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const view = async (): Promise<void> => {
            try {
                let res: any = await axios.post(`banner/view`, { id: !!id ? id : params?.id });
                if (res && res.status === 200) {
                    setEditData(res?.data?.data?.banner);
                }
            } catch (error: any) {
                if (error && error.response && error.response.status === 400) {
                    if (error.response.data.message) {
                        navigate('/banner');
                    }
                }
            }
        };

        view();
    }, []);

    const cancelHandler = () => {
        if (!!id) {
            dispatch(uiActions.pageType(''))
        } else {
            dispatch(uiActions.pageType(''));
            navigate("/history");
        }
    };

    return (
        <div className="form-banner">
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="7">
                        <Card>
                            <CardBody>
                                <Row className='view-banner'>
                                    <Col lg="6" xs="6">
                                        <p>Use For : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.useFor === "podcast" ? "bauddhik" : editData?.useFor}</p>
                                    </Col>
                                </Row>
                                <Row className='view-banner'>
                                    <Col lg="6" xs="6">
                                        <p>Image : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <img className="file-img" src={editData?.img} alt="img" />
                                    </Col>
                                </Row>
                                <Row className='view-banner'>
                                    <Col lg="6" xs="6">
                                        <p>Link Type : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.linkType === "podcast" ? "bauddhik" : editData?.linkType}</p>
                                    </Col>
                                </Row>
                                {(editData?.linkType === "audiobook" && editData?.audioBook) && <><h6 className="heading-small text-muted">
                                    Audiobook
                                </h6>
                                    <hr className="my-2" />
                                    <Row>
                                        <Col className="file-list-banner">
                                            <Table className="align-items-center table-flush" responsive>
                                                <thead className="thead-light">
                                                    <tr>
                                                        {Array.isArray(audioBookHeader) && audioBookHeader.map((item: string, index: number) => (
                                                            <th scope="col" key={index}>{item}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <Media className="align-items-center d-flex gap-3">
                                                                <div className="avatar rounded-circle mr-3">
                                                                    <img alt="..." src={editData?.audioBook?.img} />
                                                                </div>
                                                                <Media>
                                                                    <span className="mb-0 text-sm">{editData?.audioBook?.name}</span>
                                                                </Media>
                                                            </Media>
                                                        </td>
                                                        <td>{editData?.audioBook?.author?.name}</td>
                                                        <td>{editData?.audioBook?.publisher?.name}</td>
                                                        <td>{(Array.isArray(editData?.audioBook?.category) && editData?.audioBook?.category.map((i: any, index: number) => (i?.name) + `${editData?.audioBook?.category.length !== index + 1 ? ', ' : ' '}`))}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row></>}
                                {(editData?.linkType === "podcast" && editData?.podCast) && <><h6 className="heading-small text-muted">
                                   Bauddhik
                                </h6>
                                    <hr className="my-2" />
                                    <Row>
                                        <Col className="file-list-banner">
                                            <Table className="align-items-center table-flush" responsive>
                                                <thead className="thead-light">
                                                    <tr>
                                                        {Array.isArray(podCastHeader) && podCastHeader.map((item: string, index: number) => (
                                                            <th scope="col" key={index}>{item}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <Media className="align-items-center d-flex gap-3">
                                                                <div className="avatar rounded-circle mr-3">
                                                                    <img alt="..." src={editData?.podCast?.img} />
                                                                </div>
                                                                <Media>
                                                                    <span className="mb-0 text-sm">{editData?.podCast?.name}</span>
                                                                </Media>
                                                            </Media>
                                                        </td>
                                                        <td>{editData?.podCast?.speaker?.name}</td>
                                                        <td>{(Array.isArray(editData?.podCast?.category) && editData?.podCast?.category.map((i: any, index: number) => (i?.name) + `${editData?.podCast?.category.length !== index + 1 ? ', ' : ' '}`))}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row></>}
                                {(editData?.linkType === "song" && editData?.song) && <><h6 className="heading-small text-muted">
                                    Song
                                </h6>
                                    <hr className="my-2" />
                                    <Row>
                                        <Col className="file-list-banner">
                                            <Table className="align-items-center table-flush" responsive>
                                                <thead className="thead-light">
                                                    <tr>
                                                        {Array.isArray(songHeader) && songHeader.map((item: string, index: number) => (
                                                            <th scope="col" key={index}>{item}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <Media className="align-items-center d-flex gap-3">
                                                                <div className="avatar rounded-circle mr-3">
                                                                    <img alt="..." src={editData?.song?.img} />
                                                                </div>
                                                                <Media>
                                                                    <span className="mb-0 text-sm">{editData?.song?.name}</span>
                                                                </Media>
                                                            </Media>
                                                        </td>
                                                        <td>{(Array.isArray(editData?.song?.category) && editData?.song?.category.map((i: any, index: number) => (i?.name) + `${editData?.song?.category.length !== index + 1 ? ', ' : ' '}`))}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Col>
                                    </Row></>}
                                {editData?.linkType === "author" && <Row className='view-banner'>
                                    <Col lg="6" xs="6">
                                        <p>Author : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <Card className="file-list-banner">
                                            <Table className="align-items-center table-flush" responsive>
                                                <tbody className='list-border'>
                                                    <tr>
                                                        <td>
                                                            <Media className="align-items-center d-flex gap-3">
                                                                <div className="avatar rounded-circle mr-3">
                                                                    {editData?.author?.img !== null && <img alt="..." src={editData?.author?.img} />}
                                                                </div>
                                                                <Media>
                                                                    <span className="mb-0 text-sm">{editData?.author?.name}</span>
                                                                </Media>
                                                            </Media>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Card>
                                    </Col>
                                </Row>}
                                {editData?.linkType === "publisher" && <Row className='view-banner'>
                                    <Col lg="6" xs="6">
                                        <p>Publisher : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <Card className="file-list-banner">
                                            <Table className="align-items-center table-flush" responsive>
                                                <tbody className='list-border'>
                                                    <tr>
                                                        <td>
                                                            <Media className="align-items-center d-flex gap-3">
                                                                <div className="avatar rounded-circle mr-3">
                                                                    {editData?.publisher?.img !== null && <img alt="..." src={editData?.publisher?.img} />}
                                                                </div>
                                                                <Media>
                                                                    <span className="mb-0 text-sm">{editData?.publisher?.name}</span>
                                                                </Media>
                                                            </Media>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Card>
                                    </Col>
                                </Row>}
                                {editData?.linkType === "speaker" && <Row className='view-banner'>
                                    <Col lg="6" xs="6">
                                        <p>Speaker : </p>
                                    </Col>
                                    <Col lg="6" xs="6" className="file-list-banner">
                                        <Card className="file-list-banner">
                                            <Table className="align-items-center table-flush" responsive>
                                                <tbody className='list-border'>
                                                    <tr>
                                                        <td>
                                                            <Media className="align-items-center d-flex gap-3">
                                                                <div className="avatar rounded-circle mr-3">
                                                                    {editData?.speaker?.img !== null && <img alt="..." src={editData?.speaker?.img} />}
                                                                </div>
                                                                <Media>
                                                                    <span className="mb-0 text-sm">{editData?.speaker?.name}</span>
                                                                </Media>
                                                            </Media>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </Card>
                                    </Col>
                                </Row>}
                                {editData?.linkType === "video" && <Row className='view-banner'>
                                    <Col lg="6" xs="6">
                                        <p>Video : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.video}</p>
                                    </Col>
                                </Row>}
                                {editData?.linkType === "url" && <Row className='view-banner'>
                                    <Col lg="6" xs="6">
                                        <p>URL : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.url}</p>
                                    </Col>
                                </Row>}
                                <Row className='view-banner'>
                                    <Col lg="6" xs="6">
                                        <p>Start Schedule Time : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{Moment(editData?.startScheduleTime).format('DD-MM-YYYY')}</p>
                                    </Col>
                                </Row>
                                <Row className='view-banner'>
                                    <Col lg="6" xs="6">
                                        <p>End Schedule Time : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{Moment(editData?.endScheduleTime).format('DD-MM-YYYY')}</p>
                                    </Col>
                                </Row>
                                <Row className='view-banner'>
                                    <Col lg="6" xs="6">
                                        <p>Position : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.position}</p>
                                    </Col>
                                </Row>
                                <Row className='view-banner'>
                                    <Col lg="6" xs="6">
                                        <p>Status : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.status === 1 ? 'Enable' : 'Disable'}</p>
                                    </Col>
                                </Row>
                                <Row className='view-banner'>
                                    <Col lg="6" xs="6">
                                        <p>CreatedAt : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{Moment(editData?.createdAt).format('DD-MM-YYYY')}</p>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className="bg-white border-0 pl-lg-4 ">
                                <div className="form-button">
                                <Button className="cancel-btn" size="sm" onClick={cancelHandler}>Cancel</Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default ViewBanner;