import "./RecommendedSong.scss";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    CardFooter,
    Media
} from "reactstrap";
import { useState, useEffect } from 'react';
import { Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import axios from '../../shared/hooks/use-customAxios';
import { success } from "../../shared/hooks/use-toastify";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "../../shared/store/ui-Slice";
import AddSongList from "./AddSongList";
import { config } from '../../config/config';
import usePageTitle from '../../shared/hooks/use-pageTitle';

const EditRecommendedSong: React.FC<any> = ({featchingData, id}) => {
    usePageTitle("Recommended For Song");
    const songsItem: any = useSelector((state: any) => state.ui.songsItem);
    const [editData, setEditData] = useState<any>([]);
    const [error, setError] = useState<any>();
    const [songModal, setSongModal] = useState<boolean>(false);
    const dispatch = useDispatch();

    const recommendedSchema = yup.object().shape({
        noOfYearsWithRss: yup.string().required('No Of Years With Rss is required')
    });

    useEffect(() => {
        const view = async (): Promise<void> => {
            try {
                let res: any = await axios.post(`recommended/view`, { id: id });
                if (res && res.status === 200) {
                    setEditData(res?.data?.data?.recommended);
                }
            } catch (error: any) {}
        };

        view();
    }, []);

    useEffect(() => {
        if (!!editData?.song) dispatch(uiActions.updateSongHandler(editData?.song));
    }, [editData]);

    return (
        <div className="form-recommended-song">
            {songModal && <AddSongList songModal={songModal} setSongModal={setSongModal} id={editData?._id} />}
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="8">
                        <Card>
                            <Formik
                                enableReinitialize={true}
                                initialValues={{ noOfYearsWithRss: editData?.noOfYearsWithRss === 0 ? 'No' : editData?.noOfYearsWithRss === 1 ? 'Less than a Year' : editData?.noOfYearsWithRss === 2 ? '1 to 5 Years' : 'More than 5 Years' }}
                                validationSchema={recommendedSchema}
                                onSubmit={async (values: any, { resetForm }): Promise<void> => {
                                    try {
                                        let songs: any = Array.isArray(songsItem) && songsItem.map((i: any) => i._id);

                                        let payload: any = {
                                            id: editData?._id,
                                            noOfYearsWithRss: editData?.noOfYearsWithRss,
                                            song: songs,
                                            totalSong: songs?.length === 0 ? 0 : songs?.length
                                        };
                                        let res: any = await axios.post('recommended/update', payload);
                                        if (res && res.status === 200) {
                                            let payload: any = {
                                                moduleName: "recommended for song",
                                                moduleLink: `${config.front_url}recommended/song/view/${editData?._id}`,
                                                activity: "update",
                                                message: `The ${editData?.noOfYearsWithRss === 0 ? 'No' : editData?.noOfYearsWithRss === 1 ? 'Less than a Year' : editData?.noOfYearsWithRss === 2 ? '1 to 5 Years' : 'More than 5 Years'} No. of Years with RSS in recommended for song record has been updated.`
                                            }
                                            await axios.post("history/add", payload);
                                            success('Recommended Updated Successfully.');
                                            resetForm({ values: '' });
                                            dispatch(uiActions.updateSongHandler([]));
                                            dispatch(uiActions.pageType(''));
                                            featchingData();
                                        }
                                    } catch (error: any) {
                                        if (error.response) {
                                            setError(error.response.data.error);
                                        }
                                    }
                                }} >
                                {({ handleBlur, values }) => (
                                    <Form>
                                        <CardBody className="pb-2">
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-noOfYearsWithRss">No Of Years With RSS <span className="text-danger">*</span></label>
                                                            <Input className="form-control-alternative"
                                                                id="input-noOfYearsWithRss"
                                                                name="noOfYearsWithRss"
                                                                placeholder="No Of Years With RSS" type="text"
                                                                onBlur={handleBlur}
                                                                readOnly={true}
                                                                value={values.noOfYearsWithRss} />
                                                            {error ? <div className="error-message">{error?.noOfYearsWithRss ? error?.noOfYearsWithRss : ''}</div> : <ErrorMessage component="div" className="error-message" name="noOfYearsWithRss" />}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup className="d-grid">
                                                            <label className="form-control-label" htmlFor="input-song">Song</label>
                                                            <Button className='primary-btn' onClick={() => setSongModal(true)}><i className="bi bi-plus"></i> Add</Button>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    {songsItem.length === 0 && <h6 className="p-4 text-muted d-flex align-items-center justify-content-center"><i className="bi bi-x-circle text-danger"></i>&nbsp;No Song Found</h6>}
                                                    {songsItem.length > 0 && Array.isArray(songsItem) && songsItem.map((item: any, index: number) => (
                                                        <Card className="file-list-card" key={index}>
                                                            <CardBody className="p-3">
                                                                <div className="file-first-section">
                                                                    <label className="form-control-label-first" htmlFor="input-file-name">{index + 1}</label>
                                                                    <Media className="align-items-center d-flex gap-2">
                                                                        <div className="avatar rounded-circle mr-3">
                                                                            <img alt="..." src={item?.img} />
                                                                        </div>
                                                                        <Media>
                                                                            <span className="mb-0">{item?.name}</span>
                                                                        </Media>
                                                                    </Media>
                                                                </div>
                                                                <i className="bi bi-x-circle-fill text-danger" onClick={() => dispatch(uiActions.removeSongHandler(item?._id))}></i>
                                                            </CardBody>
                                                        </Card>
                                                    ))}
                                                </Row>
                                            </div>
                                        </CardBody>
                                        <CardFooter className="bg-white border-0 pl-lg-4 ">
                                            <div className="form-button">
                                                <Button className="primary-btn" size="sm" type="submit">Submit</Button>
                                                <Button className="cancel-btn" size="sm" onClick={() => { dispatch(uiActions.updateSongHandler([])); dispatch(uiActions.pageType('')); }}>Cancel</Button>
                                            </div>
                                        </CardFooter>
                                    </Form>
                                )}
                            </Formik>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default EditRecommendedSong;