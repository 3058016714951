import "./Author.scss";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    Container,
    Row,
    Col,
    CardFooter,
    Spinner
} from "reactstrap";
import { useEffect, useState } from 'react';
import { Form, Formik, ErrorMessage } from 'formik';
import * as yup from 'yup';
import axios from '../../shared/hooks/use-customAxios';
import { success } from '../../shared/hooks/use-toastify';
import useSelect from '../../shared/hooks/use-select';
import CreatableSelect from 'react-select/creatable';
import { config } from '../../config/config';
import usePageTitle from '../../shared/hooks/use-pageTitle';
import { uiActions } from "../../shared/store/ui-Slice";
import { useDispatch } from "react-redux";

const AddAuthor: React.FC<any> = ({featchingData}) => {
    usePageTitle("Author");
    const [img, setImg] = useState<any>(null);
    const [authorList, setAuthorList] = useState<any[]>([]);
    const [authorImg, setAuthorImg] = useState<any>('');
    const [imgError, setImgError] = useState<any>('');
    const [isLoading, setIsLoading] = useState<any>(true);
    const [error, setError] = useState<any>();
    let styles: any = useSelect();
    const dispatch = useDispatch();

    const authorSchema = yup.object().shape({
        name: yup.object().required('Name is required')
    });

    useEffect(() => {
        const list = async () => {
            try {
                const res: any = await axios.post(`master/list`);
                if (res && res.status === 200) {
                    let authors: any[] = res?.data?.data?.authors;
                    if (Array.isArray(authors) && authors.length > 0) authors = authors.map((item: any) => ({ label: item?.name, value: item?.name }));
                    setAuthorList(authors);
                }

            } catch (error: any) { }
        };

        list();
    }, []);

    const uploadImgHandler = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        let files: FileList | null = e.target.files;

        if (!files) return;
        if (!files[0].name.match(/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/)) {
            setImgError("Please Select Valid File");
            return;
        }
        setAuthorImg(files[0]);

        let fileData = new FormData();
        fileData.append("file", files[0]);

        try {
            let res: any = await axios.post(`author/uploadPhoto`, fileData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            });
            if (res && res.status === 200) {
                setIsLoading(false);
                setImg(res.data.data.filename);
                setImgError("");
            }
        } catch (error: any) {
            if (error && error.response && error.response.status === 400) {
                (document.getElementById("image") as HTMLInputElement).value = "";
                setIsLoading(true);
                setAuthorImg("");
                setImg(null);
            }
        }
    };

    const removeImgHandler = async () => {
        try {
            let res: any = await axios.post(`author/removePhoto`, { filename: img });
            if (res && res.status === 200) {
                (document.getElementById("image") as HTMLInputElement).value = "";
                setIsLoading(true);
                setAuthorImg("");
                setImg(null);
            }
        } catch (error: any) { }
    };

    return (
        <div className="form-author">
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="8">
                        <Card>
                            <Formik
                                initialValues={{ name: '', jobTitle: '', about: '' }}
                                validationSchema={authorSchema}
                                onSubmit={async (values: any, { resetForm }): Promise<void> => {
                                    try {
                                        if (isLoading && authorImg !== "") return;
                                        let payload: any = {
                                            name: values.name.value,
                                            jobTitle: values.jobTitle === '' ? null : values.jobTitle,
                                            img: img,
                                            about: values.about === '' ? null : values.about
                                        };
                                        let res: any = await axios.post('author/add', payload);
                                        if (res && res.status === 200) {
                                            let payload: any = {
                                                moduleName: "author",
                                                moduleLink: `${config.front_url}author/view/${res?.data?.data?.author}`,
                                                activity: "insert",
                                                message: `The ${values.name.value} author record has been inserted.`
                                            }
                                            await axios.post("history/add", payload);
                                            success('Author Created Successfully.');
                                            resetForm({ values: '' });
                                            setAuthorImg("");
                                            setImg(null);
                                            featchingData();
                                            dispatch(uiActions.pageType(''));
                                        }
                                    } catch (error: any) {
                                        if (error.response) {
                                            setError(error.response.data.error);
                                        }
                                    }
                                }} >
                                {({ handleBlur, handleChange, values, setFieldValue, setFieldTouched }) => (
                                    <Form>
                                        <CardBody className="pb-2">
                                            <div className="pl-lg-4">
                                                <Row>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-reader">Name <span className="text-danger">*</span></label>
                                                            <CreatableSelect
                                                                name="name"
                                                                onChange={(e) => { setFieldValue("name", e) }}
                                                                onBlur={(e: any) => { setFieldTouched("name", e) }}
                                                                value={values.name}
                                                                styles={styles}
                                                                placeholder="Name"
                                                                options={authorList}
                                                            />
                                                            {error ? <div className="error-message">{error?.name ? error?.name : ''}</div> : <ErrorMessage component="div" className="error-message" name="name" />}
                                                        </FormGroup>
                                                    </Col>
                                                    <Col lg="6">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="input-jobTitle">Job Title</label>
                                                            <Input className="form-control-alternative" name="jobTitle" id="input-jobTitle" placeholder="Job Title" type="text" onChange={handleChange} onBlur={handleBlur} value={values.jobTitle} />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label>About</label>
                                                            <Input
                                                                className="form-control-alternative"
                                                                placeholder="About"
                                                                name="about"
                                                                rows="4"
                                                                type="textarea"
                                                                value={values.about} onChange={handleChange} onBlur={handleBlur}
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg="12">
                                                        <FormGroup>
                                                            <label className="form-control-label" htmlFor="image">Image (256x256)</label>
                                                            <div className='file-card'>
                                                                <div className='file-inputs'>
                                                                    <Input className="form-control-alternative file-upload" disabled={authorImg} id="image" type="file" multiple={false} onChange={uploadImgHandler} />
                                                                    <button className='file-btn' disabled={authorImg}><i className="bi bi-cloud-arrow-up file-svg"></i>Upload</button>
                                                                </div>
                                                            </div>
                                                            {authorImg && authorImg !== '' && <ul className='file-list'>
                                                                <li className='list-item' >
                                                                    {isLoading ? <Spinner
                                                                        className="m-4"
                                                                    >
                                                                        Loading...
                                                                    </Spinner> :
                                                                        <>
                                                                            <img className="file-img" src={img} alt="img" />
                                                                            <div className='actions'>
                                                                                <i className="bi bi-x" onClick={removeImgHandler}></i>
                                                                            </div>
                                                                        </>}
                                                                </li>
                                                            </ul>}
                                                        </FormGroup>
                                                        {(imgError && imgError !== '') && <div className="error-img-message">{imgError}</div>}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </CardBody>
                                        <CardFooter className="bg-white border-0 pl-lg-4 ">
                                            <div className="form-button">
                                                <Button className="primary-btn" size="sm" type="submit">Submit</Button>
                                                <Button className="cancel-btn" size="sm" onClick={() => dispatch(uiActions.pageType(''))}>Cancel</Button>
                                            </div>
                                        </CardFooter>
                                    </Form>
                                )}
                            </Formik>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default AddAuthor;