import "./SectionSong.scss";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import axios from '../../shared/hooks/use-customAxios';
import { success } from '../../shared/hooks/use-toastify';
import { useState } from "react";
import usePageTitle from '../../shared/hooks/use-pageTitle';

const DeleteSectionSong: React.FC<any> = ({ setDeletePage, deletePage, editData, featchingData }) => {
    usePageTitle("Section For Song");
    const [img, setImg] = useState<any>(editData?.banner?.img);

    const deleteHandler = async (): Promise<void> => {
        try {
            let res: any = await axios.post('section/delete', { id: editData?._id });
            if (res && res.status === 200) {
                if (!!img) {
                    axios.post(`section/removePhoto`, { filename: img }).then((result) => { }).catch((error: any) => { });
                }
                let payload: any = {
                    moduleName: "section for song",
                    activity: "delete",
                    message: `The ${editData?.title} section for song record has been deleted.`
                }
                await axios.post("history/add", payload);
                success('Section Deleted Successfully.');
                setDeletePage(false);
                featchingData();
            }
        } catch (error: any) { }
    };

    return (
        <div>
            <Modal isOpen={deletePage} centered={true}>
                <ModalBody>
                    Are you sure to delete the Section For Song?
                </ModalBody>
                <ModalFooter>
                    <Button className='primary-btn' size='sm' onClick={deleteHandler}>
                        Delete
                    </Button>
                    <Button className='cancel-btn' size='sm' onClick={() => setDeletePage(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DeleteSectionSong;