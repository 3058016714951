import './RecommendedAudiobook.scss';
import { useState, useEffect } from "react";
import {
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col,
    CardFooter,
    Table,
    Media
} from "reactstrap";
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../shared/hooks/use-customAxios';
import usePageTitle from '../../shared/hooks/use-pageTitle';
import { useDispatch } from 'react-redux';
import { uiActions } from '../../shared/store/ui-Slice';

const ViewRecommendedAudiobook: React.FC<any> = ({ id }) => {
    usePageTitle("Recommended For Audiobook");
    const audioBookHeader = ["Name", "Publisher", "Author", "Category"];
    const [editData, setEditData] = useState<any>(null);
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const view = async (): Promise<void> => {
            try {
                let res: any = await axios.post(`recommended/view`, { id: !!id ? id : params?.id });
                if (res && res.status === 200) {
                    setEditData(res?.data?.data?.recommended);
                }
            } catch (error: any) {
                if (error && error.response && error.response.status === 400) {
                    if (error.response.data.message) {
                        navigate('/recommended/audiobook');
                    }
                }
            }
        };

        view();
    }, []);

    const cancelHandler = () => {
        if (!!id) {
            dispatch(uiActions.pageType(''))
        } else {
            dispatch(uiActions.pageType(''));
            navigate("/history");
        }
    };

    return (
        <div className="form-recommended-audiobook">
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="7">
                        <Card>
                            <CardBody>
                                <Row className='view-recommended-audiobook'>
                                    <Col lg="6" xs="6">
                                        <p>No Of Years With RSS  : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.noOfYearsWithRss === 0 ? 'No' : editData?.noOfYearsWithRss === 1 ? 'Less than a Year' : editData?.noOfYearsWithRss === 2 ? '1 to 5 Years' : 'More than 5 Years'}</p>
                                    </Col>
                                </Row>
                                <h6 className="heading-small text-muted">
                                    Audiobook
                                </h6>
                                <hr className="my-2" />
                                {(editData?.audioBook && editData?.audioBook?.length > 0) ? <Row>
                                    <Col>
                                        <Card className="file-list-recommended-audiobook">
                                            <Table className="align-items-center table-flush" responsive>
                                                <thead className="thead-light">
                                                    <tr>
                                                        {Array.isArray(audioBookHeader) && audioBookHeader.map((item: string, index: number) => (
                                                            <th scope="col" key={index}>{item}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {Array.isArray(editData?.audioBook) && editData?.audioBook.map((item: any, index: number) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <Media className="align-items-center d-flex gap-3">
                                                                    <div className="avatar rounded-circle mr-3">
                                                                        <img alt="..." src={item?.img} />
                                                                    </div>
                                                                    <Media>
                                                                        <span className="mb-0 text-sm">{item?.name}</span>
                                                                    </Media>
                                                                </Media>
                                                            </td>
                                                            <td>{item?.author?.name}</td>
                                                            <td>{item?.publisher?.name}</td>
                                                            <td>{(Array.isArray(item?.category) && item?.category.map((i: any, index: number) => (i?.name) + `${item?.category?.length !== index + 1 ? ', ' : ' '}`))}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Card>
                                    </Col>
                                </Row> :
                                    <p className="p-3 d-flex align-items-center justify-content-center view-audiobook"><i className="bi bi-x-circle text-danger"></i>&nbsp;No Audiobook Found</p>}
                                <Row className='view-recommended-audiobook'>
                                    <Col lg="6" xs="6">
                                        <p>Total Audiobook : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.totalAudiobook}</p>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className="bg-white border-0 pl-lg-4 ">
                                <div className="form-button">
                                   <Button className="cancel-btn" size="sm" onClick={cancelHandler}>Cancel</Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ViewRecommendedAudiobook;