import { useState, useEffect } from 'react';
import './History.scss';
import Pagination from 'react-responsive-pagination';
import {
    Card,
    CardHeader,
    CardFooter,
    Table,
    Container,
    Row,
    Button,
    Input,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle
} from "reactstrap";
import usePageTitle from '../../shared/hooks/use-pageTitle';
import axios from '../../shared/hooks/use-customAxios';
import Skeleton from '../../shared/components/Skeleton/Skeleton';
import Moment from 'moment';
import FilterHistory from './FilterHistory';
import { Link } from 'react-router-dom';
import { config } from '../../config/config';
import { useSelector } from 'react-redux';

const History = () => {
    usePageTitle("History");
    const pageType: string = useSelector((state: any) => state.ui.pageType);
    const header = ["Sr No.", "Module Name", "Activity", "User", "Message", "Activity Time"];
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [historyData, setHistoryData] = useState<any>([]);
    const [filterFromDate, setFilterFromDate] = useState<string>('');
    const [filterToDate, setFilterToDate] = useState<string>('');
    const [filterPage, setFilterPage] = useState<boolean>(false);
    const options = ["5", "10", "15", "20", "25"];
    const [total, setTotal] = useState<number>(0);
    const [record, setRecord] = useState<string>(options[0]);
    const [page, setPage] = useState<number>(1);
    let totalPages: number = Math.ceil(total / +record);
    let start: number = (page - 1) * +record;
    let srIndex: number = start;
    const handleChange = (newPage: number): void => {
        setPage(newPage);
    }

    const featchingData = async (): Promise<void> => {
        try {
            let obj: any = {
                pageNo: page,
                recordPerPage: record,
            };

            if ((filterFromDate && filterFromDate !== '') || (filterToDate && filterToDate !== '')) {
                obj.fromDate = filterFromDate;
                obj.toDate = filterToDate;
            }

            const res: any = await axios.post('history/list', obj);
            if (res && res.status === 200) {
                setIsLoading(false);
                setHistoryData(res.data.data.history);
                if (res.data.data.totalRecords || res.data.data.totalRecords === 0) {
                    setTotal(res.data.data.totalRecords);
                }
                if (res.data.data.history.length === 0) {
                    setPage(1);
                }
            }

        } catch (error: any) {
            setIsLoading(true);
        }
    };

    useEffect(() => {
        featchingData();
    }, [page, record]);

    return (
        <>
            {filterPage && <FilterHistory filterPage={filterPage} setFilterPage={setFilterPage} filterFromDate={filterFromDate} setFilterFromDate={setFilterFromDate} filterToDate={filterToDate} setFilterToDate={setFilterToDate} featchingData={featchingData} />}
            {pageType === '' && (isLoading ? <Skeleton col={7} title="History" /> :
                <div className='history'>
                    <Container className="mt--7" fluid>
                        <Row>
                            <div className="col">
                                <Card>
                                    <CardHeader className="border-0 d-flex justify-content-between">
                                        <h3 className='mb-0'>History [{total}]</h3>
                                        <div className="header-btn">
                                          <Button className='filter' onClick={() => setFilterPage(true)}>Filter</Button>
                                        </div>
                                    </CardHeader>
                                    <Table className="align-items-center table-flush mb-0" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                {Array.isArray(header) && header.map((item: string, index: number) => (
                                                    <th scope="col" key={index}>{item}</th>
                                                ))}
                                                <th scope="col" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(historyData) && historyData.map((item: any, index: number) => (
                                                <tr key={index}>
                                                    <td>{++srIndex}</td>
                                                    <td>{item?.moduleName === "podcast" ? "bauddhik" : item?.moduleName}</td>
                                                    <td>{item?.activity}</td>
                                                    <td>{item?.admin?.username}</td>
                                                    <td>{item?.message}</td>
                                                    <td>{Moment(item?.activityTime).format('DD-MM-YYYY') + ' ' + new Date(item?.activityTime).toISOString().toString().substring(11, 19)}</td>
                                                    <td className="text-right">
                                                       {!!item?.moduleLink && <UncontrolledDropdown>
                                                            <DropdownToggle className="btn-icon-only text-light" size="sm" color="">
                                                                <i className="bi bi-three-dots-vertical"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-arrow">
                                                                <DropdownItem to={item?.moduleLink?.toString().replace(config.front_url, "/")} tag={Link}>View</DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <CardFooter className="p-2">
                                        <Input type="select" value={record} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setRecord(e.target.value)}>
                                            {Array.isArray(options) && options.map((item: string, index: number) => (
                                                <option key={index} value={item}>{item}</option>

                                            ))};
                                        </Input>
                                        <Pagination
                                            current={page}
                                            total={totalPages}
                                            onPageChange={handleChange}
                                            maxWidth={5}
                                        />
                                    </CardFooter>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                </div>)}
        </>
    );
};

export default History;
