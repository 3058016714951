import "./Publisher.scss";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input } from 'reactstrap';
import usePageTitle from '../../shared/hooks/use-pageTitle';

const FilterPublisher: React.FC<any> = ({ setFilterPage, filterPage, setFilterName, filterName, featchingData, args }) => {
    usePageTitle("Publisher");
    const toggle = () => { setFilterPage(!filterPage); featchingData(); };

    return (
        <div>
            <Modal isOpen={filterPage} toggle={toggle} {...args} centered={true}>
                <ModalHeader toggle={toggle}>Filter</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <label className="form-control-label" htmlFor="input-name">Name</label>
                        <Input className="form-control-alternative" id="input-name" placeholder="Name" type="text" value={filterName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterName(e.target.value)} />
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button className="cancel-btn" size="sm" onClick={() => setFilterName('')}>Clear</Button>
                    <Button className="primary-btn" size="sm" onClick={() => { setFilterPage(false); featchingData(); }}>
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>
        </div >
    );
};

export default FilterPublisher;