import "./History.scss";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormGroup,
    Input, 
    Row,
    Col
} from 'reactstrap';
import usePageTitle from '../../shared/hooks/use-pageTitle';

const FilterHistory: React.FC<any> = ({ filterFromDate, filterToDate, setFilterFromDate, setFilterToDate, filterPage, setFilterPage, featchingData, args }) => {
    usePageTitle("History");
    const toggle = () => { setFilterPage(!filterPage); featchingData(); };
    return (
        <div>
            <Modal isOpen={filterPage} toggle={toggle} {...args} centered={true}>
                <ModalHeader toggle={toggle}>Filter</ModalHeader>
                <ModalBody>
                    <div className="pl-lg-4">
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-date">From Date</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-date"
                                        type="date"
                                        name="filterFromDate"
                                        value={filterFromDate}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterFromDate(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-date">To Date</label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-date"
                                        type="date"
                                        name="filterToDate"
                                        value={filterToDate}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterToDate(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="cancel-btn" size="sm" onClick={() => { setFilterFromDate(''); setFilterToDate(''); }}>Clear</Button>
                    <Button className="primary-btn" size="sm" onClick={() => { setFilterPage(false); featchingData(); }}>
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>
        </div >
    );
};

export default FilterHistory;