import { useState, useEffect } from 'react';
import './SectionPodcast.scss';
import {
    Card,
    CardHeader,
    Table,
    Container,
    Row,
    Button,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    CardFooter
} from "reactstrap";
import usePageTitle from '../../shared/hooks/use-pageTitle';
import axios from '../../shared/hooks/use-customAxios';
import Skeleton from '../../shared/components/Skeleton/Skeleton';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteSectionPodcast from './DeleteSectionPodcast';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import AddSectionPodcast from './AddSectionPodcast';
import EditSectionPodcast from './EditSectionPodcast';
import ViewSectionPodcast from './ViewSectionPodcast';
import { uiActions } from '../../shared/store/ui-Slice';

const SectionPodcast = () => {
    usePageTitle("Section For Bauddhik");
    const pageType: string = useSelector((state: any) => state.ui.pageType);
    const dispatch = useDispatch();
    const header = ["Sr No.", "Title", "Type"];
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [sectionData, setSectionData] = useState<any>([]);
    const [deletePage, setDeletePage] = useState<boolean>(false);
    const [editData, setEditData] = useState<any>();

    const featchingData = async (): Promise<void> => {
        try {
            const res: any = await axios.post('section/list', { useFor: "podcast" });
            if (res && res.status === 200) {
                setIsLoading(false);
                setSectionData(res.data.data.section);
            }
        } catch (error: any) {
            setIsLoading(true);
        }
    };

    useEffect(() => {
        featchingData();
    }, []);

    const handleDrop = async (droppedItem: any): Promise<void> => {
        try {
            if (!droppedItem.destination) return;
            let updatedList = [...sectionData];
            const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
            updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
            let arr: string[] = updatedList.map((item: any) => item?._id);
            await axios.post('section/updateList', { idList: arr });
            let payload: any = {
                moduleName: "section for bauddhik",
                activity: "update",
                message: `The section for bauddhik position has been updated.`
            }
            await axios.post("history/add", payload);
            setSectionData(updatedList);
            featchingData();
        } catch (error: any) { }
    }

    return (
        <>
            {pageType === 'add' && <AddSectionPodcast featchingData={featchingData} />}
            {pageType === 'edit' && <EditSectionPodcast featchingData={featchingData} id={editData} />}
            {pageType === 'view' && <ViewSectionPodcast id={editData}  />}
            {deletePage && <DeleteSectionPodcast deletePage={deletePage} setDeletePage={setDeletePage} editData={editData} featchingData={featchingData} />}
            {pageType === '' && (isLoading ? <Skeleton col={4} title="Section For Bauddhik" /> :
                <div className='section-podcast'>
                    <Container className="mt--7" fluid>
                        <Row>
                            <div className="col">
                                <Card>
                                    <CardHeader className="border-0">
                                        <div className='header-section d-flex justify-content-between'>
                                            <h3 className="mb-0">Section For Bauddhik [{Array.isArray(sectionData) && sectionData.length}]</h3>
                                            <div className="header-btn">
                                                <Button className='btn' onClick={() => dispatch(uiActions.pageType('add'))}><i className="bi bi-plus"></i> Add</Button>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <Table className="align-items-center table-flush mb-0" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                {Array.isArray(header) && header.map((item: string, index: number) => (
                                                    <th scope="col" key={index}>{item}</th>
                                                ))}
                                                <th scope="col" />
                                            </tr>
                                        </thead>
                                        <DragDropContext onDragEnd={handleDrop}>
                                            <Droppable droppableId="tr">
                                                {(provided: any) => (
                                                    <tbody
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        {Array.isArray(sectionData) && sectionData.map((item: any, index: number) => (
                                                            <Draggable key={item?._id} draggableId={item?._id} index={index}>
                                                                {(provided: any) => (
                                                                    <tr ref={provided.innerRef}
                                                                        {...provided.dragHandleProps}
                                                                        {...provided.draggableProps}>
                                                                        <td style={{ width: "10px", background: "#fff" }}>{index + 1}</td>
                                                                        <td style={{ width: "750px", background: "#fff" }}>{item?.title}</td>
                                                                        <td style={{ width: "750px", background: "#fff" }}>{item?.type}</td>
                                                                        <td className="text-right" style={{ width: "10px", background: "#fff" }}>
                                                                            <UncontrolledDropdown>
                                                                                <DropdownToggle className="btn-icon-only text-light" size="sm" color="">
                                                                                    <i className="bi bi-three-dots-vertical"></i>
                                                                                </DropdownToggle>
                                                                                <DropdownMenu className="dropdown-menu-arrow">
                                                                                    <DropdownItem onClick={() => { dispatch(uiActions.pageType('view')); setEditData(item?._id); }}>View</DropdownItem>
                                                                                    <DropdownItem onClick={() => { dispatch(uiActions.pageType('edit')); setEditData(item?._id); }}>Edit</DropdownItem>
                                                                                    <DropdownItem onClick={() => { setDeletePage(true); setEditData(item) }}>Delete</DropdownItem>
                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>
                                                                        </td>
                                                                    </tr>

                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </tbody>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </Table>
                                    <CardFooter className="border-0"></CardFooter>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                </div>)}
        </>
    );
};

export default SectionPodcast;
