import { useState, useEffect } from 'react';
import './RecommendedAudiobook.scss';
import {
    Card,
    CardHeader,
    CardFooter,
    Table,
    Container,
    Row,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle
} from "reactstrap";
import usePageTitle from '../../shared/hooks/use-pageTitle';
import axios from '../../shared/hooks/use-customAxios';
import Skeleton from '../../shared/components/Skeleton/Skeleton';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import EditRecommendedAudiobook from './EditRecommendedAudiobook';
import { uiActions } from '../../shared/store/ui-Slice';
import ViewRecommendedAudiobook from './ViewRecommendedAudiobook';

const RecommendedAudiobook = () => {
    usePageTitle("Recommended For Audiobook");
    const header = ["Sr No.", "No Of Years With RSS", "Total Audiobook"];
    const pageType: string = useSelector((state: any) => state.ui.pageType);
    const dispatch = useDispatch();
    const [editData, setEditData] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [recommenedData, setRecommendedData] = useState<any>([]);

    const featchingData = async (): Promise<void> => {
        try {
            const res: any = await axios.post('recommended/list');
            if (res && res.status === 200) {
                setIsLoading(false);
                setRecommendedData(res.data.data.recommended);
            }

        } catch (error: any) {
            setIsLoading(true);
        }
    };

    useEffect(() => {
        featchingData();
    }, []);

    return (
        <>
            {pageType === 'edit' && <EditRecommendedAudiobook featchingData={featchingData} id={editData} />}
            {pageType === 'view' && <ViewRecommendedAudiobook id={editData}  />}
            {pageType === '' && (isLoading ? <Skeleton col={4} title="Recommended For Audiobook" /> :
                <div className='recommended-audiobook'>
                    <Container className="mt--7" fluid>
                        <Row>
                            <div className="col">
                                <Card>
                                    <CardHeader className="border-0 d-flex justify-content-between">
                                        <h3 className="mb-0">Recommended For Audiobook [{Array.isArray(recommenedData) && recommenedData.length}]</h3>
                                    </CardHeader>
                                    <Table className="align-items-center table-flush mb-0" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                {Array.isArray(header) && header.map((item: string, index: number) => (
                                                    <th scope="col" key={index}>{item}</th>
                                                ))}
                                                <th scope="col" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(recommenedData) && recommenedData.map((item: any, index: number) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.noOfYearsWithRss === 0 ? 'No' : item?.noOfYearsWithRss === 1 ? 'Less than a Year' : item?.noOfYearsWithRss === 2 ? '1 to 5 Years' : 'More than 5 Years'}</td>
                                                    <td>{item?.totalAudiobook}</td>
                                                    <td className="text-right">
                                                        <UncontrolledDropdown>
                                                            <DropdownToggle className="btn-icon-only text-light" size="sm" color="">
                                                                <i className="bi bi-three-dots-vertical"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-arrow">
                                                            <DropdownItem onClick={() => { dispatch(uiActions.pageType('view')); setEditData(item?._id); }}>View</DropdownItem>
                                                                <DropdownItem onClick={() => { dispatch(uiActions.pageType('edit')); setEditData(item?._id); }}>Edit</DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                    <CardFooter className="border-0"></CardFooter>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                </div>)}
        </>
    );
};

export default RecommendedAudiobook;
