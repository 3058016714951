import "./AppUser.scss";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    FormGroup,
    Input,
    Row,
    Col
} from 'reactstrap';
import usePageTitle from '../../shared/hooks/use-pageTitle';

const FilterAppUser: React.FC<any> = ({
    setFilterPage,
    filterPage,
    setFilterName,
    filterName,
    filterEmail,
    setFilterEmail,
    filterMobileNo,
    setFilterMobileNo,
    filterGender,
    setFilterGender,
    filterIsPartOfRss,
    setFilterIsPartOfRss,
    filterNoOfYearsWithRss,
    setFilterNoOfYearsWithRss,
    filterAuthService,
    setFilterAuthService,
    filterStatus,
    setFilterStatus,
    featchingData,
    args }) => {
    usePageTitle("App User");
    const toggle = () => { setFilterPage(!filterPage); featchingData(); };

    const clearHandler = (): void => {
        setFilterName('');
        setFilterEmail('');
        setFilterMobileNo('');
        setFilterGender('');
        setFilterIsPartOfRss('');
        setFilterNoOfYearsWithRss('');
        setFilterAuthService('');
        setFilterStatus('');
    };

    return (
        <div>
            <Modal isOpen={filterPage} toggle={toggle} {...args} centered={true}>
                <ModalHeader toggle={toggle}>Filter</ModalHeader>
                <ModalBody>
                    <div className="pl-lg-4">
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-name">Name</label>
                                    <Input className="form-control-alternative" id="input-name" placeholder="Name" type="text" value={filterName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterName(e.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-email">Email</label>
                                    <Input className="form-control-alternative" id="input-email" placeholder="Email" type="text" value={filterEmail} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterEmail(e.target.value)} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-mobileno">Mobile No.</label>
                                    <Input className="form-control-alternative" id="input-mobileno" placeholder="Mobile No." type="text" value={filterMobileNo} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterMobileNo(e.target.value)} />
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-gender">Gender</label>
                                    <Input
                                        id="input-gender"
                                        name="gender"
                                        type="select"
                                        placeholder="Select"
                                        className="form-control-alternative"
                                        value={filterGender} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterGender(e.target.value)}
                                    >
                                        <option value="">Select</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-isPartOfRss">Is Part Of Rss</label>
                                    <Input
                                        id="input-isPartOfRss"
                                        name="isPartOfRss"
                                        type="select"
                                        placeholder="Select"
                                        className="form-control-alternative"
                                        value={filterIsPartOfRss} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterIsPartOfRss(e.target.value)}
                                    >
                                        <option value="">Select</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-gender">No Of Years With Rss</label>
                                    <Input
                                        id="input-noOfYearsWithRss"
                                        name="noOfYearsWithRss"
                                        type="select"
                                        placeholder="Select"
                                        className="form-control-alternative"
                                        value={filterNoOfYearsWithRss} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterNoOfYearsWithRss(e.target.value)}
                                    >
                                        <option value="">Select</option>
                                        <option value={0}>No</option>
                                        <option value={1}>Less than a Year</option>
                                        <option value={2}>1 to 5 Years</option>
                                        <option value={3}>More than 5 Years</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-authService">Auth Service</label>
                                    <Input
                                        id="input-authService"
                                        name="authService"
                                        type="select"
                                        placeholder="Select"
                                        className="form-control-alternative"
                                        value={filterAuthService} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterAuthService(e.target.value)}
                                    >
                                        <option value="">Select</option>
                                        <option value="mobile">Mobile</option>
                                        <option value="social">Social</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label className="form-control-label" htmlFor="input-status">Status</label>
                                    <Input
                                        id="input-status"
                                        name="status"
                                        type="select"
                                        placeholder="Select"
                                        className="form-control-alternative"
                                        value={filterStatus} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFilterStatus(e.target.value)}
                                    >
                                        <option value="">Select</option>
                                        <option value={0}>Not Verified</option>
                                        <option value={1}>Verified</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="cancel-btn" size="sm" onClick={clearHandler}>Clear</Button>
                    <Button className="primary-btn" size="sm" onClick={() => { setFilterPage(false); featchingData(); }}>
                        Submit
                    </Button>
                </ModalFooter>
            </Modal>
        </div >
    );
};

export default FilterAppUser;