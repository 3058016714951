import { useState, useEffect } from 'react';
import './SearchSong.scss';
import {
    Card,
    CardHeader,
    CardFooter,
    Table,
    Container,
    Row,
    Button,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle
} from "reactstrap";
import usePageTitle from '../../shared/hooks/use-pageTitle';
import axios from '../../shared/hooks/use-customAxios';
import Skeleton from '../../shared/components/Skeleton/Skeleton';
import DeleteSearchSong from './DeleteSearchSong';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from 'react-redux';
import AddSearchSong from './AddSearchSong';
import EditSearchSong from './EditSearchSong';
import ViewSearchSong from './ViewSearchSong';
import { uiActions } from '../../shared/store/ui-Slice';

const SearchSong = () => {
    usePageTitle("Search For Song");
    const pageType: string = useSelector((state: any) => state.ui.pageType);
    const dispatch = useDispatch();
    const header = ["Sr No.", "Query"];
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [searchSongData, setSearchSongData] = useState<any>([]);
    const [deletePage, setDeletePage] = useState<boolean>(false);
    const [editData, setEditData] = useState<any>();

    const featchingData = async (): Promise<void> => {
        try {
            const res: any = await axios.post('search/list', { useFor: "song" });
            if (res && res.status === 200) {
                setIsLoading(false);
                setSearchSongData(res.data.data.search);
            }

        } catch (error: any) {
            setIsLoading(true);
        }
    };

    useEffect(() => {
        featchingData();
    }, []);

    const handleDrop = async (droppedItem: any): Promise<void> => {
        try {
            if (!droppedItem.destination) return;
            let updatedList = [...searchSongData];
            const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
            updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
            let arr: string[] = updatedList.map((item: any) => item?._id);
            await axios.post('search/updateList', { idList: arr });
            let payload: any = {
                moduleName: "search for song",
                activity: "update",
                message: `The search for song position has been updated.`
            }
            await axios.post("history/add", payload);
            setSearchSongData(updatedList);
            featchingData();
        } catch (error: any) { }
    }

    return (
        <>
            {pageType === 'add' && <AddSearchSong featchingData={featchingData} />}
            {pageType === 'edit' && <EditSearchSong featchingData={featchingData} id={editData} />}
            {pageType === 'view' && <ViewSearchSong id={editData}  />}
            {deletePage && <DeleteSearchSong deletePage={deletePage} setDeletePage={setDeletePage} editData={editData} featchingData={featchingData} />}
            {pageType === '' && (isLoading ? <Skeleton col={3} title="Search For Song" /> :
                <div className='search-song'>
                    <Container className="mt--7" fluid>
                        <Row>
                            <div className="col">
                                <Card>
                                    <CardHeader className="border-0 d-flex justify-content-between">
                                        <h3 className="mb-0">Search For Song [{Array.isArray(searchSongData) && searchSongData.length}]</h3>
                                        <div className="header-btn">
                                            <Button className='btn' onClick={() => dispatch(uiActions.pageType('add'))}><i className="bi bi-plus"></i> Add</Button>
                                        </div>
                                    </CardHeader>
                                    <Table className="align-items-center table-flush mb-0" responsive>
                                        <thead className="thead-light">
                                            <tr>
                                                {Array.isArray(header) && header.map((item: string, index: number) => (
                                                    <th scope="col" key={index}>{item}</th>
                                                ))}
                                                <th scope="col" />
                                            </tr>
                                        </thead>
                                        <DragDropContext onDragEnd={handleDrop}>
                                            <Droppable droppableId="tr">
                                                {(provided: any) => (
                                                    <tbody
                                                        {...provided.droppableProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        {Array.isArray(searchSongData) && searchSongData.map((item: any, index: number) => (
                                                            <Draggable key={item?._id} draggableId={item?._id} index={index}>
                                                                {(provided: any) => (
                                                                    <tr ref={provided.innerRef}
                                                                        {...provided.dragHandleProps}
                                                                        {...provided.draggableProps}>
                                                                        <td style={{ width: "70px", background: "#fff" }}>{index + 1}</td>
                                                                        <td style={{ width: "1075px", background: "#fff" }}>{item?.query}</td>
                                                                        <td className="text-right" style={{ width: "70px", background: "#fff" }}>
                                                                            <UncontrolledDropdown>
                                                                                <DropdownToggle className="btn-icon-only text-light" size="sm" color="">
                                                                                    <i className="bi bi-three-dots-vertical"></i>
                                                                                </DropdownToggle>
                                                                                <DropdownMenu className="dropdown-menu-arrow">
                                                                                    <DropdownItem onClick={() => { dispatch(uiActions.pageType('view')); setEditData(item?._id); }}>View</DropdownItem>
                                                                                    <DropdownItem onClick={() => { dispatch(uiActions.pageType('view')); setEditData(item?._id); }}>Edit</DropdownItem>
                                                                                    <DropdownItem onClick={() => { setDeletePage(true); setEditData(item) }}>Delete</DropdownItem>
                                                                                </DropdownMenu>
                                                                            </UncontrolledDropdown>
                                                                        </td>
                                                                    </tr>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </tbody>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </Table>
                                    <CardFooter className="border-0"></CardFooter>
                                </Card>
                            </div>
                        </Row>
                    </Container>
                </div>)}
        </>
    );
};

export default SearchSong;
