import "./Audiobook.scss";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import axios from '../../shared/hooks/use-customAxios';
import { success } from '../../shared/hooks/use-toastify';
import { useState } from "react";
import { config } from '../../config/config';
import usePageTitle from '../../shared/hooks/use-pageTitle';

const DeleteFile: React.FC<any> = ({ setDeleteFilePage, deleteFilePage, deleteFile, id, name }) => {
    usePageTitle("Audiobook");
    const [file, setFile] = useState<any>(deleteFile?.file);

    const deleteHandler = async (): Promise<void> => {
        try {
            let payload: any = {
                id: id,
                playFileId: deleteFile?._id,
                duration: (deleteFile?.length?.hours * 3600) + (deleteFile?.length?.minutes * 60) + deleteFile?.length?.seconds,
                size: deleteFile?.size
            }
            let res: any = await axios.post('audiobook/deletePlayFile', payload);
            if (res && res.status === 200) {
                if (!!file) {
                    axios.post(`audiobook/removeAudio`, { filename: file }).then((result) => { }).catch((error: any) => { });
                }
                let payload: any = {
                    moduleName: "audiobook",
                    moduleLink: `${config.front_url}audiobook/view/${id}`,
                    activity: "update",
                    message: `The ${name} audiobook in ${deleteFile?.title} play file record has been deleted.`
                }
                await axios.post("history/add", payload);
                success('PlayFile Deleted Successfully.');
                setDeleteFilePage(false);
            }
        } catch (error: any) { }
    };

    return (
        <div>
            <Modal isOpen={deleteFilePage} centered={true}>
                <ModalBody>
                    Are you sure to delete the PlayFile?
                </ModalBody>
                <ModalFooter>
                    <Button className='primary-btn' size='sm' onClick={deleteHandler}>
                        Delete
                    </Button>
                    <Button className='cancel-btn' size='sm' onClick={() => setDeleteFilePage(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DeleteFile;