import './Skeleton.scss';
import {
    Card,
    CardHeader,
    CardFooter,
    Table,
    Container,
    Row,
    Button,
    Input
} from "reactstrap";
import usePageTitle from '../../hooks/use-pageTitle';

const Skeleton: React.FC<any> = ({ col, title }) => {
    usePageTitle(title);

    const rows = [];
    for (let i = 0; i < col - 1; i++) {
        rows.push(<th scope="col" key={i}><span></span></th>)
    };

    const cols = [];
    for (let i = 0; i < col; i++) {
        cols.push(<td key={i}><span></span></td>)
    };

    return (
        <>
            <div className='skeleton'>
                <Container className="mt--7" fluid>
                    <Row>
                        <div className="col">
                            <Card>
                                <CardHeader className="border-0 d-flex justify-content-between">
                                    <div>
                                        <h3>{title} [0]</h3>
                                    </div>
                                    <div className="header-btn">
                                        {((title !== "Recommended For Audiobook") &&
                                            (title !== "Recommended For Bauddhik") &&
                                            (title !== "Recommended For Song") && 
                                            (title !== "App User") &&
                                            (title !== "History") &&
                                            (title !== "No Search Found")) && <Button className='btn'><i className="bi bi-plus"></i> Add</Button>}
                                            {(title === "No Search Found") && <Button className='btn'>Export</Button>}
                                        {((title !== "Category For Audiobook") &&
                                            (title !== "Category For Bauddhik") &&
                                            (title !== "Category For Song") &&
                                            (title !== "Section For Audiobook") &&
                                            (title !== "Section For Bauddhik") &&
                                            (title !== "Section For Song") &&
                                            (title !== "Recommended For Audiobook") &&
                                            (title !== "Recommended For Bauddhik") &&
                                            (title !== "Recommended For Song") &&
                                            (title !== "Search For Audiobook") &&
                                            (title !== "Search For Bauddhik") &&
                                            (title !== "Search For Song") &&
                                            (title !== "Banner") &&
                                            (title !== "Language") &&
                                            (title !== "Notice Board") &&
                                            (title !== "No Search Found") &&
                                            (title !== "Role") &&
                                            (title !== "Admin User")) &&
                                            <Button className='filter'>Filter</Button>}
                                    </div>
                                </CardHeader>
                                <Table className="align-items-center table-flush mb-0" responsive>
                                    <thead className="thead-light">
                                        <tr>
                                            {rows}
                                            <th scope="col" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>{cols}</tr>
                                        <tr>{cols}</tr>
                                        <tr>{cols}</tr>
                                        <tr>{cols}</tr>
                                        <tr>{cols}</tr>
                                    </tbody>
                                </Table>
                                {((title !== "Category For Audiobook") &&
                                    (title !== "Category For Bauddhik") &&
                                    (title !== "Category For Song") &&
                                    (title !== "Section For Audiobook") &&
                                    (title !== "Section For Bauddhik") &&
                                    (title !== "Section For Song") &&
                                    (title !== "Recommended For Audiobook") &&
                                    (title !== "Recommended For Bauddhik") &&
                                    (title !== "Recommended For Song") &&
                                    (title !== "Search For Audiobook") &&
                                    (title !== "Search For Bauddhik") &&
                                    (title !== "Search For Song") &&
                                    (title !== "Banner") &&
                                    (title !== "Language") &&
                                    (title !== "Role") &&
                                    (title !== "Notice Board")) && <CardFooter className="p-2">
                                        <Input type="select">
                                            <option>5</option>
                                            <option>10</option>
                                            <option>15</option>
                                            <option>20</option>
                                            <option>25</option>
                                        </Input>
                                        <ul className='loader-pagination'>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul>
                                    </CardFooter>}
                            </Card>
                        </div>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Skeleton;