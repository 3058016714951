import './Author.scss';
import { useState, useEffect } from "react";
import {
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col,
    CardFooter,
} from "reactstrap";
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../shared/hooks/use-customAxios';
import usePageTitle from '../../shared/hooks/use-pageTitle';
import { uiActions } from '../../shared/store/ui-Slice';
import { useDispatch } from 'react-redux';

const ViewAuthor: React.FC<any> = ({ id }) => {
    usePageTitle("Author");
    const [editData, setEditData] = useState<any>(null);
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const view = async (): Promise<void> => {
            try {
                let res: any = await axios.post(`author/view`, { id: !!id ? id : params?.id });
                if (res && res.status === 200) {
                    setEditData(res?.data?.data?.author);
                }
            } catch (error: any) {
                if (error && error.response && error.response.status === 400) {
                    if (error.response.data.message) {
                        navigate('/author');
                    }
                }
            }
        };

        view();
    }, []);

    const cancelHandler = () => {
        if (!!id) {
            dispatch(uiActions.pageType(''));
        } else {
            dispatch(uiActions.pageType(''));
            navigate("/history");
        }
    };

    return (
        <div className="form-author">
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-1" xl="6">
                        <Card>
                            <CardBody>
                                <Row className='view-author'>
                                    <Col lg="6" xs="6">
                                        <p>Name : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.name}</p>
                                    </Col>
                                </Row>
                                <Row className='view-author'>
                                    <Col lg="6" xs="6">
                                        <p>Job Title : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.jobTitle}</p>
                                    </Col>
                                </Row>
                                <Row className='view-author'>
                                    <Col lg="6" xs="6">
                                        <p>Image : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        {editData?.img !== null ? <img className="file-img" src={editData?.img} alt="img" /> :
                                            <p style={{ background: "#ebebeb", width: "5rem", height: "6rem" }}></p>
                                        }
                                    </Col>
                                </Row>
                                <Row className='view-author'>
                                    <Col lg="6" xs="6">
                                        <p>About : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.about}</p>
                                    </Col>
                                </Row>
                                <Row className='view-author'>
                                    <Col lg="6" xs="6">
                                        <p>Total Audiobook : </p>
                                    </Col>
                                    <Col lg="6" xs="6">
                                        <p>{editData?.totalAudiobook}</p>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter className="bg-white border-0 pl-lg-4 ">
                                <div className="form-button">
                                    <Button className="cancel-btn" size="sm" onClick={cancelHandler}>Cancel</Button>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default ViewAuthor;